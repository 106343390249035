import { format, formatDistanceToNow, getTime } from 'date-fns';
import { Duration } from 'luxon';
import { SystemTimeSpan } from 'src/api/redux/app/appApi';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number, addSuffix: boolean = true) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: addSuffix
  });
}

export function isJavaScriptDate(date: any): boolean {
  return Object.prototype.toString.call(date) === '[object Date]';
}

export function timespanToFriendlyText(timespan: SystemTimeSpan | undefined | null): string | undefined {
  if(!timespan) return undefined;

  // no value
  if(!timespan.totalMinutes) return undefined;
  
  let str = '';
  if(timespan.minutes) str += `${timespan.minutes} ${timespan.minutes > 1 ? 'mins' : 'min'}`;

  if(timespan.hours) str = `${timespan.hours} ${timespan.hours > 1 ? 'hrs' : 'hr'} ` + str;

  return str.trim();
}

export function minutesToFriendlyText(minutes: number | undefined | null): string | undefined {
  if(!minutes) return undefined;
  
  return timespanToFriendlyText({
    totalMinutes: minutes,
    hours: minutes >= 60 ? Math.floor(minutes / 60) : 0,
    minutes: minutes >= 60 ? minutes % 60 : minutes,
  } as SystemTimeSpan)
}

export function minutesToIsoTimespan(minutes: number | undefined | null): string {
  return Duration.fromObject({ minutes: (minutes || 0) }).toISOTime({ suppressMilliseconds: true }) || '';
}

export function isoTimespanToFriendlyText(timespan: string | undefined | null): string | undefined {  
  return minutesToFriendlyText(isoTimespanToTotalMinutes(timespan));
}

export function isoTimespanToTotalMinutes(timespan: string | undefined | null): number {  
  return Duration.fromISOTime(timespan || '00:00:00').as('minutes');
}