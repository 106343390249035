import { appApi } from 'src/api/redux/app/appApi';
import UserSelect from 'src/components/user/UserSelect';

import { InputAdornment, MenuItem, Stack, TextField } from '@mui/material';

// components
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

type Props = {
  optionsStatus: string[];
  filterStatus: string;
  onFilterStatus: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filterName: string;
  filterFacilitatorUserId?: string;
  filterParticipantUserId?: string;
  onFilterName: (value: string) => void;
  onFilterFacilitatorUserId?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterParticipantUserId?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function LearningCourseTemplateTableToolbar({
  filterName,
  filterStatus,
  filterFacilitatorUserId,
  filterParticipantUserId,
  onFilterName,
  onFilterStatus,
  onFilterFacilitatorUserId,
  onFilterParticipantUserId,
  optionsStatus,
}: Props) {
  const roleQuery = appApi.endpoints.identityRoleGetAllList.useQuery();

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <TextField
        fullWidth
        select
        label="Status"
        value={filterStatus}
        onChange={onFilterStatus}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        {optionsStatus.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>

      { filterFacilitatorUserId && onFilterFacilitatorUserId && !roleQuery.isLoading ? 
        <UserSelect label='Trainer' roleId={roleQuery.data?.items?.find(e => e.name == 'facilitator')?.id} initialValue={filterFacilitatorUserId} onFilterUser={onFilterFacilitatorUserId} includeAllOption={true} />
        : undefined
      }

      { filterParticipantUserId && onFilterParticipantUserId && !roleQuery.isLoading ? 
        <UserSelect label='Trainee' roleId={roleQuery.data?.items?.find(e => e.name == 'participant')?.id} initialValue={filterParticipantUserId} onFilterUser={onFilterParticipantUserId} includeAllOption={true} />
        : undefined
      }

      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
