import { emptySplitApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    abpApiDefinitionGet: build.query<AbpApiDefinitionGetApiResponse, AbpApiDefinitionGetApiArg>({
      query: (queryArg) => ({
        url: `/api/abp/api-definition`,
        params: { IncludeTypes: queryArg.includeTypes },
      }),
    }),
    abpApplicationConfigurationGet: build.query<
      AbpApplicationConfigurationGetApiResponse,
      AbpApplicationConfigurationGetApiArg
    >({
      query: () => ({ url: `/api/abp/application-configuration` }),
    }),
    abpTenantFindTenantByName: build.query<
      AbpTenantFindTenantByNameApiResponse,
      AbpTenantFindTenantByNameApiArg
    >({
      query: (queryArg) => ({ url: `/api/abp/multi-tenancy/tenants/by-name/${queryArg.name}` }),
    }),
    abpTenantFindTenantById: build.query<
      AbpTenantFindTenantByIdApiResponse,
      AbpTenantFindTenantByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/abp/multi-tenancy/tenants/by-id/${queryArg.id}` }),
    }),
    accountSettingsGet: build.query<AccountSettingsGetApiResponse, AccountSettingsGetApiArg>({
      query: () => ({ url: `/api/account-admin/settings` }),
    }),
    accountSettingsUpdate: build.mutation<
      AccountSettingsUpdateApiResponse,
      AccountSettingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account-admin/settings`,
        method: 'PUT',
        body: queryArg.voloAbpAccountAccountSettingsDto,
      }),
    }),
    accountSettingsGetTwoFactor: build.query<
      AccountSettingsGetTwoFactorApiResponse,
      AccountSettingsGetTwoFactorApiArg
    >({
      query: () => ({ url: `/api/account-admin/settings/two-factor` }),
    }),
    accountSettingsUpdateTwoFactor: build.mutation<
      AccountSettingsUpdateTwoFactorApiResponse,
      AccountSettingsUpdateTwoFactorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account-admin/settings/two-factor`,
        method: 'PUT',
        body: queryArg.voloAbpAccountAccountTwoFactorSettingsDto,
      }),
    }),
    accountSettingsGetRecaptcha: build.query<
      AccountSettingsGetRecaptchaApiResponse,
      AccountSettingsGetRecaptchaApiArg
    >({
      query: () => ({ url: `/api/account-admin/settings/recaptcha` }),
    }),
    accountSettingsUpdateRecaptcha: build.mutation<
      AccountSettingsUpdateRecaptchaApiResponse,
      AccountSettingsUpdateRecaptchaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account-admin/settings/recaptcha`,
        method: 'PUT',
        body: queryArg.voloAbpAccountAccountRecaptchaSettingsDto,
      }),
    }),
    accountSettingsGetExternalProvider: build.query<
      AccountSettingsGetExternalProviderApiResponse,
      AccountSettingsGetExternalProviderApiArg
    >({
      query: () => ({ url: `/api/account-admin/settings/external-provider` }),
    }),
    accountSettingsUpdateExternalProvider: build.mutation<
      AccountSettingsUpdateExternalProviderApiResponse,
      AccountSettingsUpdateExternalProviderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account-admin/settings/external-provider`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    activeCampaignIntegrationGetSettings: build.query<
      ActiveCampaignIntegrationGetSettingsApiResponse,
      ActiveCampaignIntegrationGetSettingsApiArg
    >({
      query: () => ({ url: `/api/app/active-campaign-integration/settings` }),
    }),
    activeCampaignIntegrationSetSettings: build.mutation<
      ActiveCampaignIntegrationSetSettingsApiResponse,
      ActiveCampaignIntegrationSetSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/active-campaign-integration/set-settings`,
        method: 'POST',
        body: queryArg.activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto,
      }),
    }),
    apiIntegrationCompleteProgramExpressionOfInterestRequest: build.mutation<
      ApiIntegrationCompleteProgramExpressionOfInterestRequestApiResponse,
      ApiIntegrationCompleteProgramExpressionOfInterestRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/api-integration/complete-program-expression-of-interest-request`,
        method: 'POST',
        body: queryArg.activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestProgramSelectionRequest,
        headers: { 'X-PublicApiKey': queryArg['X-PublicApiKey'] },
      }),
    }),
    apiIntegrationGetPartnerInfo: build.query<
      ApiIntegrationGetPartnerInfoApiResponse,
      ApiIntegrationGetPartnerInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/api-integration/partner-info`,
        headers: { 'X-PublicApiKey': queryArg['X-PublicApiKey'] },
      }),
    }),
    apiIntegrationListActivePrograms: build.mutation<
      ApiIntegrationListActiveProgramsApiResponse,
      ApiIntegrationListActiveProgramsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/api-integration/list-active-programs`,
        method: 'POST',
        body: queryArg.activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestListProgramsRequest,
        headers: { 'X-PublicApiKey': queryArg['X-PublicApiKey'] },
      }),
    }),
    apiIntegrationGetIntegrationSettings: build.query<
      ApiIntegrationGetIntegrationSettingsApiResponse,
      ApiIntegrationGetIntegrationSettingsApiArg
    >({
      query: () => ({ url: `/api/app/api-integration/integration-settings` }),
    }),
    apiResourcesGetList: build.query<ApiResourcesGetListApiResponse, ApiResourcesGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/api-resources`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    apiResourcesCreate: build.mutation<ApiResourcesCreateApiResponse, ApiResourcesCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/api-resources`,
        method: 'POST',
        body: queryArg.voloAbpIdentityServerApiResourceDtosCreateApiResourceDto,
      }),
    }),
    apiResourcesDelete: build.mutation<ApiResourcesDeleteApiResponse, ApiResourcesDeleteApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/api-resources`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    apiResourcesGetAllList: build.query<
      ApiResourcesGetAllListApiResponse,
      ApiResourcesGetAllListApiArg
    >({
      query: () => ({ url: `/api/identity-server/api-resources/all` }),
    }),
    apiResourcesGet: build.query<ApiResourcesGetApiResponse, ApiResourcesGetApiArg>({
      query: (queryArg) => ({ url: `/api/identity-server/api-resources/${queryArg.id}` }),
    }),
    apiResourcesUpdate: build.mutation<ApiResourcesUpdateApiResponse, ApiResourcesUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/api-resources/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityServerApiResourceDtosUpdateApiResourceDto,
      }),
    }),
    apiScopesGetList: build.query<ApiScopesGetListApiResponse, ApiScopesGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/apiScopes`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    apiScopesCreate: build.mutation<ApiScopesCreateApiResponse, ApiScopesCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/apiScopes`,
        method: 'POST',
        body: queryArg.voloAbpIdentityServerApiScopeDtosCreateApiScopeDto,
      }),
    }),
    apiScopesDelete: build.mutation<ApiScopesDeleteApiResponse, ApiScopesDeleteApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/apiScopes`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    apiScopesGetAllList: build.query<ApiScopesGetAllListApiResponse, ApiScopesGetAllListApiArg>({
      query: () => ({ url: `/api/identity-server/apiScopes/all` }),
    }),
    apiScopesGet: build.query<ApiScopesGetApiResponse, ApiScopesGetApiArg>({
      query: (queryArg) => ({ url: `/api/identity-server/apiScopes/${queryArg.id}` }),
    }),
    apiScopesUpdate: build.mutation<ApiScopesUpdateApiResponse, ApiScopesUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/apiScopes/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityServerApiScopeDtosUpdateApiScopeDto,
      }),
    }),
    auditLogsGetList: build.query<AuditLogsGetListApiResponse, AuditLogsGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs`,
        params: {
          StartTime: queryArg.startTime,
          EndTime: queryArg.endTime,
          Url: queryArg.url,
          UserName: queryArg.userName,
          ApplicationName: queryArg.applicationName,
          ClientIpAddress: queryArg.clientIpAddress,
          CorrelationId: queryArg.correlationId,
          HttpMethod: queryArg.httpMethod,
          HttpStatusCode: queryArg.httpStatusCode,
          MaxExecutionDuration: queryArg.maxExecutionDuration,
          MinExecutionDuration: queryArg.minExecutionDuration,
          HasException: queryArg.hasException,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    auditLogsGet: build.query<AuditLogsGetApiResponse, AuditLogsGetApiArg>({
      query: (queryArg) => ({ url: `/api/audit-logging/audit-logs/${queryArg.id}` }),
    }),
    auditLogsGetErrorRate: build.query<
      AuditLogsGetErrorRateApiResponse,
      AuditLogsGetErrorRateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/statistics/error-rate`,
        params: { StartDate: queryArg.startDate, EndDate: queryArg.endDate },
      }),
    }),
    auditLogsGetAverageExecutionDurationPerDay: build.query<
      AuditLogsGetAverageExecutionDurationPerDayApiResponse,
      AuditLogsGetAverageExecutionDurationPerDayApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/statistics/average-execution-duration-per-day`,
        params: { StartDate: queryArg.startDate, EndDate: queryArg.endDate },
      }),
    }),
    auditLogsGetEntityChanges: build.query<
      AuditLogsGetEntityChangesApiResponse,
      AuditLogsGetEntityChangesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/entity-changes`,
        params: {
          AuditLogId: queryArg.auditLogId,
          EntityChangeType: queryArg.entityChangeType,
          EntityId: queryArg.entityId,
          EntityTypeFullName: queryArg.entityTypeFullName,
          StartDate: queryArg.startDate,
          EndDate: queryArg.endDate,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    auditLogsGetEntityChangesWithUsername: build.query<
      AuditLogsGetEntityChangesWithUsernameApiResponse,
      AuditLogsGetEntityChangesWithUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/entity-changes-with-username`,
        params: { EntityId: queryArg.entityId, EntityTypeFullName: queryArg.entityTypeFullName },
      }),
    }),
    auditLogsGetEntityChangeWithUsername: build.query<
      AuditLogsGetEntityChangeWithUsernameApiResponse,
      AuditLogsGetEntityChangeWithUsernameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/entity-change-with-username/${queryArg.entityChangeId}`,
      }),
    }),
    auditLogsGetEntityChange: build.query<
      AuditLogsGetEntityChangeApiResponse,
      AuditLogsGetEntityChangeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audit-logging/audit-logs/entity-changes/${queryArg.entityChangeId}`,
      }),
    }),
    identityClaimTypeGetList: build.query<
      IdentityClaimTypeGetListApiResponse,
      IdentityClaimTypeGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/claim-types`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    identityClaimTypeCreate: build.mutation<
      IdentityClaimTypeCreateApiResponse,
      IdentityClaimTypeCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/claim-types`,
        method: 'POST',
        body: queryArg.voloAbpIdentityCreateClaimTypeDto,
      }),
    }),
    identityClaimTypeGet: build.query<IdentityClaimTypeGetApiResponse, IdentityClaimTypeGetApiArg>({
      query: (queryArg) => ({ url: `/api/identity/claim-types/${queryArg.id}` }),
    }),
    identityClaimTypeUpdate: build.mutation<
      IdentityClaimTypeUpdateApiResponse,
      IdentityClaimTypeUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/claim-types/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityUpdateClaimTypeDto,
      }),
    }),
    identityClaimTypeDelete: build.mutation<
      IdentityClaimTypeDeleteApiResponse,
      IdentityClaimTypeDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/claim-types/${queryArg.id}`, method: 'DELETE' }),
    }),
    identityServerClaimTypesGetList: build.query<
      IdentityServerClaimTypesGetListApiResponse,
      IdentityServerClaimTypesGetListApiArg
    >({
      query: () => ({ url: `/api/identity-server/claim-types` }),
    }),
    clientsGetList: build.query<ClientsGetListApiResponse, ClientsGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/clients`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    clientsCreate: build.mutation<ClientsCreateApiResponse, ClientsCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/clients`,
        method: 'POST',
        body: queryArg.voloAbpIdentityServerClientDtosCreateClientDto,
      }),
    }),
    clientsDelete: build.mutation<ClientsDeleteApiResponse, ClientsDeleteApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/clients`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    clientsGet: build.query<ClientsGetApiResponse, ClientsGetApiArg>({
      query: (queryArg) => ({ url: `/api/identity-server/clients/${queryArg.id}` }),
    }),
    clientsUpdate: build.mutation<ClientsUpdateApiResponse, ClientsUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/identity-server/clients/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityServerClientDtosUpdateClientDto,
      }),
    }),
    dataExportTriggerUserExportEmail: build.mutation<
      DataExportTriggerUserExportEmailApiResponse,
      DataExportTriggerUserExportEmailApiArg
    >({
      query: () => ({ url: `/api/app/data-export/trigger-user-export-email`, method: 'POST' }),
    }),
    dataExportTriggerFacilitatorExportEmail: build.mutation<
      DataExportTriggerFacilitatorExportEmailApiResponse,
      DataExportTriggerFacilitatorExportEmailApiArg
    >({
      query: () => ({
        url: `/api/app/data-export/trigger-facilitator-export-email`,
        method: 'POST',
      }),
    }),
    dataExportTriggerParticipantExportEmail: build.mutation<
      DataExportTriggerParticipantExportEmailApiResponse,
      DataExportTriggerParticipantExportEmailApiArg
    >({
      query: () => ({
        url: `/api/app/data-export/trigger-participant-export-email`,
        method: 'POST',
      }),
    }),
    dataExportTriggerCourseTemplateExportEmail: build.mutation<
      DataExportTriggerCourseTemplateExportEmailApiResponse,
      DataExportTriggerCourseTemplateExportEmailApiArg
    >({
      query: () => ({
        url: `/api/app/data-export/trigger-course-template-export-email`,
        method: 'POST',
      }),
    }),
    dataExportTriggerCourseProgramExportEmail: build.mutation<
      DataExportTriggerCourseProgramExportEmailApiResponse,
      DataExportTriggerCourseProgramExportEmailApiArg
    >({
      query: () => ({
        url: `/api/app/data-export/trigger-course-program-export-email`,
        method: 'POST',
      }),
    }),
    dataExportTriggerCourseProgramParticipantExportEmail: build.mutation<
      DataExportTriggerCourseProgramParticipantExportEmailApiResponse,
      DataExportTriggerCourseProgramParticipantExportEmailApiArg
    >({
      query: () => ({
        url: `/api/app/data-export/trigger-course-program-participant-export-email`,
        method: 'POST',
      }),
    }),
    dataImportTriggerParticipantImport: build.mutation<
      DataImportTriggerParticipantImportApiResponse,
      DataImportTriggerParticipantImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/data-import/trigger-participant-import`,
        method: 'POST',
        body: queryArg.activ8DataDataImportTriggerParticipantDataImportRequest,
      }),
    }),
    dataImportPrepareImportFileWrite: build.mutation<
      DataImportPrepareImportFileWriteApiResponse,
      DataImportPrepareImportFileWriteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/data-import/prepare-import-file-write`,
        method: 'POST',
        params: {
          contentType: queryArg.contentType,
          contentFileName: queryArg.contentFileName,
          remoteIp: queryArg.remoteIp,
        },
      }),
    }),
    editionGet: build.query<EditionGetApiResponse, EditionGetApiArg>({
      query: (queryArg) => ({ url: `/api/saas/editions/${queryArg.id}` }),
    }),
    editionUpdate: build.mutation<EditionUpdateApiResponse, EditionUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/saas/editions/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voloSaasHostDtosEditionUpdateDto,
      }),
    }),
    editionDelete: build.mutation<EditionDeleteApiResponse, EditionDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/saas/editions/${queryArg.id}`, method: 'DELETE' }),
    }),
    editionGetList: build.query<EditionGetListApiResponse, EditionGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/saas/editions`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    editionCreate: build.mutation<EditionCreateApiResponse, EditionCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/saas/editions`,
        method: 'POST',
        body: queryArg.voloSaasHostDtosEditionCreateDto,
      }),
    }),
    editionGetUsageStatistics: build.query<
      EditionGetUsageStatisticsApiResponse,
      EditionGetUsageStatisticsApiArg
    >({
      query: () => ({ url: `/api/saas/editions/statistics/usage-statistic` }),
    }),
    editionGetPlanLookup: build.query<EditionGetPlanLookupApiResponse, EditionGetPlanLookupApiArg>({
      query: () => ({ url: `/api/saas/editions/plan-lookup` }),
    }),
    subscriptionCreateSubscription: build.mutation<
      SubscriptionCreateSubscriptionApiResponse,
      SubscriptionCreateSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/subscription`,
        method: 'POST',
        params: { editionId: queryArg.editionId, tenantId: queryArg.tenantId },
      }),
    }),
    emailSettingsGet: build.query<EmailSettingsGetApiResponse, EmailSettingsGetApiArg>({
      query: () => ({ url: `/api/setting-management/emailing` }),
    }),
    emailSettingsUpdate: build.mutation<EmailSettingsUpdateApiResponse, EmailSettingsUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/setting-management/emailing`,
        method: 'POST',
        body: queryArg.voloAbpSettingManagementUpdateEmailSettingsDto,
      }),
    }),
    facilitatorCreate: build.mutation<FacilitatorCreateApiResponse, FacilitatorCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/app/facilitator`,
        method: 'POST',
        body: queryArg.activ8FacilitatorsFacilitatorCreateDto,
      }),
    }),
    facilitatorGetList: build.query<FacilitatorGetListApiResponse, FacilitatorGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/app/facilitator`,
        params: {
          AssignedPersonAttributeIds: queryArg.assignedPersonAttributeIds,
          AssignedPersonAccreditationIds: queryArg.assignedPersonAccreditationIds,
          Filter: queryArg.filter,
          RoleId: queryArg.roleId,
          OrganizationUnitId: queryArg.organizationUnitId,
          UserName: queryArg.userName,
          PhoneNumber: queryArg.phoneNumber,
          EmailAddress: queryArg.emailAddress,
          IsLockedOut: queryArg.isLockedOut,
          NotActive: queryArg.notActive,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    facilitatorUpdate: build.mutation<FacilitatorUpdateApiResponse, FacilitatorUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/app/facilitator/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.activ8FacilitatorsFacilitatorUpdateDto,
      }),
    }),
    facilitatorGet: build.query<FacilitatorGetApiResponse, FacilitatorGetApiArg>({
      query: (queryArg) => ({ url: `/api/app/facilitator/${queryArg.id}` }),
    }),
    facilitatorDelete: build.mutation<FacilitatorDeleteApiResponse, FacilitatorDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/app/facilitator/${queryArg.id}`, method: 'DELETE' }),
    }),
    featuresGet: build.query<FeaturesGetApiResponse, FeaturesGetApiArg>({
      query: (queryArg) => ({
        url: `/api/feature-management/features`,
        params: { providerName: queryArg.providerName, providerKey: queryArg.providerKey },
      }),
    }),
    featuresUpdate: build.mutation<FeaturesUpdateApiResponse, FeaturesUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/feature-management/features`,
        method: 'PUT',
        body: queryArg.voloAbpFeatureManagementUpdateFeaturesDto,
        params: { providerName: queryArg.providerName, providerKey: queryArg.providerKey },
      }),
    }),
    identityResourcesGetList: build.query<
      IdentityResourcesGetListApiResponse,
      IdentityResourcesGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity-server/identity-resources`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    identityResourcesCreate: build.mutation<
      IdentityResourcesCreateApiResponse,
      IdentityResourcesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity-server/identity-resources`,
        method: 'POST',
        body: queryArg.voloAbpIdentityServerIdentityResourceDtosCreateIdentityResourceDto,
      }),
    }),
    identityResourcesDelete: build.mutation<
      IdentityResourcesDeleteApiResponse,
      IdentityResourcesDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity-server/identity-resources`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    identityResourcesGetAllList: build.query<
      IdentityResourcesGetAllListApiResponse,
      IdentityResourcesGetAllListApiArg
    >({
      query: () => ({ url: `/api/identity-server/identity-resources/all` }),
    }),
    identityResourcesGet: build.query<IdentityResourcesGetApiResponse, IdentityResourcesGetApiArg>({
      query: (queryArg) => ({ url: `/api/identity-server/identity-resources/${queryArg.id}` }),
    }),
    identityResourcesUpdate: build.mutation<
      IdentityResourcesUpdateApiResponse,
      IdentityResourcesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity-server/identity-resources/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityServerIdentityResourceDtosUpdateIdentityResourceDto,
      }),
    }),
    identityResourcesCreateStandardResources: build.mutation<
      IdentityResourcesCreateStandardResourcesApiResponse,
      IdentityResourcesCreateStandardResourcesApiArg
    >({
      query: () => ({
        url: `/api/identity-server/identity-resources/create-standard-resources`,
        method: 'POST',
      }),
    }),
    languageGetAllList: build.query<LanguageGetAllListApiResponse, LanguageGetAllListApiArg>({
      query: () => ({ url: `/api/language-management/languages/all` }),
    }),
    languageGetList: build.query<LanguageGetListApiResponse, LanguageGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/language-management/languages`,
        params: {
          Filter: queryArg.filter,
          ResourceName: queryArg.resourceName,
          BaseCultureName: queryArg.baseCultureName,
          TargetCultureName: queryArg.targetCultureName,
          GetOnlyEmptyValues: queryArg.getOnlyEmptyValues,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    languageCreate: build.mutation<LanguageCreateApiResponse, LanguageCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/language-management/languages`,
        method: 'POST',
        body: queryArg.voloAbpLanguageManagementDtoCreateLanguageDto,
      }),
    }),
    languageGet: build.query<LanguageGetApiResponse, LanguageGetApiArg>({
      query: (queryArg) => ({ url: `/api/language-management/languages/${queryArg.id}` }),
    }),
    languageUpdate: build.mutation<LanguageUpdateApiResponse, LanguageUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/language-management/languages/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voloAbpLanguageManagementDtoUpdateLanguageDto,
      }),
    }),
    languageDelete: build.mutation<LanguageDeleteApiResponse, LanguageDeleteApiArg>({
      query: (queryArg) => ({
        url: `/api/language-management/languages/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    languageSetAsDefault: build.mutation<
      LanguageSetAsDefaultApiResponse,
      LanguageSetAsDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/language-management/languages/${queryArg.id}/set-as-default`,
        method: 'PUT',
      }),
    }),
    languageGetResources: build.query<LanguageGetResourcesApiResponse, LanguageGetResourcesApiArg>({
      query: () => ({ url: `/api/language-management/languages/resources` }),
    }),
    languageGetCulturelist: build.query<
      LanguageGetCulturelistApiResponse,
      LanguageGetCulturelistApiArg
    >({
      query: () => ({ url: `/api/language-management/languages/culture-list` }),
    }),
    languageGetFlagList: build.query<LanguageGetFlagListApiResponse, LanguageGetFlagListApiArg>({
      query: () => ({ url: `/api/language-management/languages/flag-list` }),
    }),
    languageTextGetList: build.query<LanguageTextGetListApiResponse, LanguageTextGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/language-management/language-texts`,
        params: {
          Filter: queryArg.filter,
          ResourceName: queryArg.resourceName,
          BaseCultureName: queryArg.baseCultureName,
          TargetCultureName: queryArg.targetCultureName,
          GetOnlyEmptyValues: queryArg.getOnlyEmptyValues,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    languageTextGet: build.query<LanguageTextGetApiResponse, LanguageTextGetApiArg>({
      query: (queryArg) => ({
        url: `/api/language-management/language-texts/${queryArg.resourceName}/${queryArg.cultureName}/${queryArg.name}`,
        params: { baseCultureName: queryArg.baseCultureName },
      }),
    }),
    languageTextUpdate: build.mutation<LanguageTextUpdateApiResponse, LanguageTextUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/language-management/language-texts/${queryArg.resourceName}/${queryArg.cultureName}/${queryArg.name}`,
        method: 'PUT',
        params: { value: queryArg.value },
      }),
    }),
    languageTextRestoreToDefault: build.mutation<
      LanguageTextRestoreToDefaultApiResponse,
      LanguageTextRestoreToDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/language-management/language-texts/${queryArg.resourceName}/${queryArg.cultureName}/${queryArg.name}/restore`,
        method: 'PUT',
      }),
    }),
    learningCourseProgramUpdate: build.mutation<
      LearningCourseProgramUpdateApiResponse,
      LearningCourseProgramUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.activ8CoursesProgramsCoursesLearningCourseProgramUpdateDto,
      }),
    }),
    learningCourseProgramGet: build.query<
      LearningCourseProgramGetApiResponse,
      LearningCourseProgramGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/app/learning-course-program/${queryArg.id}` }),
    }),
    learningCourseProgramDelete: build.mutation<
      LearningCourseProgramDeleteApiResponse,
      LearningCourseProgramDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    learningCourseProgramCreate: build.mutation<
      LearningCourseProgramCreateApiResponse,
      LearningCourseProgramCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program`,
        method: 'POST',
        body: queryArg.activ8CoursesProgramsCoursesLearningCourseProgramCreateDto,
      }),
    }),
    learningCourseProgramGetList: build.query<
      LearningCourseProgramGetListApiResponse,
      LearningCourseProgramGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program`,
        params: {
          FilterText: queryArg.filterText,
          AssignedToFacilitatorIds: queryArg.assignedToFacilitatorIds,
          AssignedToParticipantIds: queryArg.assignedToParticipantIds,
          Statuses: queryArg.statuses,
          IncludeStatistics: queryArg.includeStatistics,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    learningCourseProgramSetProgramStatus: build.mutation<
      LearningCourseProgramSetProgramStatusApiResponse,
      LearningCourseProgramSetProgramStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/set-program-status`,
        method: 'POST',
        body: queryArg.activ8CoursesProgramsCoursesProgramStatusSetRequestDto,
      }),
    }),
    learningCourseProgramCloneCourseProgram: build.mutation<
      LearningCourseProgramCloneCourseProgramApiResponse,
      LearningCourseProgramCloneCourseProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/clone-course-program/${queryArg.courseProgramId}`,
        method: 'POST',
      }),
    }),
    learningCourseProgramAssignParticipantToProgram: build.mutation<
      LearningCourseProgramAssignParticipantToProgramApiResponse,
      LearningCourseProgramAssignParticipantToProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/assign-participant-to-program`,
        method: 'POST',
        params: {
          courseProgramId: queryArg.courseProgramId,
          participantIdentityUserId: queryArg.participantIdentityUserId,
        },
      }),
    }),
    learningCourseProgramUnassignParticipantToProgram: build.mutation<
      LearningCourseProgramUnassignParticipantToProgramApiResponse,
      LearningCourseProgramUnassignParticipantToProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/unassign-participant-to-program`,
        method: 'DELETE',
        params: {
          courseProgramId: queryArg.courseProgramId,
          participantIdentityUserId: queryArg.participantIdentityUserId,
        },
      }),
    }),
    learningCourseProgramGenerateUrlForDownloadCourseContent: build.query<
      LearningCourseProgramGenerateUrlForDownloadCourseContentApiResponse,
      LearningCourseProgramGenerateUrlForDownloadCourseContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program/generate-url-for-download-course-content/${queryArg.courseProgramId}`,
      }),
    }),
    learningCourseProgramFacilitatorGet: build.query<
      LearningCourseProgramFacilitatorGetApiResponse,
      LearningCourseProgramFacilitatorGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/app/learning-course-program-facilitator/${queryArg.id}` }),
    }),
    learningCourseProgramFacilitatorUpdate: build.mutation<
      LearningCourseProgramFacilitatorUpdateApiResponse,
      LearningCourseProgramFacilitatorUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-facilitator/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentUpdateDto,
      }),
    }),
    learningCourseProgramFacilitatorDelete: build.mutation<
      LearningCourseProgramFacilitatorDeleteApiResponse,
      LearningCourseProgramFacilitatorDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-facilitator/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    learningCourseProgramFacilitatorGetList: build.query<
      LearningCourseProgramFacilitatorGetListApiResponse,
      LearningCourseProgramFacilitatorGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-facilitator`,
        params: {
          FilterText: queryArg.filterText,
          FacilitatorIdentityUserIds: queryArg.facilitatorIdentityUserIds,
          LearningCourseProgramIds: queryArg.learningCourseProgramIds,
          IncludeProgramDetails: queryArg.includeProgramDetails,
          IncludeFacilitatorDetails: queryArg.includeFacilitatorDetails,
          ProgramStatuses: queryArg.programStatuses,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    learningCourseProgramFacilitatorCreate: build.mutation<
      LearningCourseProgramFacilitatorCreateApiResponse,
      LearningCourseProgramFacilitatorCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-facilitator`,
        method: 'POST',
        body: queryArg.activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentCreateDto,
      }),
    }),
    learningCourseProgramParticipantGetList: build.query<
      LearningCourseProgramParticipantGetListApiResponse,
      LearningCourseProgramParticipantGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-participant`,
        params: {
          FilterText: queryArg.filterText,
          LearningCourseProgramIds: queryArg.learningCourseProgramIds,
          ParticipantIdentityUserIds: queryArg.participantIdentityUserIds,
          IncludeProgramDetails: queryArg.includeProgramDetails,
          IncludeParticipantDetails: queryArg.includeParticipantDetails,
          ProgramStatuses: queryArg.programStatuses,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    learningCourseProgramParticipantCreate: build.mutation<
      LearningCourseProgramParticipantCreateApiResponse,
      LearningCourseProgramParticipantCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-participant`,
        method: 'POST',
        body: queryArg.activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentCreateDto,
      }),
    }),
    learningCourseProgramParticipantGet: build.query<
      LearningCourseProgramParticipantGetApiResponse,
      LearningCourseProgramParticipantGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/app/learning-course-program-participant/${queryArg.id}` }),
    }),
    learningCourseProgramParticipantUpdate: build.mutation<
      LearningCourseProgramParticipantUpdateApiResponse,
      LearningCourseProgramParticipantUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-participant/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentUpdateDto,
      }),
    }),
    learningCourseProgramParticipantDelete: build.mutation<
      LearningCourseProgramParticipantDeleteApiResponse,
      LearningCourseProgramParticipantDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-participant/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    learningCourseProgramParticipantGetByProgramAndParticipant: build.query<
      LearningCourseProgramParticipantGetByProgramAndParticipantApiResponse,
      LearningCourseProgramParticipantGetByProgramAndParticipantApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-participant/by-program-and-participant`,
        params: { programId: queryArg.programId, participantId: queryArg.participantId },
      }),
    }),
    learningCourseProgramSessionPrepareSessionContent: build.query<
      LearningCourseProgramSessionPrepareSessionContentApiResponse,
      LearningCourseProgramSessionPrepareSessionContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-session/prepare-session-content`,
        params: {
          courseProgramId: queryArg.courseProgramId,
          courseProgramChapterId: queryArg.courseProgramChapterId,
          courseProgramChapterSessionId: queryArg.courseProgramChapterSessionId,
        },
      }),
    }),
    learningCourseProgramSessionProgressThroughSessionContent: build.mutation<
      LearningCourseProgramSessionProgressThroughSessionContentApiResponse,
      LearningCourseProgramSessionProgressThroughSessionContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-session/progress-through-session-content`,
        method: 'POST',
        body: queryArg.activ8CoursesProgramsSessionsProgressThroughSessionContentRequest,
      }),
    }),
    learningCourseProgramSessionProgressThroughSessionContentBatch: build.mutation<
      LearningCourseProgramSessionProgressThroughSessionContentBatchApiResponse,
      LearningCourseProgramSessionProgressThroughSessionContentBatchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-program-session/progress-through-session-content-batch`,
        method: 'POST',
        body: queryArg.activ8CoursesProgramsSessionsProgressThroughSessionContentBatchRequest,
      }),
    }),
    learningCourseTemplateSetTemplateStatus: build.mutation<
      LearningCourseTemplateSetTemplateStatusApiResponse,
      LearningCourseTemplateSetTemplateStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/set-template-status`,
        method: 'POST',
        body: queryArg.activ8CoursesTemplatesTemplateStatusSetRequestDto,
      }),
    }),
    learningCourseTemplateGetRoleNamesApplicableToCourseAssignments: build.query<
      LearningCourseTemplateGetRoleNamesApplicableToCourseAssignmentsApiResponse,
      LearningCourseTemplateGetRoleNamesApplicableToCourseAssignmentsApiArg
    >({
      query: () => ({
        url: `/api/app/learning-course-template/role-names-applicable-to-course-assignments`,
      }),
    }),
    learningCourseTemplateDelete: build.mutation<
      LearningCourseTemplateDeleteApiResponse,
      LearningCourseTemplateDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    learningCourseTemplateGet: build.query<
      LearningCourseTemplateGetApiResponse,
      LearningCourseTemplateGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/app/learning-course-template/${queryArg.id}` }),
    }),
    learningCourseTemplateUpdate: build.mutation<
      LearningCourseTemplateUpdateApiResponse,
      LearningCourseTemplateUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.activ8CoursesTemplatesLearningCourseTemplateUpdateDto,
      }),
    }),
    learningCourseTemplateCloneCourseTemplate: build.mutation<
      LearningCourseTemplateCloneCourseTemplateApiResponse,
      LearningCourseTemplateCloneCourseTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/clone-course-template/${queryArg.courseTemplateId}`,
        method: 'POST',
      }),
    }),
    learningCourseTemplateGetTemplatePartnerLicensing: build.query<
      LearningCourseTemplateGetTemplatePartnerLicensingApiResponse,
      LearningCourseTemplateGetTemplatePartnerLicensingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/template-partner-licensing/${queryArg.courseTemplateId}`,
      }),
    }),
    learningCourseTemplatePrepareResourceWrite: build.mutation<
      LearningCourseTemplatePrepareResourceWriteApiResponse,
      LearningCourseTemplatePrepareResourceWriteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/prepare-resource-write`,
        method: 'POST',
        params: {
          courseTemplateId: queryArg.courseTemplateId,
          resourceId: queryArg.resourceId,
          contentType: queryArg.contentType,
          contentFileName: queryArg.contentFileName,
        },
      }),
    }),
    learningCourseTemplatePrepareResourceRead: build.query<
      LearningCourseTemplatePrepareResourceReadApiResponse,
      LearningCourseTemplatePrepareResourceReadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/prepare-resource-read`,
        params: {
          courseTemplateId: queryArg.courseTemplateId,
          resourceId: queryArg.resourceId,
          isDownload: queryArg.isDownload,
          contentType: queryArg.contentType,
          contentFileName: queryArg.contentFileName,
        },
      }),
    }),
    learningCourseTemplateCreate: build.mutation<
      LearningCourseTemplateCreateApiResponse,
      LearningCourseTemplateCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template`,
        method: 'POST',
        body: queryArg.activ8CoursesTemplatesLearningCourseTemplateCreateDto,
      }),
    }),
    learningCourseTemplateGetList: build.query<
      LearningCourseTemplateGetListApiResponse,
      LearningCourseTemplateGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template`,
        params: {
          FilterText: queryArg.filterText,
          LicensedToTenantIds: queryArg.licensedToTenantIds,
          EnrollmentAllowedForIdentityRoleNames: queryArg.enrollmentAllowedForIdentityRoleNames,
          Statuses: queryArg.statuses,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    learningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplate: build.query<
      LearningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplateApiResponse,
      LearningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/count-of-live-programs-using-course-template/${queryArg.courseTemplateId}`,
      }),
    }),
    learningCourseTemplateGetTemplateContent: build.query<
      LearningCourseTemplateGetTemplateContentApiResponse,
      LearningCourseTemplateGetTemplateContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/template-content/${queryArg.courseTemplateId}`,
      }),
    }),
    learningCourseTemplateGenerateUrlForDownloadCourseContent: build.query<
      LearningCourseTemplateGenerateUrlForDownloadCourseContentApiResponse,
      LearningCourseTemplateGenerateUrlForDownloadCourseContentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/learning-course-template/generate-url-for-download-course-content/${queryArg.courseTemplateId}`,
      }),
    }),
    leptonThemeSettingsGet: build.query<
      LeptonThemeSettingsGetApiResponse,
      LeptonThemeSettingsGetApiArg
    >({
      query: () => ({ url: `/api/lepton-theme-management/settings` }),
    }),
    leptonThemeSettingsUpdate: build.mutation<
      LeptonThemeSettingsUpdateApiResponse,
      LeptonThemeSettingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/lepton-theme-management/settings`,
        method: 'PUT',
        body: queryArg.voloAbpLeptonThemeManagementUpdateLeptonThemeSettingsDto,
      }),
    }),
    organizationUnitAddRoles: build.mutation<
      OrganizationUnitAddRolesApiResponse,
      OrganizationUnitAddRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/roles`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityOrganizationUnitRoleInput,
      }),
    }),
    organizationUnitGetRoles: build.query<
      OrganizationUnitGetRolesApiResponse,
      OrganizationUnitGetRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/roles`,
        params: {
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          Sorting: queryArg.sorting,
        },
      }),
    }),
    organizationUnitAddMembers: build.mutation<
      OrganizationUnitAddMembersApiResponse,
      OrganizationUnitAddMembersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/members`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityOrganizationUnitUserInput,
      }),
    }),
    organizationUnitGetMembers: build.query<
      OrganizationUnitGetMembersApiResponse,
      OrganizationUnitGetMembersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/members`,
        params: {
          Filter: queryArg.filter,
          RoleId: queryArg.roleId,
          OrganizationUnitId: queryArg.organizationUnitId,
          UserName: queryArg.userName,
          PhoneNumber: queryArg.phoneNumber,
          EmailAddress: queryArg.emailAddress,
          IsLockedOut: queryArg.isLockedOut,
          NotActive: queryArg.notActive,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    organizationUnitCreate: build.mutation<
      OrganizationUnitCreateApiResponse,
      OrganizationUnitCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units`,
        method: 'POST',
        body: queryArg.voloAbpIdentityOrganizationUnitCreateDto,
      }),
    }),
    organizationUnitDelete: build.mutation<
      OrganizationUnitDeleteApiResponse,
      OrganizationUnitDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    organizationUnitGetList: build.query<
      OrganizationUnitGetListApiResponse,
      OrganizationUnitGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    organizationUnitGet: build.query<OrganizationUnitGetApiResponse, OrganizationUnitGetApiArg>({
      query: (queryArg) => ({ url: `/api/identity/organization-units/${queryArg.id}` }),
    }),
    organizationUnitUpdate: build.mutation<
      OrganizationUnitUpdateApiResponse,
      OrganizationUnitUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityOrganizationUnitUpdateDto,
      }),
    }),
    organizationUnitGetListAll: build.query<
      OrganizationUnitGetListAllApiResponse,
      OrganizationUnitGetListAllApiArg
    >({
      query: () => ({ url: `/api/identity/organization-units/all` }),
    }),
    organizationUnitMove: build.mutation<
      OrganizationUnitMoveApiResponse,
      OrganizationUnitMoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/move`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityOrganizationUnitMoveInput,
      }),
    }),
    organizationUnitGetAvailableUsers: build.query<
      OrganizationUnitGetAvailableUsersApiResponse,
      OrganizationUnitGetAvailableUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/available-users`,
        params: {
          Filter: queryArg.filter,
          Id: queryArg.id,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    organizationUnitGetAvailableRoles: build.query<
      OrganizationUnitGetAvailableRolesApiResponse,
      OrganizationUnitGetAvailableRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/available-roles`,
        params: {
          Filter: queryArg.filter,
          Id: queryArg.id,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    organizationUnitRemoveMember: build.mutation<
      OrganizationUnitRemoveMemberApiResponse,
      OrganizationUnitRemoveMemberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/members/${queryArg.memberId}`,
        method: 'DELETE',
      }),
    }),
    organizationUnitRemoveRole: build.mutation<
      OrganizationUnitRemoveRoleApiResponse,
      OrganizationUnitRemoveRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/organization-units/${queryArg.id}/roles/${queryArg.roleId}`,
        method: 'DELETE',
      }),
    }),
    participantCreate: build.mutation<ParticipantCreateApiResponse, ParticipantCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/app/participant`,
        method: 'POST',
        body: queryArg.activ8ParticipantsParticipantCreateDto,
      }),
    }),
    participantGetList: build.query<ParticipantGetListApiResponse, ParticipantGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/app/participant`,
        params: {
          AssignedPersonAttributeIds: queryArg.assignedPersonAttributeIds,
          AssignedPersonAccreditationIds: queryArg.assignedPersonAccreditationIds,
          Filter: queryArg.filter,
          RoleId: queryArg.roleId,
          OrganizationUnitId: queryArg.organizationUnitId,
          UserName: queryArg.userName,
          PhoneNumber: queryArg.phoneNumber,
          EmailAddress: queryArg.emailAddress,
          IsLockedOut: queryArg.isLockedOut,
          NotActive: queryArg.notActive,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    participantUpdate: build.mutation<ParticipantUpdateApiResponse, ParticipantUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/app/participant/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.activ8ParticipantsParticipantUpdateDto,
      }),
    }),
    participantGet: build.query<ParticipantGetApiResponse, ParticipantGetApiArg>({
      query: (queryArg) => ({ url: `/api/app/participant/${queryArg.id}` }),
    }),
    participantDelete: build.mutation<ParticipantDeleteApiResponse, ParticipantDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/app/participant/${queryArg.id}`, method: 'DELETE' }),
    }),
    participantRemoveIdentityUserLink: build.mutation<
      ParticipantRemoveIdentityUserLinkApiResponse,
      ParticipantRemoveIdentityUserLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/participant/remove-identity-user-link`,
        method: 'POST',
        params: {
          parentIdentityUserId: queryArg.parentIdentityUserId,
          childIdentityUserId: queryArg.childIdentityUserId,
        },
      }),
    }),
    participantMaintainIdentityUserLinks: build.mutation<
      ParticipantMaintainIdentityUserLinksApiResponse,
      ParticipantMaintainIdentityUserLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/participant/maintain-identity-user-links`,
        method: 'POST',
        params: {
          parentIdentityUserId: queryArg.parentIdentityUserId,
          childIdentityUserId: queryArg.childIdentityUserId,
          unlinkAllChildParentLinks: queryArg.unlinkAllChildParentLinks,
        },
      }),
    }),
    partnerAppNotificationGetAll: build.query<
      PartnerAppNotificationGetAllApiResponse,
      PartnerAppNotificationGetAllApiArg
    >({
      query: () => ({ url: `/api/app/partner-app-notification` }),
    }),
    partnerDashboardGet: build.query<PartnerDashboardGetApiResponse, PartnerDashboardGetApiArg>({
      query: () => ({ url: `/api/app/partner-dashboard` }),
    }),
    partnerManagementEnsurePartnerSetup: build.mutation<
      PartnerManagementEnsurePartnerSetupApiResponse,
      PartnerManagementEnsurePartnerSetupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/partner-management/ensure-partner-setup/${queryArg.tenantId}`,
        method: 'POST',
      }),
    }),
    partnerProfileGet: build.query<PartnerProfileGetApiResponse, PartnerProfileGetApiArg>({
      query: () => ({ url: `/api/app/partner-profile` }),
    }),
    partnerProfileSet: build.mutation<PartnerProfileSetApiResponse, PartnerProfileSetApiArg>({
      query: (queryArg) => ({
        url: `/api/app/partner-profile/set`,
        method: 'POST',
        body: queryArg.activ8PartnersProfilePartnerProfileDetailSetRequest,
      }),
    }),
    permissionsGet: build.query<PermissionsGetApiResponse, PermissionsGetApiArg>({
      query: (queryArg) => ({
        url: `/api/permission-management/permissions`,
        params: { providerName: queryArg.providerName, providerKey: queryArg.providerKey },
      }),
    }),
    permissionsUpdate: build.mutation<PermissionsUpdateApiResponse, PermissionsUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/permission-management/permissions`,
        method: 'PUT',
        body: queryArg.voloAbpPermissionManagementUpdatePermissionsDto,
        params: { providerName: queryArg.providerName, providerKey: queryArg.providerKey },
      }),
    }),
    personAccreditationGetAssignmentsForUser: build.query<
      PersonAccreditationGetAssignmentsForUserApiResponse,
      PersonAccreditationGetAssignmentsForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation/assignments-for-user/${queryArg.userId}`,
      }),
    }),
    personAccreditationSetAssignmentsForUser: build.mutation<
      PersonAccreditationSetAssignmentsForUserApiResponse,
      PersonAccreditationSetAssignmentsForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation/set-assignments-for-user/${queryArg.userId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    personAccreditationGetList: build.query<
      PersonAccreditationGetListApiResponse,
      PersonAccreditationGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation`,
        params: {
          FilterText: queryArg.filterText,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    personAccreditationCreate: build.mutation<
      PersonAccreditationCreateApiResponse,
      PersonAccreditationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation`,
        method: 'POST',
        body: queryArg.activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto,
      }),
    }),
    personAccreditationUpdate: build.mutation<
      PersonAccreditationUpdateApiResponse,
      PersonAccreditationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto,
      }),
    }),
    personAccreditationDelete: build.mutation<
      PersonAccreditationDeleteApiResponse,
      PersonAccreditationDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-accreditation/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    personAccreditationGet: build.query<
      PersonAccreditationGetApiResponse,
      PersonAccreditationGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/app/person-accreditation/${queryArg.id}` }),
    }),
    personAttributeGetAssignmentsForUser: build.query<
      PersonAttributeGetAssignmentsForUserApiResponse,
      PersonAttributeGetAssignmentsForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute/assignments-for-user/${queryArg.userId}`,
      }),
    }),
    personAttributeSetAssignmentsForUser: build.mutation<
      PersonAttributeSetAssignmentsForUserApiResponse,
      PersonAttributeSetAssignmentsForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute/set-assignments-for-user/${queryArg.userId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    personAttributeGetList: build.query<
      PersonAttributeGetListApiResponse,
      PersonAttributeGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute`,
        params: {
          FilterText: queryArg.filterText,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    personAttributeCreate: build.mutation<
      PersonAttributeCreateApiResponse,
      PersonAttributeCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute`,
        method: 'POST',
        body: queryArg.activ8PersonAttributesPersonAttributeCreateOrUpdateDto,
      }),
    }),
    personAttributeUpdate: build.mutation<
      PersonAttributeUpdateApiResponse,
      PersonAttributeUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/person-attribute/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.activ8PersonAttributesPersonAttributeCreateOrUpdateDto,
      }),
    }),
    personAttributeDelete: build.mutation<
      PersonAttributeDeleteApiResponse,
      PersonAttributeDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/api/app/person-attribute/${queryArg.id}`, method: 'DELETE' }),
    }),
    personAttributeGet: build.query<PersonAttributeGetApiResponse, PersonAttributeGetApiArg>({
      query: (queryArg) => ({ url: `/api/app/person-attribute/${queryArg.id}` }),
    }),
    identityRoleGet: build.query<IdentityRoleGetApiResponse, IdentityRoleGetApiArg>({
      query: (queryArg) => ({ url: `/api/identity/roles/${queryArg.id}` }),
    }),
    identityRoleUpdate: build.mutation<IdentityRoleUpdateApiResponse, IdentityRoleUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/identity/roles/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityIdentityRoleUpdateDto,
      }),
    }),
    identityRoleDelete: build.mutation<IdentityRoleDeleteApiResponse, IdentityRoleDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/identity/roles/${queryArg.id}`, method: 'DELETE' }),
    }),
    identityRoleCreate: build.mutation<IdentityRoleCreateApiResponse, IdentityRoleCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/identity/roles`,
        method: 'POST',
        body: queryArg.voloAbpIdentityIdentityRoleCreateDto,
      }),
    }),
    identityRoleGetList: build.query<IdentityRoleGetListApiResponse, IdentityRoleGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/identity/roles`,
        params: {
          Filter: queryArg.filter,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    identityRoleGetAllList: build.query<
      IdentityRoleGetAllListApiResponse,
      IdentityRoleGetAllListApiArg
    >({
      query: () => ({ url: `/api/identity/roles/all` }),
    }),
    identityRoleUpdateClaims: build.mutation<
      IdentityRoleUpdateClaimsApiResponse,
      IdentityRoleUpdateClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/roles/${queryArg.id}/claims`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    identityRoleGetClaims: build.query<
      IdentityRoleGetClaimsApiResponse,
      IdentityRoleGetClaimsApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/roles/${queryArg.id}/claims` }),
    }),
    identityRoleGetAllClaimTypes: build.query<
      IdentityRoleGetAllClaimTypesApiResponse,
      IdentityRoleGetAllClaimTypesApiArg
    >({
      query: () => ({ url: `/api/identity/roles/all-claim-types` }),
    }),
    identitySecurityLogGetList: build.query<
      IdentitySecurityLogGetListApiResponse,
      IdentitySecurityLogGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/security-logs`,
        params: {
          StartTime: queryArg.startTime,
          EndTime: queryArg.endTime,
          ApplicationName: queryArg.applicationName,
          Identity: queryArg.identity,
          Action: queryArg.action,
          UserName: queryArg.userName,
          ClientId: queryArg.clientId,
          CorrelationId: queryArg.correlationId,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    identitySecurityLogGet: build.query<
      IdentitySecurityLogGetApiResponse,
      IdentitySecurityLogGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/security-logs/${queryArg.id}` }),
    }),
    identitySecurityLogGetMyList: build.query<
      IdentitySecurityLogGetMyListApiResponse,
      IdentitySecurityLogGetMyListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/security-logs/my`,
        params: {
          StartTime: queryArg.startTime,
          EndTime: queryArg.endTime,
          ApplicationName: queryArg.applicationName,
          Identity: queryArg.identity,
          Action: queryArg.action,
          UserName: queryArg.userName,
          ClientId: queryArg.clientId,
          CorrelationId: queryArg.correlationId,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    identitySecurityLogGetMy: build.query<
      IdentitySecurityLogGetMyApiResponse,
      IdentitySecurityLogGetMyApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/security-logs/my/${queryArg.id}` }),
    }),
    identitySettingsGet: build.query<IdentitySettingsGetApiResponse, IdentitySettingsGetApiArg>({
      query: () => ({ url: `/api/identity/settings` }),
    }),
    identitySettingsUpdate: build.mutation<
      IdentitySettingsUpdateApiResponse,
      IdentitySettingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/settings`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityIdentitySettingsDto,
      }),
    }),
    identitySettingsGetLdap: build.query<
      IdentitySettingsGetLdapApiResponse,
      IdentitySettingsGetLdapApiArg
    >({
      query: () => ({ url: `/api/identity/settings/ldap` }),
    }),
    identitySettingsUpdateLdap: build.mutation<
      IdentitySettingsUpdateLdapApiResponse,
      IdentitySettingsUpdateLdapApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/settings/ldap`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityIdentityLdapSettingsDto,
      }),
    }),
    identitySettingsGetOAuth: build.query<
      IdentitySettingsGetOAuthApiResponse,
      IdentitySettingsGetOAuthApiArg
    >({
      query: () => ({ url: `/api/identity/settings/oauth` }),
    }),
    identitySettingsUpdateOAuth: build.mutation<
      IdentitySettingsUpdateOAuthApiResponse,
      IdentitySettingsUpdateOAuthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/settings/oauth`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityIdentityOAuthSettingsDto,
      }),
    }),
    tenantGet: build.query<TenantGetApiResponse, TenantGetApiArg>({
      query: (queryArg) => ({ url: `/api/saas/tenants/${queryArg.id}` }),
    }),
    tenantUpdate: build.mutation<TenantUpdateApiResponse, TenantUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/saas/tenants/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voloSaasHostDtosSaasTenantUpdateDto,
      }),
    }),
    tenantDelete: build.mutation<TenantDeleteApiResponse, TenantDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/saas/tenants/${queryArg.id}`, method: 'DELETE' }),
    }),
    tenantGetList: build.query<TenantGetListApiResponse, TenantGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/saas/tenants`,
        params: {
          Filter: queryArg.filter,
          GetEditionNames: queryArg.getEditionNames,
          EditionId: queryArg.editionId,
          ExpirationDateMin: queryArg.expirationDateMin,
          ExpirationDateMax: queryArg.expirationDateMax,
          ActivationState: queryArg.activationState,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    tenantCreate: build.mutation<TenantCreateApiResponse, TenantCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/saas/tenants`,
        method: 'POST',
        body: queryArg.voloSaasHostDtosSaasTenantCreateDto,
      }),
    }),
    tenantGetDatabases: build.query<TenantGetDatabasesApiResponse, TenantGetDatabasesApiArg>({
      query: () => ({ url: `/api/saas/tenants/databases` }),
    }),
    tenantGetConnectionStrings: build.query<
      TenantGetConnectionStringsApiResponse,
      TenantGetConnectionStringsApiArg
    >({
      query: (queryArg) => ({ url: `/api/saas/tenants/${queryArg.id}/connection-strings` }),
    }),
    tenantUpdateConnectionStrings: build.mutation<
      TenantUpdateConnectionStringsApiResponse,
      TenantUpdateConnectionStringsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/tenants/${queryArg.id}/connection-strings`,
        method: 'PUT',
        body: queryArg.voloSaasHostDtosSaasTenantConnectionStringsDto,
      }),
    }),
    tenantApplyDatabaseMigrations: build.mutation<
      TenantApplyDatabaseMigrationsApiResponse,
      TenantApplyDatabaseMigrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/saas/tenants/${queryArg.id}/apply-database-migrations`,
        method: 'POST',
      }),
    }),
    tenantGetEditionLookup: build.query<
      TenantGetEditionLookupApiResponse,
      TenantGetEditionLookupApiArg
    >({
      query: () => ({ url: `/api/saas/tenants/lookup/editions` }),
    }),
    templateContentGet: build.query<TemplateContentGetApiResponse, TemplateContentGetApiArg>({
      query: (queryArg) => ({
        url: `/api/text-template-management/template-contents`,
        params: { TemplateName: queryArg.templateName, CultureName: queryArg.cultureName },
      }),
    }),
    templateContentUpdate: build.mutation<
      TemplateContentUpdateApiResponse,
      TemplateContentUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/text-template-management/template-contents`,
        method: 'PUT',
        body: queryArg.voloAbpTextTemplateManagementTextTemplatesUpdateTemplateContentInput,
      }),
    }),
    templateContentRestoreToDefault: build.mutation<
      TemplateContentRestoreToDefaultApiResponse,
      TemplateContentRestoreToDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/text-template-management/template-contents/restore-to-default`,
        method: 'PUT',
        body: queryArg.voloAbpTextTemplateManagementTextTemplatesRestoreTemplateContentInput,
      }),
    }),
    templateDefinitionGetList: build.query<
      TemplateDefinitionGetListApiResponse,
      TemplateDefinitionGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/text-template-management/template-definitions`,
        params: {
          FilterText: queryArg.filterText,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    templateDefinitionGet: build.query<
      TemplateDefinitionGetApiResponse,
      TemplateDefinitionGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/text-template-management/template-definitions/${queryArg.name}`,
      }),
    }),
    timezoneLookupGetAllIana: build.query<
      TimezoneLookupGetAllIanaApiResponse,
      TimezoneLookupGetAllIanaApiArg
    >({
      query: () => ({ url: `/api/app/timezone-lookup/iana` }),
    }),
    timezoneLookupGetAllWindows: build.query<
      TimezoneLookupGetAllWindowsApiResponse,
      TimezoneLookupGetAllWindowsApiArg
    >({
      query: () => ({ url: `/api/app/timezone-lookup/windows` }),
    }),
    identityUserGet: build.query<IdentityUserGetApiResponse, IdentityUserGetApiArg>({
      query: (queryArg) => ({ url: `/api/identity/users/${queryArg.id}` }),
    }),
    identityUserUpdate: build.mutation<IdentityUserUpdateApiResponse, IdentityUserUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityIdentityUserUpdateDto,
      }),
    }),
    identityUserDelete: build.mutation<IdentityUserDeleteApiResponse, IdentityUserDeleteApiArg>({
      query: (queryArg) => ({ url: `/api/identity/users/${queryArg.id}`, method: 'DELETE' }),
    }),
    identityUserGetList: build.query<IdentityUserGetListApiResponse, IdentityUserGetListApiArg>({
      query: (queryArg) => ({
        url: `/api/identity/users`,
        params: {
          Filter: queryArg.filter,
          RoleId: queryArg.roleId,
          OrganizationUnitId: queryArg.organizationUnitId,
          UserName: queryArg.userName,
          PhoneNumber: queryArg.phoneNumber,
          EmailAddress: queryArg.emailAddress,
          IsLockedOut: queryArg.isLockedOut,
          NotActive: queryArg.notActive,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    identityUserCreate: build.mutation<IdentityUserCreateApiResponse, IdentityUserCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/identity/users`,
        method: 'POST',
        body: queryArg.voloAbpIdentityIdentityUserCreateDto,
      }),
    }),
    identityUserGetRoles: build.query<IdentityUserGetRolesApiResponse, IdentityUserGetRolesApiArg>({
      query: (queryArg) => ({ url: `/api/identity/users/${queryArg.id}/roles` }),
    }),
    identityUserUpdateRoles: build.mutation<
      IdentityUserUpdateRolesApiResponse,
      IdentityUserUpdateRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/roles`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityIdentityUserUpdateRolesDto,
      }),
    }),
    identityUserGetAssignableRoles: build.query<
      IdentityUserGetAssignableRolesApiResponse,
      IdentityUserGetAssignableRolesApiArg
    >({
      query: () => ({ url: `/api/identity/users/assignable-roles` }),
    }),
    identityUserGetAvailableOrganizationUnits: build.query<
      IdentityUserGetAvailableOrganizationUnitsApiResponse,
      IdentityUserGetAvailableOrganizationUnitsApiArg
    >({
      query: () => ({ url: `/api/identity/users/available-organization-units` }),
    }),
    identityUserGetAllClaimTypes: build.query<
      IdentityUserGetAllClaimTypesApiResponse,
      IdentityUserGetAllClaimTypesApiArg
    >({
      query: () => ({ url: `/api/identity/users/all-claim-types` }),
    }),
    identityUserGetClaims: build.query<
      IdentityUserGetClaimsApiResponse,
      IdentityUserGetClaimsApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/users/${queryArg.id}/claims` }),
    }),
    identityUserUpdateClaims: build.mutation<
      IdentityUserUpdateClaimsApiResponse,
      IdentityUserUpdateClaimsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/claims`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    identityUserGetOrganizationUnits: build.query<
      IdentityUserGetOrganizationUnitsApiResponse,
      IdentityUserGetOrganizationUnitsApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/users/${queryArg.id}/organization-units` }),
    }),
    identityUserLock: build.mutation<IdentityUserLockApiResponse, IdentityUserLockApiArg>({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/lock/${queryArg.lockoutEnd}`,
        method: 'PUT',
      }),
    }),
    identityUserUnlock: build.mutation<IdentityUserUnlockApiResponse, IdentityUserUnlockApiArg>({
      query: (queryArg) => ({ url: `/api/identity/users/${queryArg.id}/unlock`, method: 'PUT' }),
    }),
    identityUserFindByUsername: build.query<
      IdentityUserFindByUsernameApiResponse,
      IdentityUserFindByUsernameApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/users/by-username/${queryArg.username}` }),
    }),
    identityUserFindByEmail: build.query<
      IdentityUserFindByEmailApiResponse,
      IdentityUserFindByEmailApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/users/by-email/${queryArg.email}` }),
    }),
    identityUserGetTwoFactorEnabled: build.query<
      IdentityUserGetTwoFactorEnabledApiResponse,
      IdentityUserGetTwoFactorEnabledApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/users/${queryArg.id}/two-factor-enabled` }),
    }),
    identityUserSetTwoFactorEnabled: build.mutation<
      IdentityUserSetTwoFactorEnabledApiResponse,
      IdentityUserSetTwoFactorEnabledApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/two-factor/${queryArg.enabled}`,
        method: 'PUT',
      }),
    }),
    identityUserUpdatePassword: build.mutation<
      IdentityUserUpdatePasswordApiResponse,
      IdentityUserUpdatePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/${queryArg.id}/change-password`,
        method: 'PUT',
        body: queryArg.voloAbpIdentityIdentityUserUpdatePasswordInput,
      }),
    }),
    identityUserGetRoleLookup: build.query<
      IdentityUserGetRoleLookupApiResponse,
      IdentityUserGetRoleLookupApiArg
    >({
      query: () => ({ url: `/api/identity/users/lookup/roles` }),
    }),
    identityUserGetOrganizationUnitLookup: build.query<
      IdentityUserGetOrganizationUnitLookupApiResponse,
      IdentityUserGetOrganizationUnitLookupApiArg
    >({
      query: () => ({ url: `/api/identity/users/lookup/organization-units` }),
    }),
    identityUserGetExternalLoginProviders: build.query<
      IdentityUserGetExternalLoginProvidersApiResponse,
      IdentityUserGetExternalLoginProvidersApiArg
    >({
      query: () => ({ url: `/api/identity/users/external-login-Providers` }),
    }),
    identityUserImportExternalUser: build.mutation<
      IdentityUserImportExternalUserApiResponse,
      IdentityUserImportExternalUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/import-external-user`,
        method: 'POST',
        body: queryArg.voloAbpIdentityImportExternalUserInput,
      }),
    }),
    userLinkageShareUserWithAnotherTenant: build.mutation<
      UserLinkageShareUserWithAnotherTenantApiResponse,
      UserLinkageShareUserWithAnotherTenantApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/user-linkage/share-user-with-another-tenant`,
        method: 'POST',
        body: queryArg.activ8UsersShareUserWithAnotherPartnerRequest,
      }),
    }),
    userLinkageGetAllUserLinkages: build.query<
      UserLinkageGetAllUserLinkagesApiResponse,
      UserLinkageGetAllUserLinkagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app/user-linkage/user-linkages`,
        params: { userId: queryArg.userId, tenantId: queryArg.tenantId },
      }),
    }),
    identityUserLookupFindById: build.query<
      IdentityUserLookupFindByIdApiResponse,
      IdentityUserLookupFindByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/users/lookup/${queryArg.id}` }),
    }),
    identityUserLookupFindByUserName: build.query<
      IdentityUserLookupFindByUserNameApiResponse,
      IdentityUserLookupFindByUserNameApiArg
    >({
      query: (queryArg) => ({ url: `/api/identity/users/lookup/by-username/${queryArg.userName}` }),
    }),
    identityUserLookupSearch: build.query<
      IdentityUserLookupSearchApiResponse,
      IdentityUserLookupSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/lookup/search`,
        params: {
          Sorting: queryArg.sorting,
          Filter: queryArg.filter,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
        },
      }),
    }),
    identityUserLookupGetCount: build.query<
      IdentityUserLookupGetCountApiResponse,
      IdentityUserLookupGetCountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/identity/users/lookup/count`,
        params: { Filter: queryArg.filter },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as appApi };
export type AbpApiDefinitionGetApiResponse =
  /** status 200 Success */ VoloAbpHttpModelingApplicationApiDescriptionModel;
export type AbpApiDefinitionGetApiArg = {
  includeTypes?: boolean;
};
export type AbpApplicationConfigurationGetApiResponse =
  /** status 200 Success */ VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDto;
export type AbpApplicationConfigurationGetApiArg = void;
export type AbpTenantFindTenantByNameApiResponse =
  /** status 200 Success */ VoloAbpAspNetCoreMvcMultiTenancyFindTenantResultDto;
export type AbpTenantFindTenantByNameApiArg = {
  name: string;
};
export type AbpTenantFindTenantByIdApiResponse =
  /** status 200 Success */ VoloAbpAspNetCoreMvcMultiTenancyFindTenantResultDto;
export type AbpTenantFindTenantByIdApiArg = {
  id: string;
};
export type AccountSettingsGetApiResponse =
  /** status 200 Success */ VoloAbpAccountAccountSettingsDto;
export type AccountSettingsGetApiArg = void;
export type AccountSettingsUpdateApiResponse = /** status 200 Success */ undefined;
export type AccountSettingsUpdateApiArg = {
  voloAbpAccountAccountSettingsDto: VoloAbpAccountAccountSettingsDto;
};
export type AccountSettingsGetTwoFactorApiResponse =
  /** status 200 Success */ VoloAbpAccountAccountTwoFactorSettingsDto;
export type AccountSettingsGetTwoFactorApiArg = void;
export type AccountSettingsUpdateTwoFactorApiResponse = /** status 200 Success */ undefined;
export type AccountSettingsUpdateTwoFactorApiArg = {
  voloAbpAccountAccountTwoFactorSettingsDto: VoloAbpAccountAccountTwoFactorSettingsDto;
};
export type AccountSettingsGetRecaptchaApiResponse =
  /** status 200 Success */ VoloAbpAccountAccountRecaptchaSettingsDto;
export type AccountSettingsGetRecaptchaApiArg = void;
export type AccountSettingsUpdateRecaptchaApiResponse = /** status 200 Success */ undefined;
export type AccountSettingsUpdateRecaptchaApiArg = {
  voloAbpAccountAccountRecaptchaSettingsDto: VoloAbpAccountAccountRecaptchaSettingsDto;
};
export type AccountSettingsGetExternalProviderApiResponse =
  /** status 200 Success */ VoloAbpAccountAccountExternalProviderSettingsDto;
export type AccountSettingsGetExternalProviderApiArg = void;
export type AccountSettingsUpdateExternalProviderApiResponse = /** status 200 Success */ undefined;
export type AccountSettingsUpdateExternalProviderApiArg = {
  body: VoloAbpAccountUpdateExternalProviderDto[];
};
export type ActiveCampaignIntegrationGetSettingsApiResponse =
  /** status 200 Success */ Activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto;
export type ActiveCampaignIntegrationGetSettingsApiArg = void;
export type ActiveCampaignIntegrationSetSettingsApiResponse = /** status 200 Success */ undefined;
export type ActiveCampaignIntegrationSetSettingsApiArg = {
  activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto: Activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto;
};
export type ApiIntegrationCompleteProgramExpressionOfInterestRequestApiResponse =
  /** status 200 Success */ undefined;
export type ApiIntegrationCompleteProgramExpressionOfInterestRequestApiArg = {
  'X-PublicApiKey': string;
  activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestProgramSelectionRequest: Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestProgramSelectionRequest;
};
export type ApiIntegrationGetPartnerInfoApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiPartnerInfoResponse;
export type ApiIntegrationGetPartnerInfoApiArg = {
  'X-PublicApiKey': string;
};
export type ApiIntegrationListActiveProgramsApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryResponse;
export type ApiIntegrationListActiveProgramsApiArg = {
  'X-PublicApiKey': string;
  activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestListProgramsRequest: Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestListProgramsRequest;
};
export type ApiIntegrationGetIntegrationSettingsApiResponse =
  /** status 200 Success */ Activ8IntegrationsApiApiIntegrationSettingsDto;
export type ApiIntegrationGetIntegrationSettingsApiArg = void;
export type ApiResourcesGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityServerApiResourceDtosApiResourceWithDetailsDto20VoloAbpIdentityServerApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type ApiResourcesGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type ApiResourcesCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerApiResourceDtosApiResourceWithDetailsDto;
export type ApiResourcesCreateApiArg = {
  voloAbpIdentityServerApiResourceDtosCreateApiResourceDto: VoloAbpIdentityServerApiResourceDtosCreateApiResourceDto;
};
export type ApiResourcesDeleteApiResponse = /** status 200 Success */ undefined;
export type ApiResourcesDeleteApiArg = {
  id?: string;
};
export type ApiResourcesGetAllListApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerApiResourceDtosApiResourceWithDetailsDto[];
export type ApiResourcesGetAllListApiArg = void;
export type ApiResourcesGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerApiResourceDtosApiResourceWithDetailsDto;
export type ApiResourcesGetApiArg = {
  id: string;
};
export type ApiResourcesUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerApiResourceDtosApiResourceWithDetailsDto;
export type ApiResourcesUpdateApiArg = {
  id: string;
  voloAbpIdentityServerApiResourceDtosUpdateApiResourceDto: VoloAbpIdentityServerApiResourceDtosUpdateApiResourceDto;
};
export type ApiScopesGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityServerApiScopeDtosApiScopeWithDetailsDto20VoloAbpIdentityServerApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type ApiScopesGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type ApiScopesCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerApiScopeDtosApiScopeWithDetailsDto;
export type ApiScopesCreateApiArg = {
  voloAbpIdentityServerApiScopeDtosCreateApiScopeDto: VoloAbpIdentityServerApiScopeDtosCreateApiScopeDto;
};
export type ApiScopesDeleteApiResponse = /** status 200 Success */ undefined;
export type ApiScopesDeleteApiArg = {
  id?: string;
};
export type ApiScopesGetAllListApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerApiScopeDtosApiScopeWithDetailsDto[];
export type ApiScopesGetAllListApiArg = void;
export type ApiScopesGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerApiScopeDtosApiScopeWithDetailsDto;
export type ApiScopesGetApiArg = {
  id: string;
};
export type ApiScopesUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerApiScopeDtosApiScopeWithDetailsDto;
export type ApiScopesUpdateApiArg = {
  id: string;
  voloAbpIdentityServerApiScopeDtosUpdateApiScopeDto: VoloAbpIdentityServerApiScopeDtosUpdateApiScopeDto;
};
export type AuditLogsGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpAuditLoggingAuditLogDto20VoloAbpAuditLoggingApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type AuditLogsGetListApiArg = {
  startTime?: string;
  endTime?: string;
  url?: string;
  userName?: string;
  applicationName?: string;
  clientIpAddress?: string;
  correlationId?: string;
  httpMethod?: string;
  httpStatusCode?: SystemNetHttpStatusCode;
  maxExecutionDuration?: number;
  minExecutionDuration?: number;
  hasException?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type AuditLogsGetApiResponse = /** status 200 Success */ VoloAbpAuditLoggingAuditLogDto;
export type AuditLogsGetApiArg = {
  id: string;
};
export type AuditLogsGetErrorRateApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingGetErrorRateOutput;
export type AuditLogsGetErrorRateApiArg = {
  startDate?: string;
  endDate?: string;
};
export type AuditLogsGetAverageExecutionDurationPerDayApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingGetAverageExecutionDurationPerDayOutput;
export type AuditLogsGetAverageExecutionDurationPerDayApiArg = {
  startDate?: string;
  endDate?: string;
};
export type AuditLogsGetEntityChangesApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpAuditLoggingEntityChangeDto20VoloAbpAuditLoggingApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type AuditLogsGetEntityChangesApiArg = {
  auditLogId?: string;
  entityChangeType?: VoloAbpAuditingEntityChangeType;
  entityId?: string;
  entityTypeFullName?: string;
  startDate?: string;
  endDate?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type AuditLogsGetEntityChangesWithUsernameApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingEntityChangeWithUsernameDto[];
export type AuditLogsGetEntityChangesWithUsernameApiArg = {
  entityId?: string;
  entityTypeFullName?: string;
};
export type AuditLogsGetEntityChangeWithUsernameApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingEntityChangeWithUsernameDto;
export type AuditLogsGetEntityChangeWithUsernameApiArg = {
  entityChangeId: string;
};
export type AuditLogsGetEntityChangeApiResponse =
  /** status 200 Success */ VoloAbpAuditLoggingEntityChangeDto;
export type AuditLogsGetEntityChangeApiArg = {
  entityChangeId: string;
};
export type IdentityClaimTypeGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityClaimTypeDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type IdentityClaimTypeGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type IdentityClaimTypeCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityClaimTypeDto;
export type IdentityClaimTypeCreateApiArg = {
  voloAbpIdentityCreateClaimTypeDto: VoloAbpIdentityCreateClaimTypeDto;
};
export type IdentityClaimTypeGetApiResponse = /** status 200 Success */ VoloAbpIdentityClaimTypeDto;
export type IdentityClaimTypeGetApiArg = {
  id: string;
};
export type IdentityClaimTypeUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityClaimTypeDto;
export type IdentityClaimTypeUpdateApiArg = {
  id: string;
  voloAbpIdentityUpdateClaimTypeDto: VoloAbpIdentityUpdateClaimTypeDto;
};
export type IdentityClaimTypeDeleteApiResponse = /** status 200 Success */ undefined;
export type IdentityClaimTypeDeleteApiArg = {
  id: string;
};
export type IdentityServerClaimTypesGetListApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerClaimTypeDtosIdentityClaimTypeDto[];
export type IdentityServerClaimTypesGetListApiArg = void;
export type ClientsGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityServerClientDtosClientWithDetailsDto20VoloAbpIdentityServerApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type ClientsGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type ClientsCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerClientDtosClientWithDetailsDto;
export type ClientsCreateApiArg = {
  voloAbpIdentityServerClientDtosCreateClientDto: VoloAbpIdentityServerClientDtosCreateClientDto;
};
export type ClientsDeleteApiResponse = /** status 200 Success */ undefined;
export type ClientsDeleteApiArg = {
  id?: string;
};
export type ClientsGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerClientDtosClientWithDetailsDto;
export type ClientsGetApiArg = {
  id: string;
};
export type ClientsUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerClientDtosClientWithDetailsDto;
export type ClientsUpdateApiArg = {
  id: string;
  voloAbpIdentityServerClientDtosUpdateClientDto: VoloAbpIdentityServerClientDtosUpdateClientDto;
};
export type DataExportTriggerUserExportEmailApiResponse = /** status 200 Success */ undefined;
export type DataExportTriggerUserExportEmailApiArg = void;
export type DataExportTriggerFacilitatorExportEmailApiResponse =
  /** status 200 Success */ undefined;
export type DataExportTriggerFacilitatorExportEmailApiArg = void;
export type DataExportTriggerParticipantExportEmailApiResponse =
  /** status 200 Success */ undefined;
export type DataExportTriggerParticipantExportEmailApiArg = void;
export type DataExportTriggerCourseTemplateExportEmailApiResponse =
  /** status 200 Success */ undefined;
export type DataExportTriggerCourseTemplateExportEmailApiArg = void;
export type DataExportTriggerCourseProgramExportEmailApiResponse =
  /** status 200 Success */ undefined;
export type DataExportTriggerCourseProgramExportEmailApiArg = void;
export type DataExportTriggerCourseProgramParticipantExportEmailApiResponse =
  /** status 200 Success */ undefined;
export type DataExportTriggerCourseProgramParticipantExportEmailApiArg = void;
export type DataImportTriggerParticipantImportApiResponse = /** status 200 Success */ undefined;
export type DataImportTriggerParticipantImportApiArg = {
  activ8DataDataImportTriggerParticipantDataImportRequest: Activ8DataDataImportTriggerParticipantDataImportRequest;
};
export type DataImportPrepareImportFileWriteApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesPrepareResourceOperationResponse;
export type DataImportPrepareImportFileWriteApiArg = {
  contentType?: string;
  contentFileName?: string;
  remoteIp?: string;
};
export type EditionGetApiResponse = /** status 200 Success */ VoloSaasHostDtosEditionDto;
export type EditionGetApiArg = {
  id: string;
};
export type EditionUpdateApiResponse = /** status 200 Success */ VoloSaasHostDtosEditionDto;
export type EditionUpdateApiArg = {
  id: string;
  voloSaasHostDtosEditionUpdateDto: VoloSaasHostDtosEditionUpdateDto;
};
export type EditionDeleteApiResponse = /** status 200 Success */ undefined;
export type EditionDeleteApiArg = {
  id: string;
};
export type EditionGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloSaasHostDtosEditionDto20VoloSaasHostApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type EditionGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type EditionCreateApiResponse = /** status 200 Success */ VoloSaasHostDtosEditionDto;
export type EditionCreateApiArg = {
  voloSaasHostDtosEditionCreateDto: VoloSaasHostDtosEditionCreateDto;
};
export type EditionGetUsageStatisticsApiResponse =
  /** status 200 Success */ VoloSaasHostGetEditionUsageStatisticsResultDto;
export type EditionGetUsageStatisticsApiArg = void;
export type EditionGetPlanLookupApiResponse = /** status 200 Success */ VoloPaymentPlansPlanDto[];
export type EditionGetPlanLookupApiArg = void;
export type SubscriptionCreateSubscriptionApiResponse =
  /** status 200 Success */ VoloPaymentRequestsPaymentRequestWithDetailsDto;
export type SubscriptionCreateSubscriptionApiArg = {
  editionId?: string;
  tenantId?: string;
};
export type EmailSettingsGetApiResponse =
  /** status 200 Success */ VoloAbpSettingManagementEmailSettingsDto;
export type EmailSettingsGetApiArg = void;
export type EmailSettingsUpdateApiResponse = /** status 200 Success */ undefined;
export type EmailSettingsUpdateApiArg = {
  voloAbpSettingManagementUpdateEmailSettingsDto: VoloAbpSettingManagementUpdateEmailSettingsDto;
};
export type FacilitatorCreateApiResponse =
  /** status 200 Success */ Activ8FacilitatorsFacilitatorDto;
export type FacilitatorCreateApiArg = {
  activ8FacilitatorsFacilitatorCreateDto: Activ8FacilitatorsFacilitatorCreateDto;
};
export type FacilitatorGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8FacilitatorsFacilitatorDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type FacilitatorGetListApiArg = {
  assignedPersonAttributeIds?: string[];
  assignedPersonAccreditationIds?: string[];
  filter?: string;
  roleId?: string;
  organizationUnitId?: string;
  userName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  isLockedOut?: boolean;
  notActive?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type FacilitatorUpdateApiResponse =
  /** status 200 Success */ Activ8FacilitatorsFacilitatorDto;
export type FacilitatorUpdateApiArg = {
  id: string;
  activ8FacilitatorsFacilitatorUpdateDto: Activ8FacilitatorsFacilitatorUpdateDto;
};
export type FacilitatorGetApiResponse = /** status 200 Success */ Activ8FacilitatorsFacilitatorDto;
export type FacilitatorGetApiArg = {
  id: string;
};
export type FacilitatorDeleteApiResponse = /** status 200 Success */ undefined;
export type FacilitatorDeleteApiArg = {
  id: string;
};
export type FeaturesGetApiResponse =
  /** status 200 Success */ VoloAbpFeatureManagementGetFeatureListResultDto;
export type FeaturesGetApiArg = {
  providerName?: string;
  providerKey?: string;
};
export type FeaturesUpdateApiResponse = /** status 200 Success */ undefined;
export type FeaturesUpdateApiArg = {
  providerName?: string;
  providerKey?: string;
  voloAbpFeatureManagementUpdateFeaturesDto: VoloAbpFeatureManagementUpdateFeaturesDto;
};
export type IdentityResourcesGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityServerIdentityResourceDtosIdentityResourceWithDetailsDto20VoloAbpIdentityServerApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type IdentityResourcesGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type IdentityResourcesCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerIdentityResourceDtosIdentityResourceWithDetailsDto;
export type IdentityResourcesCreateApiArg = {
  voloAbpIdentityServerIdentityResourceDtosCreateIdentityResourceDto: VoloAbpIdentityServerIdentityResourceDtosCreateIdentityResourceDto;
};
export type IdentityResourcesDeleteApiResponse = /** status 200 Success */ undefined;
export type IdentityResourcesDeleteApiArg = {
  id?: string;
};
export type IdentityResourcesGetAllListApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerIdentityResourceDtosIdentityResourceWithDetailsDto[];
export type IdentityResourcesGetAllListApiArg = void;
export type IdentityResourcesGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerIdentityResourceDtosIdentityResourceWithDetailsDto;
export type IdentityResourcesGetApiArg = {
  id: string;
};
export type IdentityResourcesUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityServerIdentityResourceDtosIdentityResourceWithDetailsDto;
export type IdentityResourcesUpdateApiArg = {
  id: string;
  voloAbpIdentityServerIdentityResourceDtosUpdateIdentityResourceDto: VoloAbpIdentityServerIdentityResourceDtosUpdateIdentityResourceDto;
};
export type IdentityResourcesCreateStandardResourcesApiResponse =
  /** status 200 Success */ undefined;
export type IdentityResourcesCreateStandardResourcesApiArg = void;
export type LanguageGetAllListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpLanguageManagementDtoLanguageDto20VoloAbpLanguageManagementApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type LanguageGetAllListApiArg = void;
export type LanguageGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpLanguageManagementDtoLanguageDto20VoloAbpLanguageManagementApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type LanguageGetListApiArg = {
  filter?: string;
  resourceName?: string;
  baseCultureName?: string;
  targetCultureName?: string;
  getOnlyEmptyValues?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LanguageCreateApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoLanguageDto;
export type LanguageCreateApiArg = {
  voloAbpLanguageManagementDtoCreateLanguageDto: VoloAbpLanguageManagementDtoCreateLanguageDto;
};
export type LanguageGetApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoLanguageDto;
export type LanguageGetApiArg = {
  id: string;
};
export type LanguageUpdateApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoLanguageDto;
export type LanguageUpdateApiArg = {
  id: string;
  voloAbpLanguageManagementDtoUpdateLanguageDto: VoloAbpLanguageManagementDtoUpdateLanguageDto;
};
export type LanguageDeleteApiResponse = /** status 200 Success */ undefined;
export type LanguageDeleteApiArg = {
  id: string;
};
export type LanguageSetAsDefaultApiResponse = /** status 200 Success */ undefined;
export type LanguageSetAsDefaultApiArg = {
  id: string;
};
export type LanguageGetResourcesApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoLanguageResourceDto[];
export type LanguageGetResourcesApiArg = void;
export type LanguageGetCulturelistApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoCultureInfoDto[];
export type LanguageGetCulturelistApiArg = void;
export type LanguageGetFlagListApiResponse = /** status 200 Success */ string[];
export type LanguageGetFlagListApiArg = void;
export type LanguageTextGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpLanguageManagementDtoLanguageTextDto20VoloAbpLanguageManagementApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type LanguageTextGetListApiArg = {
  filter?: string;
  resourceName?: string;
  baseCultureName?: string;
  targetCultureName?: string;
  getOnlyEmptyValues?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LanguageTextGetApiResponse =
  /** status 200 Success */ VoloAbpLanguageManagementDtoLanguageTextDto;
export type LanguageTextGetApiArg = {
  resourceName: string;
  cultureName: string;
  name: string;
  baseCultureName?: string;
};
export type LanguageTextUpdateApiResponse = /** status 200 Success */ undefined;
export type LanguageTextUpdateApiArg = {
  resourceName: string;
  cultureName: string;
  name: string;
  value?: string;
};
export type LanguageTextRestoreToDefaultApiResponse = /** status 200 Success */ undefined;
export type LanguageTextRestoreToDefaultApiArg = {
  resourceName: string;
  cultureName: string;
  name: string;
};
export type LearningCourseProgramUpdateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsCoursesLearningCourseProgramDto;
export type LearningCourseProgramUpdateApiArg = {
  id: string;
  activ8CoursesProgramsCoursesLearningCourseProgramUpdateDto: Activ8CoursesProgramsCoursesLearningCourseProgramUpdateDto;
};
export type LearningCourseProgramGetApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsCoursesLearningCourseProgramDto;
export type LearningCourseProgramGetApiArg = {
  id: string;
};
export type LearningCourseProgramDeleteApiResponse = /** status 200 Success */ undefined;
export type LearningCourseProgramDeleteApiArg = {
  id: string;
};
export type LearningCourseProgramCreateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsCoursesLearningCourseProgramDto;
export type LearningCourseProgramCreateApiArg = {
  activ8CoursesProgramsCoursesLearningCourseProgramCreateDto: Activ8CoursesProgramsCoursesLearningCourseProgramCreateDto;
};
export type LearningCourseProgramGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type LearningCourseProgramGetListApiArg = {
  filterText?: string;
  assignedToFacilitatorIds?: string[];
  assignedToParticipantIds?: string[];
  statuses?: Activ8CoursesProgramsLearningCourseProgramStatus[];
  includeStatistics?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LearningCourseProgramSetProgramStatusApiResponse = /** status 200 Success */ undefined;
export type LearningCourseProgramSetProgramStatusApiArg = {
  activ8CoursesProgramsCoursesProgramStatusSetRequestDto: Activ8CoursesProgramsCoursesProgramStatusSetRequestDto;
};
export type LearningCourseProgramCloneCourseProgramApiResponse = /** status 200 Success */ string;
export type LearningCourseProgramCloneCourseProgramApiArg = {
  courseProgramId: string;
};
export type LearningCourseProgramAssignParticipantToProgramApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto;
export type LearningCourseProgramAssignParticipantToProgramApiArg = {
  courseProgramId?: string;
  participantIdentityUserId?: string;
};
export type LearningCourseProgramUnassignParticipantToProgramApiResponse =
  /** status 200 Success */ undefined;
export type LearningCourseProgramUnassignParticipantToProgramApiArg = {
  courseProgramId?: string;
  participantIdentityUserId?: string;
};
export type LearningCourseProgramGenerateUrlForDownloadCourseContentApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesPrepareResourceOperationResponse;
export type LearningCourseProgramGenerateUrlForDownloadCourseContentApiArg = {
  courseProgramId: string;
};
export type LearningCourseProgramFacilitatorGetApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto;
export type LearningCourseProgramFacilitatorGetApiArg = {
  id: string;
};
export type LearningCourseProgramFacilitatorUpdateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto;
export type LearningCourseProgramFacilitatorUpdateApiArg = {
  id: string;
  activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentUpdateDto: Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentUpdateDto;
};
export type LearningCourseProgramFacilitatorDeleteApiResponse = /** status 200 Success */ undefined;
export type LearningCourseProgramFacilitatorDeleteApiArg = {
  id: string;
};
export type LearningCourseProgramFacilitatorGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type LearningCourseProgramFacilitatorGetListApiArg = {
  filterText?: string;
  facilitatorIdentityUserIds?: string[];
  learningCourseProgramIds?: string[];
  includeProgramDetails?: boolean;
  includeFacilitatorDetails?: boolean;
  programStatuses?: Activ8CoursesProgramsLearningCourseProgramStatus[];
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LearningCourseProgramFacilitatorCreateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto;
export type LearningCourseProgramFacilitatorCreateApiArg = {
  activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentCreateDto: Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentCreateDto;
};
export type LearningCourseProgramParticipantGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type LearningCourseProgramParticipantGetListApiArg = {
  filterText?: string;
  learningCourseProgramIds?: string[];
  participantIdentityUserIds?: string[];
  includeProgramDetails?: boolean;
  includeParticipantDetails?: boolean;
  programStatuses?: Activ8CoursesProgramsLearningCourseProgramStatus[];
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LearningCourseProgramParticipantCreateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto;
export type LearningCourseProgramParticipantCreateApiArg = {
  activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentCreateDto: Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentCreateDto;
};
export type LearningCourseProgramParticipantGetApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto;
export type LearningCourseProgramParticipantGetApiArg = {
  id: string;
};
export type LearningCourseProgramParticipantUpdateApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto;
export type LearningCourseProgramParticipantUpdateApiArg = {
  id: string;
  activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentUpdateDto: Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentUpdateDto;
};
export type LearningCourseProgramParticipantDeleteApiResponse = /** status 200 Success */ undefined;
export type LearningCourseProgramParticipantDeleteApiArg = {
  id: string;
};
export type LearningCourseProgramParticipantGetByProgramAndParticipantApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto;
export type LearningCourseProgramParticipantGetByProgramAndParticipantApiArg = {
  programId?: string;
  participantId?: string;
};
export type LearningCourseProgramSessionPrepareSessionContentApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsSessionsLearningCourseProgramSessionPreparedContentDto;
export type LearningCourseProgramSessionPrepareSessionContentApiArg = {
  courseProgramId?: string;
  courseProgramChapterId?: string;
  courseProgramChapterSessionId?: string;
};
export type LearningCourseProgramSessionProgressThroughSessionContentApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsSessionsProgressThroughSessionContentResponse;
export type LearningCourseProgramSessionProgressThroughSessionContentApiArg = {
  activ8CoursesProgramsSessionsProgressThroughSessionContentRequest: Activ8CoursesProgramsSessionsProgressThroughSessionContentRequest;
};
export type LearningCourseProgramSessionProgressThroughSessionContentBatchApiResponse =
  /** status 200 Success */ Activ8CoursesProgramsSessionsProgressThroughSessionContentBatchResponse;
export type LearningCourseProgramSessionProgressThroughSessionContentBatchApiArg = {
  activ8CoursesProgramsSessionsProgressThroughSessionContentBatchRequest: Activ8CoursesProgramsSessionsProgressThroughSessionContentBatchRequest;
};
export type LearningCourseTemplateSetTemplateStatusApiResponse =
  /** status 200 Success */ undefined;
export type LearningCourseTemplateSetTemplateStatusApiArg = {
  activ8CoursesTemplatesTemplateStatusSetRequestDto: Activ8CoursesTemplatesTemplateStatusSetRequestDto;
};
export type LearningCourseTemplateGetRoleNamesApplicableToCourseAssignmentsApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601SystemString20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E;
export type LearningCourseTemplateGetRoleNamesApplicableToCourseAssignmentsApiArg = void;
export type LearningCourseTemplateDeleteApiResponse = /** status 200 Success */ undefined;
export type LearningCourseTemplateDeleteApiArg = {
  id: string;
};
export type LearningCourseTemplateGetApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesLearningCourseTemplateDto;
export type LearningCourseTemplateGetApiArg = {
  id: string;
};
export type LearningCourseTemplateUpdateApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesLearningCourseTemplateDto;
export type LearningCourseTemplateUpdateApiArg = {
  id: string;
  activ8CoursesTemplatesLearningCourseTemplateUpdateDto: Activ8CoursesTemplatesLearningCourseTemplateUpdateDto;
};
export type LearningCourseTemplateCloneCourseTemplateApiResponse = /** status 200 Success */ string;
export type LearningCourseTemplateCloneCourseTemplateApiArg = {
  courseTemplateId: string;
};
export type LearningCourseTemplateGetTemplatePartnerLicensingApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601Activ8CoursesTemplatesTenantLicenseToLearningCourseTemplateAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type LearningCourseTemplateGetTemplatePartnerLicensingApiArg = {
  courseTemplateId: string;
};
export type LearningCourseTemplatePrepareResourceWriteApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesPrepareResourceOperationResponse;
export type LearningCourseTemplatePrepareResourceWriteApiArg = {
  courseTemplateId?: string;
  resourceId?: string;
  contentType?: string;
  contentFileName?: string;
};
export type LearningCourseTemplatePrepareResourceReadApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesPrepareResourceOperationResponse;
export type LearningCourseTemplatePrepareResourceReadApiArg = {
  courseTemplateId?: string;
  resourceId?: string;
  isDownload?: boolean;
  contentType?: string;
  contentFileName?: string;
};
export type LearningCourseTemplateCreateApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesLearningCourseTemplateDto;
export type LearningCourseTemplateCreateApiArg = {
  activ8CoursesTemplatesLearningCourseTemplateCreateDto: Activ8CoursesTemplatesLearningCourseTemplateCreateDto;
};
export type LearningCourseTemplateGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8CoursesTemplatesLearningCourseTemplateSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type LearningCourseTemplateGetListApiArg = {
  filterText?: string;
  licensedToTenantIds?: string[];
  enrollmentAllowedForIdentityRoleNames?: string[];
  statuses?: Activ8CoursesTemplatesLearningCourseTemplateStatus[];
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type LearningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplateApiResponse =
  /** status 200 Success */ number;
export type LearningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplateApiArg = {
  courseTemplateId: string;
};
export type LearningCourseTemplateGetTemplateContentApiResponse =
  /** status 200 Success */ Activ8CoursesContentLearningCourseContent;
export type LearningCourseTemplateGetTemplateContentApiArg = {
  courseTemplateId: string;
};
export type LearningCourseTemplateGenerateUrlForDownloadCourseContentApiResponse =
  /** status 200 Success */ Activ8CoursesTemplatesPrepareResourceOperationResponse;
export type LearningCourseTemplateGenerateUrlForDownloadCourseContentApiArg = {
  courseTemplateId: string;
};
export type LeptonThemeSettingsGetApiResponse =
  /** status 200 Success */ VoloAbpLeptonThemeManagementLeptonThemeSettingsDto;
export type LeptonThemeSettingsGetApiArg = void;
export type LeptonThemeSettingsUpdateApiResponse = /** status 200 Success */ undefined;
export type LeptonThemeSettingsUpdateApiArg = {
  voloAbpLeptonThemeManagementUpdateLeptonThemeSettingsDto: VoloAbpLeptonThemeManagementUpdateLeptonThemeSettingsDto;
};
export type OrganizationUnitAddRolesApiResponse = /** status 200 Success */ undefined;
export type OrganizationUnitAddRolesApiArg = {
  id: string;
  voloAbpIdentityOrganizationUnitRoleInput: VoloAbpIdentityOrganizationUnitRoleInput;
};
export type OrganizationUnitGetRolesApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type OrganizationUnitGetRolesApiArg = {
  id: string;
  skipCount?: number;
  maxResultCount?: number;
  sorting?: string;
};
export type OrganizationUnitAddMembersApiResponse = /** status 200 Success */ undefined;
export type OrganizationUnitAddMembersApiArg = {
  id: string;
  voloAbpIdentityOrganizationUnitUserInput: VoloAbpIdentityOrganizationUnitUserInput;
};
export type OrganizationUnitGetMembersApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityUserDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type OrganizationUnitGetMembersApiArg = {
  id: string;
  filter?: string;
  roleId?: string;
  organizationUnitId?: string;
  userName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  isLockedOut?: boolean;
  notActive?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type OrganizationUnitCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityOrganizationUnitWithDetailsDto;
export type OrganizationUnitCreateApiArg = {
  voloAbpIdentityOrganizationUnitCreateDto: VoloAbpIdentityOrganizationUnitCreateDto;
};
export type OrganizationUnitDeleteApiResponse = /** status 200 Success */ undefined;
export type OrganizationUnitDeleteApiArg = {
  id?: string;
};
export type OrganizationUnitGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type OrganizationUnitGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type OrganizationUnitGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityOrganizationUnitWithDetailsDto;
export type OrganizationUnitGetApiArg = {
  id: string;
};
export type OrganizationUnitUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityOrganizationUnitWithDetailsDto;
export type OrganizationUnitUpdateApiArg = {
  id: string;
  voloAbpIdentityOrganizationUnitUpdateDto: VoloAbpIdentityOrganizationUnitUpdateDto;
};
export type OrganizationUnitGetListAllApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type OrganizationUnitGetListAllApiArg = void;
export type OrganizationUnitMoveApiResponse = /** status 200 Success */ undefined;
export type OrganizationUnitMoveApiArg = {
  id: string;
  voloAbpIdentityOrganizationUnitMoveInput: VoloAbpIdentityOrganizationUnitMoveInput;
};
export type OrganizationUnitGetAvailableUsersApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityUserDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type OrganizationUnitGetAvailableUsersApiArg = {
  filter?: string;
  id?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type OrganizationUnitGetAvailableRolesApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type OrganizationUnitGetAvailableRolesApiArg = {
  filter?: string;
  id?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type OrganizationUnitRemoveMemberApiResponse = /** status 200 Success */ undefined;
export type OrganizationUnitRemoveMemberApiArg = {
  id: string;
  memberId: string;
};
export type OrganizationUnitRemoveRoleApiResponse = /** status 200 Success */ undefined;
export type OrganizationUnitRemoveRoleApiArg = {
  id: string;
  roleId: string;
};
export type ParticipantCreateApiResponse =
  /** status 200 Success */ Activ8ParticipantsParticipantDto;
export type ParticipantCreateApiArg = {
  activ8ParticipantsParticipantCreateDto: Activ8ParticipantsParticipantCreateDto;
};
export type ParticipantGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8ParticipantsParticipantDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type ParticipantGetListApiArg = {
  assignedPersonAttributeIds?: string[];
  assignedPersonAccreditationIds?: string[];
  filter?: string;
  roleId?: string;
  organizationUnitId?: string;
  userName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  isLockedOut?: boolean;
  notActive?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type ParticipantUpdateApiResponse =
  /** status 200 Success */ Activ8ParticipantsParticipantDto;
export type ParticipantUpdateApiArg = {
  id: string;
  activ8ParticipantsParticipantUpdateDto: Activ8ParticipantsParticipantUpdateDto;
};
export type ParticipantGetApiResponse = /** status 200 Success */ Activ8ParticipantsParticipantDto;
export type ParticipantGetApiArg = {
  id: string;
};
export type ParticipantDeleteApiResponse = /** status 200 Success */ undefined;
export type ParticipantDeleteApiArg = {
  id: string;
};
export type ParticipantRemoveIdentityUserLinkApiResponse = /** status 200 Success */ undefined;
export type ParticipantRemoveIdentityUserLinkApiArg = {
  parentIdentityUserId?: string;
  childIdentityUserId?: string;
};
export type ParticipantMaintainIdentityUserLinksApiResponse = /** status 200 Success */ undefined;
export type ParticipantMaintainIdentityUserLinksApiArg = {
  parentIdentityUserId?: string;
  childIdentityUserId?: string;
  unlinkAllChildParentLinks?: boolean;
};
export type PartnerAppNotificationGetAllApiResponse =
  /** status 200 Success */ Activ8AppNotificationsAppNotification[];
export type PartnerAppNotificationGetAllApiArg = void;
export type PartnerDashboardGetApiResponse =
  /** status 200 Success */ Activ8PartnersDashboardPartnerDashboardResponse;
export type PartnerDashboardGetApiArg = void;
export type PartnerManagementEnsurePartnerSetupApiResponse = /** status 200 Success */ undefined;
export type PartnerManagementEnsurePartnerSetupApiArg = {
  tenantId: string;
};
export type PartnerProfileGetApiResponse =
  /** status 200 Success */ Activ8PartnersPartnerProfileDetail;
export type PartnerProfileGetApiArg = void;
export type PartnerProfileSetApiResponse = /** status 200 Success */ undefined;
export type PartnerProfileSetApiArg = {
  activ8PartnersProfilePartnerProfileDetailSetRequest: Activ8PartnersProfilePartnerProfileDetailSetRequest;
};
export type PermissionsGetApiResponse =
  /** status 200 Success */ VoloAbpPermissionManagementGetPermissionListResultDto;
export type PermissionsGetApiArg = {
  providerName?: string;
  providerKey?: string;
};
export type PermissionsUpdateApiResponse = /** status 200 Success */ undefined;
export type PermissionsUpdateApiArg = {
  providerName?: string;
  providerKey?: string;
  voloAbpPermissionManagementUpdatePermissionsDto: VoloAbpPermissionManagementUpdatePermissionsDto;
};
export type PersonAccreditationGetAssignmentsForUserApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601Activ8PersonAccreditationsPersonAccreditationToUserAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type PersonAccreditationGetAssignmentsForUserApiArg = {
  userId: string;
};
export type PersonAccreditationSetAssignmentsForUserApiResponse =
  /** status 200 Success */ undefined;
export type PersonAccreditationSetAssignmentsForUserApiArg = {
  userId: string;
  body: string[];
};
export type PersonAccreditationGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8PersonAccreditationsPersonAccreditationDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type PersonAccreditationGetListApiArg = {
  filterText?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type PersonAccreditationCreateApiResponse =
  /** status 200 Success */ Activ8PersonAccreditationsPersonAccreditationDto;
export type PersonAccreditationCreateApiArg = {
  activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto: Activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto;
};
export type PersonAccreditationUpdateApiResponse =
  /** status 200 Success */ Activ8PersonAccreditationsPersonAccreditationDto;
export type PersonAccreditationUpdateApiArg = {
  id: string;
  activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto: Activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto;
};
export type PersonAccreditationDeleteApiResponse = /** status 200 Success */ undefined;
export type PersonAccreditationDeleteApiArg = {
  id: string;
};
export type PersonAccreditationGetApiResponse =
  /** status 200 Success */ Activ8PersonAccreditationsPersonAccreditationDto;
export type PersonAccreditationGetApiArg = {
  id: string;
};
export type PersonAttributeGetAssignmentsForUserApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601Activ8PersonAttributesPersonAttributeToUserAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type PersonAttributeGetAssignmentsForUserApiArg = {
  userId: string;
};
export type PersonAttributeSetAssignmentsForUserApiResponse = /** status 200 Success */ undefined;
export type PersonAttributeSetAssignmentsForUserApiArg = {
  userId: string;
  body: string[];
};
export type PersonAttributeGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601Activ8PersonAttributesPersonAttributeDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
export type PersonAttributeGetListApiArg = {
  filterText?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type PersonAttributeCreateApiResponse =
  /** status 200 Success */ Activ8PersonAttributesPersonAttributeDto;
export type PersonAttributeCreateApiArg = {
  activ8PersonAttributesPersonAttributeCreateOrUpdateDto: Activ8PersonAttributesPersonAttributeCreateOrUpdateDto;
};
export type PersonAttributeUpdateApiResponse =
  /** status 200 Success */ Activ8PersonAttributesPersonAttributeDto;
export type PersonAttributeUpdateApiArg = {
  id: string;
  activ8PersonAttributesPersonAttributeCreateOrUpdateDto: Activ8PersonAttributesPersonAttributeCreateOrUpdateDto;
};
export type PersonAttributeDeleteApiResponse = /** status 200 Success */ undefined;
export type PersonAttributeDeleteApiArg = {
  id: string;
};
export type PersonAttributeGetApiResponse =
  /** status 200 Success */ Activ8PersonAttributesPersonAttributeDto;
export type PersonAttributeGetApiArg = {
  id: string;
};
export type IdentityRoleGetApiResponse = /** status 200 Success */ VoloAbpIdentityIdentityRoleDto;
export type IdentityRoleGetApiArg = {
  id: string;
};
export type IdentityRoleUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityRoleDto;
export type IdentityRoleUpdateApiArg = {
  id: string;
  voloAbpIdentityIdentityRoleUpdateDto: VoloAbpIdentityIdentityRoleUpdateDto;
};
export type IdentityRoleDeleteApiResponse = /** status 200 Success */ undefined;
export type IdentityRoleDeleteApiArg = {
  id: string;
};
export type IdentityRoleCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityRoleDto;
export type IdentityRoleCreateApiArg = {
  voloAbpIdentityIdentityRoleCreateDto: VoloAbpIdentityIdentityRoleCreateDto;
};
export type IdentityRoleGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type IdentityRoleGetListApiArg = {
  filter?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type IdentityRoleGetAllListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type IdentityRoleGetAllListApiArg = void;
export type IdentityRoleUpdateClaimsApiResponse = /** status 200 Success */ undefined;
export type IdentityRoleUpdateClaimsApiArg = {
  id: string;
  body: VoloAbpIdentityIdentityRoleClaimDto[];
};
export type IdentityRoleGetClaimsApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityRoleClaimDto[];
export type IdentityRoleGetClaimsApiArg = {
  id: string;
};
export type IdentityRoleGetAllClaimTypesApiResponse =
  /** status 200 Success */ VoloAbpIdentityClaimTypeDto[];
export type IdentityRoleGetAllClaimTypesApiArg = void;
export type IdentitySecurityLogGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySecurityLogDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type IdentitySecurityLogGetListApiArg = {
  startTime?: string;
  endTime?: string;
  applicationName?: string;
  identity?: string;
  action?: string;
  userName?: string;
  clientId?: string;
  correlationId?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type IdentitySecurityLogGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentitySecurityLogDto;
export type IdentitySecurityLogGetApiArg = {
  id: string;
};
export type IdentitySecurityLogGetMyListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySecurityLogDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type IdentitySecurityLogGetMyListApiArg = {
  startTime?: string;
  endTime?: string;
  applicationName?: string;
  identity?: string;
  action?: string;
  userName?: string;
  clientId?: string;
  correlationId?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type IdentitySecurityLogGetMyApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentitySecurityLogDto;
export type IdentitySecurityLogGetMyApiArg = {
  id: string;
};
export type IdentitySettingsGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentitySettingsDto;
export type IdentitySettingsGetApiArg = void;
export type IdentitySettingsUpdateApiResponse = /** status 200 Success */ undefined;
export type IdentitySettingsUpdateApiArg = {
  voloAbpIdentityIdentitySettingsDto: VoloAbpIdentityIdentitySettingsDto;
};
export type IdentitySettingsGetLdapApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityLdapSettingsDto;
export type IdentitySettingsGetLdapApiArg = void;
export type IdentitySettingsUpdateLdapApiResponse = /** status 200 Success */ undefined;
export type IdentitySettingsUpdateLdapApiArg = {
  voloAbpIdentityIdentityLdapSettingsDto: VoloAbpIdentityIdentityLdapSettingsDto;
};
export type IdentitySettingsGetOAuthApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityOAuthSettingsDto;
export type IdentitySettingsGetOAuthApiArg = void;
export type IdentitySettingsUpdateOAuthApiResponse = /** status 200 Success */ undefined;
export type IdentitySettingsUpdateOAuthApiArg = {
  voloAbpIdentityIdentityOAuthSettingsDto: VoloAbpIdentityIdentityOAuthSettingsDto;
};
export type TenantGetApiResponse = /** status 200 Success */ VoloSaasHostDtosSaasTenantDto;
export type TenantGetApiArg = {
  id: string;
};
export type TenantUpdateApiResponse = /** status 200 Success */ VoloSaasHostDtosSaasTenantDto;
export type TenantUpdateApiArg = {
  id: string;
  voloSaasHostDtosSaasTenantUpdateDto: VoloSaasHostDtosSaasTenantUpdateDto;
};
export type TenantDeleteApiResponse = /** status 200 Success */ undefined;
export type TenantDeleteApiArg = {
  id: string;
};
export type TenantGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloSaasHostDtosSaasTenantDto20VoloSaasHostApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type TenantGetListApiArg = {
  filter?: string;
  getEditionNames?: boolean;
  editionId?: string;
  expirationDateMin?: string;
  expirationDateMax?: string;
  activationState?: VoloSaasTenantActivationState;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type TenantCreateApiResponse = /** status 200 Success */ VoloSaasHostDtosSaasTenantDto;
export type TenantCreateApiArg = {
  voloSaasHostDtosSaasTenantCreateDto: VoloSaasHostDtosSaasTenantCreateDto;
};
export type TenantGetDatabasesApiResponse =
  /** status 200 Success */ VoloSaasHostDtosSaasTenantDatabasesDto;
export type TenantGetDatabasesApiArg = void;
export type TenantGetConnectionStringsApiResponse =
  /** status 200 Success */ VoloSaasHostDtosSaasTenantConnectionStringsDto;
export type TenantGetConnectionStringsApiArg = {
  id: string;
};
export type TenantUpdateConnectionStringsApiResponse = /** status 200 Success */ undefined;
export type TenantUpdateConnectionStringsApiArg = {
  id: string;
  voloSaasHostDtosSaasTenantConnectionStringsDto: VoloSaasHostDtosSaasTenantConnectionStringsDto;
};
export type TenantApplyDatabaseMigrationsApiResponse = /** status 200 Success */ undefined;
export type TenantApplyDatabaseMigrationsApiArg = {
  id: string;
};
export type TenantGetEditionLookupApiResponse =
  /** status 200 Success */ VoloSaasHostDtosEditionLookupDto[];
export type TenantGetEditionLookupApiArg = void;
export type TemplateContentGetApiResponse =
  /** status 200 Success */ VoloAbpTextTemplateManagementTextTemplatesTextTemplateContentDto;
export type TemplateContentGetApiArg = {
  templateName: string;
  cultureName?: string;
};
export type TemplateContentUpdateApiResponse =
  /** status 200 Success */ VoloAbpTextTemplateManagementTextTemplatesTextTemplateContentDto;
export type TemplateContentUpdateApiArg = {
  voloAbpTextTemplateManagementTextTemplatesUpdateTemplateContentInput: VoloAbpTextTemplateManagementTextTemplatesUpdateTemplateContentInput;
};
export type TemplateContentRestoreToDefaultApiResponse = /** status 200 Success */ undefined;
export type TemplateContentRestoreToDefaultApiArg = {
  voloAbpTextTemplateManagementTextTemplatesRestoreTemplateContentInput: VoloAbpTextTemplateManagementTextTemplatesRestoreTemplateContentInput;
};
export type TemplateDefinitionGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpTextTemplateManagementTextTemplatesTemplateDefinitionDto20VoloAbpTextTemplateManagementApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type TemplateDefinitionGetListApiArg = {
  filterText?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type TemplateDefinitionGetApiResponse =
  /** status 200 Success */ VoloAbpTextTemplateManagementTextTemplatesTemplateDefinitionDto;
export type TemplateDefinitionGetApiArg = {
  name: string;
};
export type TimezoneLookupGetAllIanaApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpNameValue20VoloAbpCore20Version532020CultureNeutral20PublicKeyTokenNull;
export type TimezoneLookupGetAllIanaApiArg = void;
export type TimezoneLookupGetAllWindowsApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpNameValue20VoloAbpCore20Version532020CultureNeutral20PublicKeyTokenNull;
export type TimezoneLookupGetAllWindowsApiArg = void;
export type IdentityUserGetApiResponse = /** status 200 Success */ VoloAbpIdentityIdentityUserDto;
export type IdentityUserGetApiArg = {
  id: string;
};
export type IdentityUserUpdateApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDto;
export type IdentityUserUpdateApiArg = {
  id: string;
  voloAbpIdentityIdentityUserUpdateDto: VoloAbpIdentityIdentityUserUpdateDto;
};
export type IdentityUserDeleteApiResponse = /** status 200 Success */ undefined;
export type IdentityUserDeleteApiArg = {
  id: string;
};
export type IdentityUserGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityUserDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserGetListApiArg = {
  filter?: string;
  roleId?: string;
  organizationUnitId?: string;
  userName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  isLockedOut?: boolean;
  notActive?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type IdentityUserCreateApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDto;
export type IdentityUserCreateApiArg = {
  voloAbpIdentityIdentityUserCreateDto: VoloAbpIdentityIdentityUserCreateDto;
};
export type IdentityUserGetRolesApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserGetRolesApiArg = {
  id: string;
};
export type IdentityUserUpdateRolesApiResponse = /** status 200 Success */ undefined;
export type IdentityUserUpdateRolesApiArg = {
  id: string;
  voloAbpIdentityIdentityUserUpdateRolesDto: VoloAbpIdentityIdentityUserUpdateRolesDto;
};
export type IdentityUserGetAssignableRolesApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserGetAssignableRolesApiArg = void;
export type IdentityUserGetAvailableOrganizationUnitsApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserGetAvailableOrganizationUnitsApiArg = void;
export type IdentityUserGetAllClaimTypesApiResponse =
  /** status 200 Success */ VoloAbpIdentityClaimTypeDto[];
export type IdentityUserGetAllClaimTypesApiArg = void;
export type IdentityUserGetClaimsApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserClaimDto[];
export type IdentityUserGetClaimsApiArg = {
  id: string;
};
export type IdentityUserUpdateClaimsApiResponse = /** status 200 Success */ undefined;
export type IdentityUserUpdateClaimsApiArg = {
  id: string;
  body: VoloAbpIdentityIdentityUserClaimDto[];
};
export type IdentityUserGetOrganizationUnitsApiResponse =
  /** status 200 Success */ VoloAbpIdentityOrganizationUnitDto[];
export type IdentityUserGetOrganizationUnitsApiArg = {
  id: string;
};
export type IdentityUserLockApiResponse = /** status 200 Success */ undefined;
export type IdentityUserLockApiArg = {
  id: string;
  lockoutEnd: string;
};
export type IdentityUserUnlockApiResponse = /** status 200 Success */ undefined;
export type IdentityUserUnlockApiArg = {
  id: string;
};
export type IdentityUserFindByUsernameApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDto;
export type IdentityUserFindByUsernameApiArg = {
  username: string;
};
export type IdentityUserFindByEmailApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDto;
export type IdentityUserFindByEmailApiArg = {
  email: string;
};
export type IdentityUserGetTwoFactorEnabledApiResponse = /** status 200 Success */ boolean;
export type IdentityUserGetTwoFactorEnabledApiArg = {
  id: string;
};
export type IdentityUserSetTwoFactorEnabledApiResponse = /** status 200 Success */ undefined;
export type IdentityUserSetTwoFactorEnabledApiArg = {
  id: string;
  enabled: boolean;
};
export type IdentityUserUpdatePasswordApiResponse = /** status 200 Success */ undefined;
export type IdentityUserUpdatePasswordApiArg = {
  id: string;
  voloAbpIdentityIdentityUserUpdatePasswordInput: VoloAbpIdentityIdentityUserUpdatePasswordInput;
};
export type IdentityUserGetRoleLookupApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityRoleLookupDto[];
export type IdentityUserGetRoleLookupApiArg = void;
export type IdentityUserGetOrganizationUnitLookupApiResponse =
  /** status 200 Success */ VoloAbpIdentityOrganizationUnitLookupDto[];
export type IdentityUserGetOrganizationUnitLookupApiArg = void;
export type IdentityUserGetExternalLoginProvidersApiResponse =
  /** status 200 Success */ VoloAbpIdentityExternalLoginProviderDto[];
export type IdentityUserGetExternalLoginProvidersApiArg = void;
export type IdentityUserImportExternalUserApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDto;
export type IdentityUserImportExternalUserApiArg = {
  voloAbpIdentityImportExternalUserInput: VoloAbpIdentityImportExternalUserInput;
};
export type UserLinkageShareUserWithAnotherTenantApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDto;
export type UserLinkageShareUserWithAnotherTenantApiArg = {
  activ8UsersShareUserWithAnotherPartnerRequest: Activ8UsersShareUserWithAnotherPartnerRequest;
};
export type UserLinkageGetAllUserLinkagesApiResponse =
  /** status 200 Success */ Activ8UsersUserLinkageInfo[];
export type UserLinkageGetAllUserLinkagesApiArg = {
  userId?: string;
  tenantId?: string;
};
export type IdentityUserLookupFindByIdApiResponse = /** status 200 Success */ VoloAbpUsersUserData;
export type IdentityUserLookupFindByIdApiArg = {
  id: string;
};
export type IdentityUserLookupFindByUserNameApiResponse =
  /** status 200 Success */ VoloAbpUsersUserData;
export type IdentityUserLookupFindByUserNameApiArg = {
  userName: string;
};
export type IdentityUserLookupSearchApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpUsersUserData20VoloAbpUsersAbstractions20Version532020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserLookupSearchApiArg = {
  sorting?: string;
  filter?: string;
  skipCount?: number;
  maxResultCount?: number;
};
export type IdentityUserLookupGetCountApiResponse = /** status 200 Success */ number;
export type IdentityUserLookupGetCountApiArg = {
  filter?: string;
};
export type VoloAbpHttpModelingControllerInterfaceApiDescriptionModel = {
  type?: string | null;
};
export type VoloAbpHttpModelingMethodParameterApiDescriptionModel = {
  name?: string | null;
  typeAsString?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isOptional?: boolean;
  defaultValue?: any | null;
};
export type VoloAbpHttpModelingParameterApiDescriptionModel = {
  nameOnMethod?: string | null;
  name?: string | null;
  jsonName?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isOptional?: boolean;
  defaultValue?: any | null;
  constraintTypes?: string[] | null;
  bindingSourceId?: string | null;
  descriptorName?: string | null;
};
export type VoloAbpHttpModelingReturnValueApiDescriptionModel = {
  type?: string | null;
  typeSimple?: string | null;
};
export type VoloAbpHttpModelingActionApiDescriptionModel = {
  uniqueName?: string | null;
  name?: string | null;
  httpMethod?: string | null;
  url?: string | null;
  supportedVersions?: string[] | null;
  parametersOnMethod?: VoloAbpHttpModelingMethodParameterApiDescriptionModel[] | null;
  parameters?: VoloAbpHttpModelingParameterApiDescriptionModel[] | null;
  returnValue?: VoloAbpHttpModelingReturnValueApiDescriptionModel;
  allowAnonymous?: boolean | null;
  implementFrom?: string | null;
};
export type VoloAbpHttpModelingControllerApiDescriptionModel = {
  controllerName?: string | null;
  controllerGroupName?: string | null;
  isRemoteService?: boolean;
  apiVersion?: string | null;
  type?: string | null;
  interfaces?: VoloAbpHttpModelingControllerInterfaceApiDescriptionModel[] | null;
  actions?: {
    [key: string]: VoloAbpHttpModelingActionApiDescriptionModel;
  } | null;
};
export type VoloAbpHttpModelingModuleApiDescriptionModel = {
  rootPath?: string | null;
  remoteServiceName?: string | null;
  controllers?: {
    [key: string]: VoloAbpHttpModelingControllerApiDescriptionModel;
  } | null;
};
export type VoloAbpHttpModelingPropertyApiDescriptionModel = {
  name?: string | null;
  jsonName?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isRequired?: boolean;
};
export type VoloAbpHttpModelingTypeApiDescriptionModel = {
  baseType?: string | null;
  isEnum?: boolean;
  enumNames?: string[] | null;
  enumValues?: any[] | null;
  genericArguments?: string[] | null;
  properties?: VoloAbpHttpModelingPropertyApiDescriptionModel[] | null;
};
export type VoloAbpHttpModelingApplicationApiDescriptionModel = {
  modules?: {
    [key: string]: VoloAbpHttpModelingModuleApiDescriptionModel;
  } | null;
  types?: {
    [key: string]: VoloAbpHttpModelingTypeApiDescriptionModel;
  } | null;
};
export type VoloAbpHttpRemoteServiceValidationErrorInfo = {
  message?: string | null;
  members?: string[] | null;
};
export type VoloAbpHttpRemoteServiceErrorInfo = {
  code?: string | null;
  message?: string | null;
  details?: string | null;
  data?: {
    [key: string]: any;
  } | null;
  validationErrors?: VoloAbpHttpRemoteServiceValidationErrorInfo[] | null;
};
export type VoloAbpHttpRemoteServiceErrorResponse = {
  error?: VoloAbpHttpRemoteServiceErrorInfo;
};
export type VoloAbpLocalizationLanguageInfo = {
  cultureName?: string | null;
  uiCultureName?: string | null;
  displayName?: string | null;
  flagIcon?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsDateTimeFormatDto = {
  calendarAlgorithmType?: string | null;
  dateTimeFormatLong?: string | null;
  shortDatePattern?: string | null;
  fullDateTimePattern?: string | null;
  dateSeparator?: string | null;
  shortTimePattern?: string | null;
  longTimePattern?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto = {
  displayName?: string | null;
  englishName?: string | null;
  threeLetterIsoLanguageName?: string | null;
  twoLetterIsoLanguageName?: string | null;
  isRightToLeft?: boolean;
  cultureName?: string | null;
  name?: string | null;
  nativeName?: string | null;
  dateTimeFormat?: VoloAbpAspNetCoreMvcApplicationConfigurationsDateTimeFormatDto;
};
export type VoloAbpNameValue = {
  name?: string | null;
  value?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDto = {
  values?: {
    [key: string]: {
      [key: string]: string;
    } | null;
  } | null;
  languages?: VoloAbpLocalizationLanguageInfo[] | null;
  currentCulture?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto;
  defaultResourceName?: string | null;
  languagesMap?: {
    [key: string]: VoloAbpNameValue[] | null;
  } | null;
  languageFilesMap?: {
    [key: string]: VoloAbpNameValue[] | null;
  } | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationAuthConfigurationDto = {
  policies?: {
    [key: string]: boolean | null;
  } | null;
  grantedPolicies?: {
    [key: string]: boolean | null;
  } | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationSettingConfigurationDto = {
  values?: {
    [key: string]: string | null;
  } | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentUserDto = {
  isAuthenticated?: boolean;
  id?: string | null;
  tenantId?: string | null;
  impersonatorUserId?: string | null;
  impersonatorTenantId?: string | null;
  impersonatorUserName?: string | null;
  impersonatorTenantName?: string | null;
  userName?: string | null;
  name?: string | null;
  surName?: string | null;
  email?: string | null;
  emailVerified?: boolean;
  phoneNumber?: string | null;
  phoneNumberVerified?: boolean;
  roles?: string[] | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationFeatureConfigurationDto = {
  values?: {
    [key: string]: string | null;
  } | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationGlobalFeatureConfigurationDto =
  {
    enabledFeatures?: string[] | null;
  };
export type VoloAbpAspNetCoreMvcMultiTenancyMultiTenancyInfoDto = {
  isEnabled?: boolean;
};
export type VoloAbpAspNetCoreMvcMultiTenancyCurrentTenantDto = {
  id?: string | null;
  name?: string | null;
  isAvailable?: boolean;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsIanaTimeZone = {
  timeZoneName?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsWindowsTimeZone = {
  timeZoneId?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsTimeZone = {
  iana?: VoloAbpAspNetCoreMvcApplicationConfigurationsIanaTimeZone;
  windows?: VoloAbpAspNetCoreMvcApplicationConfigurationsWindowsTimeZone;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsTimingDto = {
  timeZone?: VoloAbpAspNetCoreMvcApplicationConfigurationsTimeZone;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsClockDto = {
  kind?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingLocalizableStringDto = {
  name?: string | null;
  resource?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiGetDto =
  {
    isAvailable?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiCreateDto =
  {
    isAvailable?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiUpdateDto =
  {
    isAvailable?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiDto = {
  onGet?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiGetDto;
  onCreate?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiCreateDto;
  onUpdate?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiUpdateDto;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiTableDto =
  {
    isVisible?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto =
  {
    isVisible?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiLookupDto =
  {
    url?: string | null;
    resultListPropertyName?: string | null;
    displayPropertyName?: string | null;
    valuePropertyName?: string | null;
    filterParamName?: string | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiDto = {
  onTable?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiTableDto;
  onCreateForm?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto;
  onEditForm?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto;
  lookup?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiLookupDto;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyAttributeDto =
  {
    typeSimple?: string | null;
    config?: {
      [key: string]: any | null;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyDto = {
  type?: string | null;
  typeSimple?: string | null;
  displayName?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingLocalizableStringDto;
  api?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiDto;
  ui?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiDto;
  attributes?:
    | VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyAttributeDto[]
    | null;
  configuration?: {
    [key: string]: any | null;
  } | null;
  defaultValue?: any | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingEntityExtensionDto = {
  properties?: {
    [key: string]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyDto;
  } | null;
  configuration?: {
    [key: string]: any | null;
  } | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingModuleExtensionDto = {
  entities?: {
    [key: string]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingEntityExtensionDto;
  } | null;
  configuration?: {
    [key: string]: any | null;
  } | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumFieldDto = {
  name?: string | null;
  value?: any | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumDto = {
  fields?:
    | VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumFieldDto[]
    | null;
  localizationResource?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingObjectExtensionsDto = {
  modules?: {
    [key: string]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingModuleExtensionDto;
  } | null;
  enums?: {
    [key: string]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumDto;
  } | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDto = {
  localization?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDto;
  auth?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationAuthConfigurationDto;
  setting?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationSettingConfigurationDto;
  currentUser?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentUserDto;
  features?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationFeatureConfigurationDto;
  globalFeatures?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationGlobalFeatureConfigurationDto;
  multiTenancy?: VoloAbpAspNetCoreMvcMultiTenancyMultiTenancyInfoDto;
  currentTenant?: VoloAbpAspNetCoreMvcMultiTenancyCurrentTenantDto;
  timing?: VoloAbpAspNetCoreMvcApplicationConfigurationsTimingDto;
  clock?: VoloAbpAspNetCoreMvcApplicationConfigurationsClockDto;
  objectExtensions?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingObjectExtensionsDto;
};
export type VoloAbpAspNetCoreMvcMultiTenancyFindTenantResultDto = {
  success?: boolean;
  tenantId?: string | null;
  name?: string | null;
  isActive?: boolean;
};
export type VoloAbpAccountAccountSettingsDto = {
  isSelfRegistrationEnabled?: boolean;
  enableLocalLogin?: boolean;
};
export type VoloAbpIdentityFeaturesIdentityProTwoFactorBehaviour =
  | 'Optional'
  | 'Disabled'
  | 'Forced';
export type VoloAbpAccountAccountTwoFactorSettingsDto = {
  twoFactorBehaviour?: VoloAbpIdentityFeaturesIdentityProTwoFactorBehaviour;
  isRememberBrowserEnabled?: boolean;
  usersCanChange?: boolean;
};
export type VoloAbpAccountAccountRecaptchaSettingsDto = {
  useCaptchaOnLogin?: boolean;
  useCaptchaOnRegistration?: boolean;
  verifyBaseUrl?: string | null;
  siteKey?: string | null;
  siteSecret?: string | null;
  version?: number;
  score?: number;
};
export type VoloAbpAccountExternalProvidersExternalProviderSettingsProperty = {
  name?: string | null;
  value?: string | null;
};
export type VoloAbpAccountExternalProvidersExternalProviderSettings = {
  name?: string | null;
  enabled?: boolean;
  properties?: VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[] | null;
  secretProperties?: VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[] | null;
};
export type VoloAbpAccountAccountExternalProviderSettingsDto = {
  settings?: VoloAbpAccountExternalProvidersExternalProviderSettings[] | null;
};
export type VoloAbpAccountUpdateExternalProviderDto = {
  name?: string | null;
  enabled?: boolean;
  properties?: VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[] | null;
  secretProperties?: VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[] | null;
};
export type Activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto = {
  apiUrl?: string | null;
  apiKey?: string | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestParticipant =
  {
    fullname?: string | null;
    contactPhone?: string | null;
    monthOfBirth?: string | null;
    yearOfBirth?: string | null;
  };
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestProgramSelectionRequest =
  {
    learningCourseProgramId?: string;
    contactId?: string | null;
    participants?:
      | Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestParticipant[]
      | null;
  };
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiPartnerInfoResponse = {
  partnerName?: string | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryDto = {
  id?: string;
  publicName?: string | null;
  publicShortDescription?: string | null;
  conductionAddress?: string | null;
  conductionIanaTimezone?: string | null;
  programReferenceCode?: string | null;
  publicLongRichTextDescription?: string | null;
  firstSessionDateAtUtc?: string | null;
  firstSessionDate?: string | null;
  firstSessionStartTime?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryDto[] | null;
  };
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryResponse = {
  programs?: VoloAbpApplicationDtosListResultDto601Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull;
  contactId?: string | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramPublicApiExpressionOfInterestListProgramsRequest =
  {
    emailAddress?: string | null;
    postcode?: string | null;
    countryCode?: string | null;
    partnerMarketingConsent?: boolean;
    activ8MarketingConsent?: boolean;
  };
export type Activ8IntegrationsApiApiIntegrationSettingsDto = {
  publicApiKey?: string | null;
};
export type VoloAbpIdentityServerApiResourceDtosApiResourceSecretDto = {
  apiResourceId?: string;
  type?: string | null;
  value?: string | null;
  description?: string | null;
  expiration?: string | null;
};
export type VoloAbpIdentityServerApiResourceDtosApiResourceScopeDto = {
  apiResourceId?: string;
  scope?: string | null;
};
export type VoloAbpIdentityServerApiResourceDtosApiResourceClaimDto = {
  apiResourceId?: string;
  type?: string | null;
};
export type VoloAbpIdentityServerApiResourceDtosApiResourcePropertyDto = {
  apiResourceId?: string;
  key?: string | null;
  value?: string | null;
};
export type VoloAbpIdentityServerApiResourceDtosApiResourceWithDetailsDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
  enabled?: boolean;
  allowedAccessTokenSigningAlgorithms?: string | null;
  showInDiscoveryDocument?: boolean;
  secrets?: VoloAbpIdentityServerApiResourceDtosApiResourceSecretDto[] | null;
  scopes?: VoloAbpIdentityServerApiResourceDtosApiResourceScopeDto[] | null;
  userClaims?: VoloAbpIdentityServerApiResourceDtosApiResourceClaimDto[] | null;
  properties?: VoloAbpIdentityServerApiResourceDtosApiResourcePropertyDto[] | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityServerApiResourceDtosApiResourceWithDetailsDto20VoloAbpIdentityServerApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityServerApiResourceDtosApiResourceWithDetailsDto[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityServerApiResourceDtosCreateApiResourceDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  displayName?: string | null;
  description?: string | null;
  allowedAccessTokenSigningAlgorithms?: string | null;
  showInDiscoveryDocument?: boolean;
  userClaims?: VoloAbpIdentityServerApiResourceDtosApiResourceClaimDto[] | null;
};
export type VoloAbpIdentityServerApiResourceDtosUpdateApiResourceDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName?: string | null;
  description?: string | null;
  allowedAccessTokenSigningAlgorithms?: string | null;
  enabled?: boolean;
  secrets?: VoloAbpIdentityServerApiResourceDtosApiResourceSecretDto[] | null;
  scopes?: VoloAbpIdentityServerApiResourceDtosApiResourceScopeDto[] | null;
  userClaims?: VoloAbpIdentityServerApiResourceDtosApiResourceClaimDto[] | null;
  properties?: VoloAbpIdentityServerApiResourceDtosApiResourcePropertyDto[] | null;
};
export type VoloAbpIdentityServerApiScopeDtosApiScopeClaimDto = {
  apiScopeId?: string;
  type?: string | null;
};
export type VoloAbpIdentityServerApiScopeDtosApiScopePropertyDto = {
  apiScopeId?: string;
  key?: string | null;
  value?: string | null;
};
export type VoloAbpIdentityServerApiScopeDtosApiScopeWithDetailsDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  enabled?: boolean;
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
  required?: boolean;
  emphasize?: boolean;
  showInDiscoveryDocument?: boolean;
  userClaims?: VoloAbpIdentityServerApiScopeDtosApiScopeClaimDto[] | null;
  properties?: VoloAbpIdentityServerApiScopeDtosApiScopePropertyDto[] | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityServerApiScopeDtosApiScopeWithDetailsDto20VoloAbpIdentityServerApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityServerApiScopeDtosApiScopeWithDetailsDto[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityServerApiScopeDtosCreateApiScopeDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  displayName?: string | null;
  description?: string | null;
  required?: boolean;
  enabled?: boolean;
  emphasize?: boolean;
  showInDiscoveryDocument?: boolean;
  userClaims?: VoloAbpIdentityServerApiScopeDtosApiScopeClaimDto[] | null;
  properties?: VoloAbpIdentityServerApiScopeDtosApiScopePropertyDto[] | null;
};
export type VoloAbpIdentityServerApiScopeDtosUpdateApiScopeDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName?: string | null;
  description?: string | null;
  required?: boolean;
  enabled?: boolean;
  emphasize?: boolean;
  showInDiscoveryDocument?: boolean;
  userClaims?: VoloAbpIdentityServerApiScopeDtosApiScopeClaimDto[] | null;
  properties?: VoloAbpIdentityServerApiScopeDtosApiScopePropertyDto[] | null;
};
export type VoloAbpAuditingEntityChangeType = 'Created' | 'Updated' | 'Deleted';
export type VoloAbpAuditLoggingEntityPropertyChangeDto = {
  id?: string;
  tenantId?: string | null;
  entityChangeId?: string;
  newValue?: string | null;
  originalValue?: string | null;
  propertyName?: string | null;
  propertyTypeFullName?: string | null;
};
export type VoloAbpAuditLoggingEntityChangeDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  auditLogId?: string;
  tenantId?: string | null;
  changeTime?: string;
  changeType?: VoloAbpAuditingEntityChangeType;
  entityId?: string | null;
  entityTypeFullName?: string | null;
  propertyChanges?: VoloAbpAuditLoggingEntityPropertyChangeDto[] | null;
};
export type VoloAbpAuditLoggingAuditLogActionDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  tenantId?: string | null;
  auditLogId?: string;
  serviceName?: string | null;
  methodName?: string | null;
  parameters?: string | null;
  executionTime?: string;
  executionDuration?: number;
};
export type VoloAbpAuditLoggingAuditLogDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  userId?: string | null;
  userName?: string | null;
  tenantId?: string | null;
  tenantName?: string | null;
  impersonatorUserId?: string | null;
  impersonatorUserName?: string | null;
  impersonatorTenantId?: string | null;
  impersonatorTenantName?: string | null;
  executionTime?: string;
  executionDuration?: number;
  clientIpAddress?: string | null;
  clientName?: string | null;
  browserInfo?: string | null;
  httpMethod?: string | null;
  url?: string | null;
  exceptions?: string | null;
  comments?: string | null;
  httpStatusCode?: number | null;
  applicationName?: string | null;
  correlationId?: string | null;
  entityChanges?: VoloAbpAuditLoggingEntityChangeDto[] | null;
  actions?: VoloAbpAuditLoggingAuditLogActionDto[] | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpAuditLoggingAuditLogDto20VoloAbpAuditLoggingApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpAuditLoggingAuditLogDto[] | null;
    totalCount?: number;
  };
export type SystemNetHttpStatusCode =
  | 'Continue'
  | 'SwitchingProtocols'
  | 'Processing'
  | 'EarlyHints'
  | 'OK'
  | 'Created'
  | 'Accepted'
  | 'NonAuthoritativeInformation'
  | 'NoContent'
  | 'ResetContent'
  | 'PartialContent'
  | 'MultiStatus'
  | 'AlreadyReported'
  | 'IMUsed'
  | 'MultipleChoices'
  | 'Ambiguous'
  | 'MovedPermanently'
  | 'Moved'
  | 'Found'
  | 'Redirect'
  | 'SeeOther'
  | 'RedirectMethod'
  | 'NotModified'
  | 'UseProxy'
  | 'Unused'
  | 'TemporaryRedirect'
  | 'RedirectKeepVerb'
  | 'PermanentRedirect'
  | 'BadRequest'
  | 'Unauthorized'
  | 'PaymentRequired'
  | 'Forbidden'
  | 'NotFound'
  | 'MethodNotAllowed'
  | 'NotAcceptable'
  | 'ProxyAuthenticationRequired'
  | 'RequestTimeout'
  | 'Conflict'
  | 'Gone'
  | 'LengthRequired'
  | 'PreconditionFailed'
  | 'RequestEntityTooLarge'
  | 'RequestUriTooLong'
  | 'UnsupportedMediaType'
  | 'RequestedRangeNotSatisfiable'
  | 'ExpectationFailed'
  | 'MisdirectedRequest'
  | 'UnprocessableEntity'
  | 'Locked'
  | 'FailedDependency'
  | 'UpgradeRequired'
  | 'PreconditionRequired'
  | 'TooManyRequests'
  | 'RequestHeaderFieldsTooLarge'
  | 'UnavailableForLegalReasons'
  | 'InternalServerError'
  | 'NotImplemented'
  | 'BadGateway'
  | 'ServiceUnavailable'
  | 'GatewayTimeout'
  | 'HttpVersionNotSupported'
  | 'VariantAlsoNegotiates'
  | 'InsufficientStorage'
  | 'LoopDetected'
  | 'NotExtended'
  | 'NetworkAuthenticationRequired';
export type VoloAbpAuditLoggingGetErrorRateOutput = {
  data?: {
    [key: string]: number | null;
  } | null;
};
export type VoloAbpAuditLoggingGetAverageExecutionDurationPerDayOutput = {
  data?: {
    [key: string]: number | null;
  } | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpAuditLoggingEntityChangeDto20VoloAbpAuditLoggingApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpAuditLoggingEntityChangeDto[] | null;
    totalCount?: number;
  };
export type VoloAbpAuditLoggingEntityChangeWithUsernameDto = {
  entityChange?: VoloAbpAuditLoggingEntityChangeDto;
  userName?: string | null;
};
export type VoloAbpIdentityIdentityClaimValueType = 'String' | 'Int' | 'Boolean' | 'DateTime';
export type VoloAbpIdentityClaimTypeDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
  required?: boolean;
  isStatic?: boolean;
  regex?: string | null;
  regexDescription?: string | null;
  description?: string | null;
  valueType?: VoloAbpIdentityIdentityClaimValueType;
  valueTypeAsString?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityClaimTypeDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityClaimTypeDto[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityCreateClaimTypeDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  required?: boolean;
  regex?: string | null;
  regexDescription?: string | null;
  description?: string | null;
  valueType?: VoloAbpIdentityIdentityClaimValueType;
};
export type VoloAbpIdentityUpdateClaimTypeDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  required?: boolean;
  regex?: string | null;
  regexDescription?: string | null;
  description?: string | null;
  valueType?: VoloAbpIdentityIdentityClaimValueType;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityServerClaimTypeDtosIdentityClaimTypeDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
};
export type VoloAbpIdentityServerClientDtosClientSecretDto = {
  clientId?: string;
  type?: string | null;
  value?: string | null;
  description?: string | null;
  expiration?: string | null;
};
export type VoloAbpIdentityServerClientDtosClientScopeDto = {
  clientId?: string;
  scope?: string | null;
};
export type VoloAbpIdentityServerClientDtosClientClaimDto = {
  type?: string | null;
  value?: string | null;
};
export type VoloAbpIdentityServerClientDtosClientGrantTypeDto = {
  clientId?: string;
  grantType?: string | null;
};
export type VoloAbpIdentityServerClientDtosClientIdentityProviderRestrictionDto = {
  clientId?: string;
  provider?: string | null;
};
export type VoloAbpIdentityServerClientDtosClientPropertyDto = {
  clientId?: string;
  key?: string | null;
  value?: string | null;
};
export type VoloAbpIdentityServerClientDtosClientCorsOriginDto = {
  clientId?: string;
  origin?: string | null;
};
export type VoloAbpIdentityServerClientDtosClientRedirectUriDto = {
  clientId?: string;
  redirectUri?: string | null;
};
export type VoloAbpIdentityServerClientDtosClientPostLogoutRedirectUriDto = {
  clientId?: string;
  postLogoutRedirectUri?: string | null;
};
export type VoloAbpIdentityServerClientDtosClientWithDetailsDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  clientId?: string | null;
  clientName?: string | null;
  description?: string | null;
  clientUri?: string | null;
  logoUri?: string | null;
  enabled?: boolean;
  protocolType?: string | null;
  requireClientSecret?: boolean;
  requireConsent?: boolean;
  allowRememberConsent?: boolean;
  alwaysIncludeUserClaimsInIdToken?: boolean;
  requirePkce?: boolean;
  allowPlainTextPkce?: boolean;
  requireRequestObject?: boolean;
  allowAccessTokensViaBrowser?: boolean;
  frontChannelLogoutUri?: string | null;
  frontChannelLogoutSessionRequired?: boolean;
  backChannelLogoutUri?: string | null;
  backChannelLogoutSessionRequired?: boolean;
  allowOfflineAccess?: boolean;
  identityTokenLifetime?: number;
  allowedIdentityTokenSigningAlgorithms?: string | null;
  accessTokenLifetime?: number;
  authorizationCodeLifetime?: number;
  consentLifetime?: number | null;
  absoluteRefreshTokenLifetime?: number;
  slidingRefreshTokenLifetime?: number;
  refreshTokenUsage?: number;
  updateAccessTokenClaimsOnRefresh?: boolean;
  refreshTokenExpiration?: number;
  accessTokenType?: number;
  enableLocalLogin?: boolean;
  includeJwtId?: boolean;
  alwaysSendClientClaims?: boolean;
  clientClaimsPrefix?: string | null;
  pairWiseSubjectSalt?: string | null;
  userSsoLifetime?: number | null;
  userCodeType?: string | null;
  deviceCodeLifetime?: number;
  clientSecrets?: VoloAbpIdentityServerClientDtosClientSecretDto[] | null;
  allowedScopes?: VoloAbpIdentityServerClientDtosClientScopeDto[] | null;
  claims?: VoloAbpIdentityServerClientDtosClientClaimDto[] | null;
  allowedGrantTypes?: VoloAbpIdentityServerClientDtosClientGrantTypeDto[] | null;
  identityProviderRestrictions?:
    | VoloAbpIdentityServerClientDtosClientIdentityProviderRestrictionDto[]
    | null;
  properties?: VoloAbpIdentityServerClientDtosClientPropertyDto[] | null;
  allowedCorsOrigins?: VoloAbpIdentityServerClientDtosClientCorsOriginDto[] | null;
  redirectUris?: VoloAbpIdentityServerClientDtosClientRedirectUriDto[] | null;
  postLogoutRedirectUris?: VoloAbpIdentityServerClientDtosClientPostLogoutRedirectUriDto[] | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityServerClientDtosClientWithDetailsDto20VoloAbpIdentityServerApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityServerClientDtosClientWithDetailsDto[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityServerClientDtosCreateClientDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  clientId: string;
  clientName: string;
  description?: string | null;
  clientUri?: string | null;
  logoUri?: string | null;
  requireConsent?: boolean;
  callbackUrl?: string | null;
  logoutUrl?: string | null;
  secrets?: VoloAbpIdentityServerClientDtosClientSecretDto[] | null;
  scopes?: string[] | null;
};
export type VoloAbpIdentityServerClientDtosUpdateClientDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  clientName?: string | null;
  description?: string | null;
  clientUri?: string | null;
  logoUri?: string | null;
  enabled?: boolean;
  requireConsent?: boolean;
  allowOfflineAccess?: boolean;
  allowRememberConsent?: boolean;
  allowAccessTokensViaBrowser?: boolean;
  requirePkce?: boolean;
  requireClientSecret?: boolean;
  requireRequestObject?: boolean;
  accessTokenLifetime?: number;
  consentLifetime?: number | null;
  accessTokenType?: number;
  enableLocalLogin?: boolean;
  frontChannelLogoutUri?: string | null;
  frontChannelLogoutSessionRequired?: boolean;
  backChannelLogoutUri?: string | null;
  allowedIdentityTokenSigningAlgorithms?: string | null;
  backChannelLogoutSessionRequired?: boolean;
  includeJwtId?: boolean;
  alwaysSendClientClaims?: boolean;
  pairWiseSubjectSalt?: string | null;
  userSsoLifetime?: number | null;
  userCodeType?: string | null;
  deviceCodeLifetime?: number;
  clientSecrets?: VoloAbpIdentityServerClientDtosClientSecretDto[] | null;
  claims?: VoloAbpIdentityServerClientDtosClientClaimDto[] | null;
  properties?: VoloAbpIdentityServerClientDtosClientPropertyDto[] | null;
  allowedGrantTypes?: string[] | null;
  identityProviderRestrictions?: string[] | null;
  scopes?: string[] | null;
  allowedCorsOrigins?: string[] | null;
  redirectUris?: string[] | null;
  postLogoutRedirectUris?: string[] | null;
};
export type Activ8DataDataImportTriggerParticipantDataImportRequest = {
  importFileBlobUrl?: string | null;
  assignToCourseProgramId?: string | null;
};
export type SaaSKitCoreModelsDtosStorageBlobPropertiesDto = {
  fileId?: string | null;
  partitionId?: string | null;
  fileCategory?: string | null;
  entityTypeName?: string | null;
  entityFriendlyId?: string | null;
  isPubliclyViewable?: boolean;
  thumbnailUrl?: string | null;
  fileName?: string | null;
  contentType?: string | null;
  fileExtension?: string | null;
};
export type Activ8CoursesTemplatesPrepareResourceOperationResponse = {
  blobReference?: string | null;
  properties?: SaaSKitCoreModelsDtosStorageBlobPropertiesDto;
  blobToken?: string | null;
};
export type VoloSaasHostDtosEditionDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  displayName?: string | null;
  planId?: string | null;
  planName?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloSaasHostDtosEditionUpdateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName: string;
  planId?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloSaasHostDtosEditionDto20VoloSaasHostApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloSaasHostDtosEditionDto[] | null;
    totalCount?: number;
  };
export type VoloSaasHostDtosEditionCreateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName: string;
  planId?: string | null;
};
export type VoloSaasHostGetEditionUsageStatisticsResultDto = {
  data?: {
    [key: string]: number | null;
  } | null;
};
export type VoloPaymentPlansPlanDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloPaymentRequestsPaymentType = 'OneTime' | 'Subscription';
export type VoloPaymentRequestsPaymentRequestProductDto = {
  paymentRequestId?: string;
  code?: string | null;
  name?: string | null;
  unitPrice?: number;
  count?: number;
  totalPrice?: number;
  paymentType?: VoloPaymentRequestsPaymentType;
  planId?: string;
  extraProperties?: {
    [key: string]: any;
  } | null;
};
export type VoloPaymentRequestsPaymentRequestState =
  | 'Waiting'
  | 'Completed'
  | 'Failed'
  | 'Refunded';
export type VoloPaymentRequestsPaymentRequestWithDetailsDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  products?: VoloPaymentRequestsPaymentRequestProductDto[] | null;
  currency?: string | null;
  state?: VoloPaymentRequestsPaymentRequestState;
  failReason?: string | null;
  emailSendDate?: string | null;
  gateway?: string | null;
  externalSubscriptionId?: string | null;
  totalPrice?: number;
  creationTime?: string;
};
export type VoloAbpSettingManagementEmailSettingsDto = {
  smtpHost?: string | null;
  smtpPort?: number;
  smtpUserName?: string | null;
  smtpPassword?: string | null;
  smtpDomain?: string | null;
  smtpEnableSsl?: boolean;
  smtpUseDefaultCredentials?: boolean;
  defaultFromAddress?: string | null;
  defaultFromDisplayName?: string | null;
};
export type VoloAbpSettingManagementUpdateEmailSettingsDto = {
  smtpHost?: string | null;
  smtpPort?: number;
  smtpUserName?: string | null;
  smtpPassword?: string | null;
  smtpDomain?: string | null;
  smtpEnableSsl?: boolean;
  smtpUseDefaultCredentials?: boolean;
  defaultFromAddress: string;
  defaultFromDisplayName: string;
};
export type Activ8FacilitatorsFacilitatorDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  tenantId?: string | null;
  userName?: string | null;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  supportTwoFactor?: boolean;
  twoFactorEnabled?: boolean;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  isLockedOut?: boolean;
  lockoutEnd?: string | null;
  concurrencyStamp?: string | null;
  roleNames?: string[] | null;
  accessFailedCount?: number;
  address?: string | null;
};
export type Activ8FacilitatorsFacilitatorCreateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  password: string;
  sendConfirmationEmail?: boolean;
};
export type VoloAbpApplicationDtosPagedResultDto601Activ8FacilitatorsFacilitatorDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8FacilitatorsFacilitatorDto[] | null;
    totalCount?: number;
  };
export type Activ8FacilitatorsFacilitatorUpdateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpFeatureManagementFeatureProviderDto = {
  name?: string | null;
  key?: string | null;
};
export type VoloAbpValidationStringValuesIValueValidator = {
  name?: string | null;
  properties?: {
    [key: string]: any;
  } | null;
};
export type VoloAbpValidationStringValuesIStringValueType = {
  name?: string | null;
  properties?: {
    [key: string]: any | null;
  } | null;
  validator?: VoloAbpValidationStringValuesIValueValidator;
};
export type VoloAbpFeatureManagementFeatureDto = {
  name?: string | null;
  displayName?: string | null;
  value?: string | null;
  provider?: VoloAbpFeatureManagementFeatureProviderDto;
  description?: string | null;
  valueType?: VoloAbpValidationStringValuesIStringValueType;
  depth?: number;
  parentName?: string | null;
};
export type VoloAbpFeatureManagementFeatureGroupDto = {
  name?: string | null;
  displayName?: string | null;
  features?: VoloAbpFeatureManagementFeatureDto[] | null;
};
export type VoloAbpFeatureManagementGetFeatureListResultDto = {
  groups?: VoloAbpFeatureManagementFeatureGroupDto[] | null;
};
export type VoloAbpFeatureManagementUpdateFeatureDto = {
  name?: string | null;
  value?: string | null;
};
export type VoloAbpFeatureManagementUpdateFeaturesDto = {
  features?: VoloAbpFeatureManagementUpdateFeatureDto[] | null;
};
export type VoloAbpIdentityServerIdentityResourceDtosIdentityResourceClaimDto = {
  identityResourceId?: string;
  type?: string | null;
};
export type VoloAbpIdentityServerIdentityResourceDtosIdentityResourcePropertyDto = {
  identityResourceId?: string;
  key?: string | null;
  value?: string | null;
};
export type VoloAbpIdentityServerIdentityResourceDtosIdentityResourceWithDetailsDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
  enabled?: boolean;
  required?: boolean;
  emphasize?: boolean;
  showInDiscoveryDocument?: boolean;
  userClaims?: VoloAbpIdentityServerIdentityResourceDtosIdentityResourceClaimDto[] | null;
  properties?: VoloAbpIdentityServerIdentityResourceDtosIdentityResourcePropertyDto[] | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityServerIdentityResourceDtosIdentityResourceWithDetailsDto20VoloAbpIdentityServerApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityServerIdentityResourceDtosIdentityResourceWithDetailsDto[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityServerIdentityResourceDtosCreateIdentityResourceDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  displayName?: string | null;
  description?: string | null;
  enabled?: boolean;
  required?: boolean;
  emphasize?: boolean;
  showInDiscoveryDocument?: boolean;
  userClaims?: VoloAbpIdentityServerIdentityResourceDtosIdentityResourceClaimDto[] | null;
  properties?: VoloAbpIdentityServerIdentityResourceDtosIdentityResourcePropertyDto[] | null;
};
export type VoloAbpIdentityServerIdentityResourceDtosUpdateIdentityResourceDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  displayName?: string | null;
  description?: string | null;
  enabled?: boolean;
  required?: boolean;
  emphasize?: boolean;
  showInDiscoveryDocument?: boolean;
  userClaims?: VoloAbpIdentityServerIdentityResourceDtosIdentityResourceClaimDto[] | null;
  properties?: VoloAbpIdentityServerIdentityResourceDtosIdentityResourcePropertyDto[] | null;
};
export type VoloAbpLanguageManagementDtoLanguageDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  cultureName?: string | null;
  uiCultureName?: string | null;
  displayName?: string | null;
  flagIcon?: string | null;
  isEnabled?: boolean;
  isDefaultLanguage?: boolean;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpLanguageManagementDtoLanguageDto20VoloAbpLanguageManagementApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpLanguageManagementDtoLanguageDto[] | null;
  };
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpLanguageManagementDtoLanguageDto20VoloAbpLanguageManagementApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpLanguageManagementDtoLanguageDto[] | null;
    totalCount?: number;
  };
export type VoloAbpLanguageManagementDtoCreateLanguageDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName?: string | null;
  cultureName?: string | null;
  uiCultureName?: string | null;
  flagIcon?: string | null;
  isEnabled?: boolean;
};
export type VoloAbpLanguageManagementDtoUpdateLanguageDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName?: string | null;
  flagIcon?: string | null;
  isEnabled?: boolean;
  concurrencyStamp?: string | null;
};
export type VoloAbpLanguageManagementDtoLanguageResourceDto = {
  name?: string | null;
};
export type VoloAbpLanguageManagementDtoCultureInfoDto = {
  displayName?: string | null;
  name?: string | null;
};
export type VoloAbpLanguageManagementDtoLanguageTextDto = {
  resourceName?: string | null;
  cultureName?: string | null;
  baseCultureName?: string | null;
  baseValue?: string | null;
  name?: string | null;
  value?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpLanguageManagementDtoLanguageTextDto20VoloAbpLanguageManagementApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpLanguageManagementDtoLanguageTextDto[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityIdentityUserDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  tenantId?: string | null;
  userName?: string | null;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  supportTwoFactor?: boolean;
  twoFactorEnabled?: boolean;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  isLockedOut?: boolean;
  lockoutEnd?: string | null;
  concurrencyStamp?: string | null;
  roleNames?: string[] | null;
  accessFailedCount?: number;
};
export type Activ8CoursesProgramsLearningCourseProgramStatus =
  | 'Undefined'
  | 'Draft'
  | 'Live'
  | 'Complete'
  | 'Cancelled';
export type SaaSKitCoreModelsDtosStorageBlobDto = {
  contentUrl?: string | null;
  fileSizeBytes?: number;
  properties?: SaaSKitCoreModelsDtosStorageBlobPropertiesDto;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramStatisticsDto = {
  averageParticipantModulesScorePercentage?: number | null;
  averageParticipantModuleCompletionPercentage?: number | null;
  totalContentViews?: number;
};
export type Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  creator?: VoloAbpIdentityIdentityUserDto;
  lastModifier?: VoloAbpIdentityIdentityUserDto;
  deleter?: VoloAbpIdentityIdentityUserDto;
  learningCourseProgramId?: string;
  facilitatorIdentityUserId?: string;
  lastDownloadOfCourseContentAt?: string | null;
  facilitatorIdentityUser?: VoloAbpIdentityIdentityUserDto;
  learningCourseProgram?: Activ8CoursesProgramsCoursesLearningCourseProgramDto;
  isUsingOutdatedCourseContent?: boolean;
  contentViewCount?: number;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramSessionDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  learningCourseProgramId?: string;
  learningCourseTemplateContentChapterId?: string;
  learningCourseTemplateContentSessionId?: string;
  sessionDate?: string | null;
  sessionStartTime?: string | null;
  sessionFinishTime?: string | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDto;
  lastModifier?: VoloAbpIdentityIdentityUserDto;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  learningCourseTemplateId?: string;
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  isListedOnPartnerPublicWebsite?: boolean;
  conductionAddress?: string | null;
  conductionIanaTimezone?: string | null;
  supportContactEmail?: string | null;
  supportContactPhone?: string | null;
  programReferenceCode?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageBlobDto;
  } | null;
  statistics?: Activ8CoursesProgramsCoursesLearningCourseProgramStatisticsDto;
  publicLongRichTextDescription?: string | null;
  cancellationReasonMessage?: string | null;
  facilitators?:
    | Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto[]
    | null;
  sessions?: Activ8CoursesProgramsCoursesLearningCourseProgramSessionDto[] | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramSessionCreateOrUpdateDto = {
  id?: string;
  learningCourseProgramId?: string;
  learningCourseTemplateContentChapterId?: string;
  learningCourseTemplateContentSessionId?: string;
  sessionDate?: string | null;
  sessionStartTime?: string | null;
  sessionFinishTime?: string | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramUpdateDto = {
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  publicLongRichTextDescription?: string | null;
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  isListedOnPartnerPublicWebsite?: boolean;
  conductionAddress?: string | null;
  conductionIanaTimezone?: string | null;
  supportContactEmail?: string | null;
  supportContactPhone?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageBlobDto;
  } | null;
  sessions?: Activ8CoursesProgramsCoursesLearningCourseProgramSessionCreateOrUpdateDto[] | null;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramCreateDto = {
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  publicLongRichTextDescription?: string | null;
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  isListedOnPartnerPublicWebsite?: boolean;
  conductionAddress?: string | null;
  conductionIanaTimezone?: string | null;
  supportContactEmail?: string | null;
  supportContactPhone?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageBlobDto;
  } | null;
  sessions?: Activ8CoursesProgramsCoursesLearningCourseProgramSessionCreateOrUpdateDto[] | null;
  newId?: string;
  learningCourseTemplateId?: string;
};
export type Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDto;
  lastModifier?: VoloAbpIdentityIdentityUserDto;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  learningCourseTemplateId?: string;
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  isListedOnPartnerPublicWebsite?: boolean;
  conductionAddress?: string | null;
  conductionIanaTimezone?: string | null;
  supportContactEmail?: string | null;
  supportContactPhone?: string | null;
  programReferenceCode?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageBlobDto;
  } | null;
  statistics?: Activ8CoursesProgramsCoursesLearningCourseProgramStatisticsDto;
};
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDto[] | null;
    totalCount?: number;
  };
export type Activ8CoursesProgramsCoursesProgramStatusSetRequestDto = {
  status?: Activ8CoursesProgramsLearningCourseProgramStatus;
  cancellationReasonMessage?: string | null;
  learningCourseProgramId: string;
};
export type Activ8CoursesProgramsParticipantToLearningCourseProgramAssignmentStatus =
  | 'Undefined'
  | 'Registered'
  | 'Started'
  | 'Completed';
export type Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentProgressionInfoSummary =
  {
    inProgressCount?: number;
    notStartedCount?: number;
    incompleteCount?: number;
    completeCount?: number;
    totalModulesCount?: number;
  };
export type Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentSessionProgressionStatus =
  'NotRequired' | 'NotStarted' | 'InProgress' | 'Complete';
export type Activ8CoursesContentQuizResultsLearningCourseContentQuizQuestionResultMultipleChoiceOption =
  {
    id?: string;
    learningCourseContentQuizQuestionMultipleChoiceOptionId?: string;
    wasSelectedByUser?: boolean | null;
    isCorrectSelection?: boolean | null;
    isCorrectAnswer?: boolean;
  };
export type Activ8CoursesContentQuizResultsLearningCourseContentQuizQuestionResult = {
  id?: string;
  learningCourseContentQuizQuestionId?: string;
  isCompleted?: boolean;
  isUserAnswerCorrect?: boolean;
  isUserAnswerAssessed?: boolean;
  completionDuration?: string | null;
  entryTime?: string | null;
  exitTime?: string | null;
  multipleChoiceAnswers?:
    | Activ8CoursesContentQuizResultsLearningCourseContentQuizQuestionResultMultipleChoiceOption[]
    | null;
};
export type Activ8CoursesContentQuizResultsLearningCourseContentQuizResult = {
  id?: string;
  learningCourseContentQuizId?: string;
  isCompleted?: boolean;
  completionDuration?: string | null;
  questions?: Activ8CoursesContentQuizResultsLearningCourseContentQuizQuestionResult[] | null;
};
export type Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentSessionProgressionInfo =
  {
    id?: string;
    learningCourseTemplateContentChapterId?: string;
    learningCourseTemplateContentSessionId?: string;
    progressionStatus?: Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentSessionProgressionStatus;
    quizResults?: Activ8CoursesContentQuizResultsLearningCourseContentQuizResult[] | null;
  };
export type Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentProgressionInfo =
  {
    summary?: Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentProgressionInfoSummary;
    sessions?:
      | Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentSessionProgressionInfo[]
      | null;
  };
export type Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  creator?: VoloAbpIdentityIdentityUserDto;
  lastModifier?: VoloAbpIdentityIdentityUserDto;
  deleter?: VoloAbpIdentityIdentityUserDto;
  learningCourseProgramId?: string;
  participantIdentityUserId?: string;
  participantIdentityUser?: VoloAbpIdentityIdentityUserDto;
  assignmentStatus?: Activ8CoursesProgramsParticipantToLearningCourseProgramAssignmentStatus;
  progression?: Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentProgressionInfo;
  learningCourseProgram?: Activ8CoursesProgramsCoursesLearningCourseProgramDto;
  onlineModuleScorePercentage?: number | null;
  onlineModuleCompletionPercentage?: number | null;
  contentViewCount?: number;
};
export type Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentUpdateDto =
  object;
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?:
      | Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto[]
      | null;
    totalCount?: number;
  };
export type Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentCreateDto =
  {
    facilitatorIdentityUserId?: string;
    learningCourseProgramId?: string;
  };
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?:
      | Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto[]
      | null;
    totalCount?: number;
  };
export type Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentCreateDto =
  {
    participantIdentityUserId?: string;
    learningCourseProgramId?: string;
    createdByDataImportIdentifier?: string | null;
  };
export type Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentUpdateDto =
  object;
export type SystemTimeSpan = {
  ticks?: number;
  days?: number;
  hours?: number;
  milliseconds?: number;
  minutes?: number;
  seconds?: number;
  totalDays?: number;
  totalHours?: number;
  totalMilliseconds?: number;
  totalMinutes?: number;
  totalSeconds?: number;
};
export type Activ8CoursesContentLearningCourseContentChapterSummary = {
  id?: string;
  name?: string | null;
  duration?: SystemTimeSpan;
};
export type Activ8CoursesContentLearningCourseContentResourceType =
  | 'Generic'
  | 'Image'
  | 'Video'
  | 'Pdf'
  | 'Audio';
export type SaaSKitCoreModelsDtosCoordinateDto = {
  x?: number;
  y?: number;
};
export type MediaCropInfoAreaInfo = {
  coordinates?: SaaSKitCoreModelsDtosCoordinateDto;
  width?: number;
  height?: number;
};
export type MediaCropInfoDto = {
  percentageArea?: MediaCropInfoAreaInfo;
  pixelArea?: MediaCropInfoAreaInfo;
  zoom?: number;
};
export type Activ8CoursesContentLearningCourseContentResource = {
  id?: string;
  type?: Activ8CoursesContentLearningCourseContentResourceType;
  description?: string | null;
  blobReference?: string | null;
  mediaCrop?: MediaCropInfoDto;
  fileName?: string | null;
  contentType?: string | null;
  fileExtension?: string | null;
  fileSizeBytes?: number;
};
export type Activ8CoursesContentLearningCourseContentBlockType =
  | 'Undefined'
  | 'RichText'
  | 'Quiz'
  | 'Resources'
  | 'Media'
  | 'PageBreak';
export type Activ8CoursesContentQuizLearningCourseContentQuizQuestionMultipleChoiceOption = {
  id?: string;
  answerText?: string | null;
  isCorrect?: boolean;
};
export type Activ8CoursesContentQuizLearningCourseContentQuizQuestion = {
  id?: string;
  questionText?: string | null;
  choiceOptions?:
    | Activ8CoursesContentQuizLearningCourseContentQuizQuestionMultipleChoiceOption[]
    | null;
};
export type Activ8CoursesContentQuizLearningCourseContentQuiz = {
  id?: string;
  description?: string | null;
  isAssessed?: boolean;
  isAnswerFeedbackShown?: boolean;
  questions?: Activ8CoursesContentQuizLearningCourseContentQuizQuestion[] | null;
};
export type Activ8CoursesContentLearningCourseContentBlock = {
  id?: string;
  type?: Activ8CoursesContentLearningCourseContentBlockType;
  richText?: string | null;
  quiz?: Activ8CoursesContentQuizLearningCourseContentQuiz;
  resources?: Activ8CoursesContentLearningCourseContentResource[] | null;
};
export type Activ8CoursesContentLearningCourseContentChapterSession = {
  id?: string;
  name?: string | null;
  duration?: SystemTimeSpan;
  facilitatorNotes?: string | null;
  facilitatorResources?: Activ8CoursesContentLearningCourseContentResource[] | null;
  content?: Activ8CoursesContentLearningCourseContentBlock[] | null;
};
export type Activ8CoursesProgramsSessionsLearningCourseProgramSessionPreparedContentDto = {
  program?: Activ8CoursesProgramsCoursesLearningCourseProgramSummaryDto;
  chapter?: Activ8CoursesContentLearningCourseContentChapterSummary;
  session?: Activ8CoursesContentLearningCourseContentChapterSession;
  resourceContentUrls?: {
    [key: string]: string | null;
  } | null;
};
export type Activ8CoursesProgramsSessionsProgressThroughSessionContentResponse = {
  quizResults?: Activ8CoursesContentQuizResultsLearningCourseContentQuizResult[] | null;
};
export type Activ8CoursesProgramsSessionsProgressThroughSessionContentRequest = {
  entryTime?: string;
  exitTime?: string;
  learningCourseProgramId?: string;
  learningCourseTemplateContentChapterId?: string;
  learningCourseTemplateContentSessionId?: string;
  learningCourseContentBlockId?: string;
  learningCourseContentQuizId?: string | null;
  learningCourseContentQuizQuestionId?: string | null;
  learningCourseContentQuizQuestionMultipleChoiceOptionIdsSelected?: string[] | null;
};
export type Activ8CoursesProgramsSessionsProgressThroughSessionContentBatchResponse = {
  responses?: Activ8CoursesProgramsSessionsProgressThroughSessionContentResponse[] | null;
};
export type Activ8CoursesProgramsSessionsProgressThroughSessionContentBatchRequest = {
  requests?: Activ8CoursesProgramsSessionsProgressThroughSessionContentRequest[] | null;
};
export type Activ8CoursesTemplatesLearningCourseTemplateStatus =
  | 'Undefined'
  | 'Draft'
  | 'Paused'
  | 'Live';
export type Activ8CoursesTemplatesTemplateStatusSetRequestDto = {
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  learningCourseTemplateId: string;
};
export type VoloAbpApplicationDtosListResultDto601SystemString20SystemPrivateCoreLib20Version600020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E =
  {
    items?: string[] | null;
  };
export type Activ8CoursesTemplatesLearningCourseTemplatePreRequisiteType =
  | 'Undefined'
  | 'Facilitator'
  | 'Participant';
export type Activ8CoursesTemplatesPersonAccreditationPreRequisiteToLearningCourseTemplateAssignmentDto =
  {
    id?: string;
    creationTime?: string;
    creatorId?: string | null;
    lastModificationTime?: string | null;
    lastModifierId?: string | null;
    type?: Activ8CoursesTemplatesLearningCourseTemplatePreRequisiteType;
    personAccreditationId?: string;
  };
export type Activ8CoursesTemplatesPersonAttributePreRequisiteToLearningCourseTemplateAssignmentDto =
  {
    id?: string;
    creationTime?: string;
    creatorId?: string | null;
    lastModificationTime?: string | null;
    lastModifierId?: string | null;
    type?: Activ8CoursesTemplatesLearningCourseTemplatePreRequisiteType;
    personAttributeId?: string;
  };
export type Activ8CoursesTemplatesPersonAccreditationPostCompletionToLearningCourseTemplateAssignmentDto =
  {
    id?: string;
    creationTime?: string;
    creatorId?: string | null;
    lastModificationTime?: string | null;
    lastModifierId?: string | null;
    personAccreditationId: string;
  };
export type Activ8CoursesTemplatesTenantLicenseToLearningCourseTemplateAssignmentDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  licensedToTenantId: string;
  learningCourseTemplateId: string;
};
export type Activ8CoursesTemplatesLearningCourseTemplateDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDto;
  lastModifier?: VoloAbpIdentityIdentityUserDto;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  courseReferenceCode?: string | null;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageBlobDto;
  } | null;
  publicLongRichTextDescription?: string | null;
  partnerLongRichTextDescription?: string | null;
  facilitatorLongRichTextDescription?: string | null;
  participantWelcomeEmailTemplate?: string | null;
  accreditationPreRequisites?:
    | Activ8CoursesTemplatesPersonAccreditationPreRequisiteToLearningCourseTemplateAssignmentDto[]
    | null;
  attributePreRequisites?:
    | Activ8CoursesTemplatesPersonAttributePreRequisiteToLearningCourseTemplateAssignmentDto[]
    | null;
  postCompletionAccreditations?:
    | Activ8CoursesTemplatesPersonAccreditationPostCompletionToLearningCourseTemplateAssignmentDto[]
    | null;
  tenantLicenses?:
    | Activ8CoursesTemplatesTenantLicenseToLearningCourseTemplateAssignmentDto[]
    | null;
};
export type Activ8CoursesContentLearningCourseContentChapter = {
  id?: string;
  name?: string | null;
  duration?: SystemTimeSpan;
  sessions?: Activ8CoursesContentLearningCourseContentChapterSession[] | null;
};
export type Activ8CoursesContentLearningCourseContent = {
  chapters?: Activ8CoursesContentLearningCourseContentChapter[] | null;
};
export type Activ8CoursesTemplatesLearningCourseTemplateUpdateDto = {
  content?: Activ8CoursesContentLearningCourseContent;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageBlobDto;
  } | null;
  publicLongRichTextDescription?: string | null;
  partnerLongRichTextDescription?: string | null;
  facilitatorLongRichTextDescription?: string | null;
  participantWelcomeEmailTemplate?: string | null;
  facilitatorAccreditationIdsPreRequisites?: string[] | null;
  participantAccreditationIdsPreRequisites?: string[] | null;
  facilitatorAttributeIdsPreRequisites?: string[] | null;
  postCompletionAccreditationsIds?: string[] | null;
  licensedTenantIds?: string[] | null;
};
export type VoloAbpApplicationDtosListResultDto601Activ8CoursesTemplatesTenantLicenseToLearningCourseTemplateAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8CoursesTemplatesTenantLicenseToLearningCourseTemplateAssignmentDto[] | null;
  };
export type Activ8CoursesTemplatesLearningCourseTemplateCreateDto = {
  content?: Activ8CoursesContentLearningCourseContent;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageBlobDto;
  } | null;
  publicLongRichTextDescription?: string | null;
  partnerLongRichTextDescription?: string | null;
  facilitatorLongRichTextDescription?: string | null;
  participantWelcomeEmailTemplate?: string | null;
  facilitatorAccreditationIdsPreRequisites?: string[] | null;
  participantAccreditationIdsPreRequisites?: string[] | null;
  facilitatorAttributeIdsPreRequisites?: string[] | null;
  postCompletionAccreditationsIds?: string[] | null;
  licensedTenantIds?: string[] | null;
  newId?: string;
};
export type Activ8CoursesTemplatesLearningCourseTemplateSummaryDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  creator?: VoloAbpIdentityIdentityUserDto;
  lastModifier?: VoloAbpIdentityIdentityUserDto;
  internalName?: string | null;
  publicName?: string | null;
  publicShortDescription?: string | null;
  status?: Activ8CoursesTemplatesLearningCourseTemplateStatus;
  isRecommendedForPartnerPublicWebsite?: boolean;
  courseReferenceCode?: string | null;
  enrollmentAllowedForIdentityRoleName?: string | null;
  partnerShortDescription?: string | null;
  facilitatorShortDescription?: string | null;
  bannerImage?: {
    [key: string]: SaaSKitCoreModelsDtosStorageBlobDto;
  } | null;
};
export type VoloAbpApplicationDtosPagedResultDto601Activ8CoursesTemplatesLearningCourseTemplateSummaryDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8CoursesTemplatesLearningCourseTemplateSummaryDto[] | null;
    totalCount?: number;
  };
export type VoloAbpLeptonThemeManagementMenuPlacement = 'Left' | 'Top';
export type VoloAbpLeptonThemeManagementMenuStatus = 'AlwaysOpened' | 'OpenOnHover';
export type VoloAbpLeptonThemeManagementLeptonStyle =
  | 'Style1'
  | 'Style2'
  | 'Style3'
  | 'Style4'
  | 'Style5'
  | 'Style6';
export type VoloAbpLeptonThemeManagementLeptonThemeSettingsDto = {
  boxedLayout?: boolean;
  menuPlacement?: VoloAbpLeptonThemeManagementMenuPlacement;
  menuStatus?: VoloAbpLeptonThemeManagementMenuStatus;
  style?: VoloAbpLeptonThemeManagementLeptonStyle;
  publicLayoutStyle?: VoloAbpLeptonThemeManagementLeptonStyle;
};
export type VoloAbpLeptonThemeManagementUpdateLeptonThemeSettingsDto = {
  boxedLayout?: boolean;
  menuPlacement?: VoloAbpLeptonThemeManagementMenuPlacement;
  menuStatus?: VoloAbpLeptonThemeManagementMenuStatus;
  style?: VoloAbpLeptonThemeManagementLeptonStyle;
  publicLayoutStyle?: VoloAbpLeptonThemeManagementLeptonStyle;
};
export type VoloAbpIdentityOrganizationUnitRoleInput = {
  roleIds?: string[] | null;
};
export type VoloAbpIdentityIdentityRoleDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
  isDefault?: boolean;
  isStatic?: boolean;
  isPublic?: boolean;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityIdentityRoleDto[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityOrganizationUnitUserInput = {
  userIds?: string[] | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentityUserDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityIdentityUserDto[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityOrganizationUnitWithDetailsDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  parentId?: string | null;
  code?: string | null;
  displayName?: string | null;
  roles?: VoloAbpIdentityIdentityRoleDto[] | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityOrganizationUnitCreateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName: string;
  parentId?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityOrganizationUnitWithDetailsDto[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityOrganizationUnitUpdateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  displayName: string;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpIdentityOrganizationUnitWithDetailsDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityOrganizationUnitWithDetailsDto[] | null;
  };
export type VoloAbpIdentityOrganizationUnitMoveInput = {
  newParentId?: string | null;
};
export type Activ8ParticipantsParticipantDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  tenantId?: string | null;
  userName?: string | null;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  supportTwoFactor?: boolean;
  twoFactorEnabled?: boolean;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  isLockedOut?: boolean;
  lockoutEnd?: string | null;
  concurrencyStamp?: string | null;
  roleNames?: string[] | null;
  accessFailedCount?: number;
  address?: string | null;
  parentIdentityUser?: VoloAbpIdentityIdentityUserDto;
  childIdentityUsers?: VoloAbpIdentityIdentityUserDto[] | null;
};
export type Activ8ParticipantsParticipantCreateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  password: string;
  sendConfirmationEmail?: boolean;
  createdAt?: string | null;
  isPartialUser?: boolean;
};
export type VoloAbpApplicationDtosPagedResultDto601Activ8ParticipantsParticipantDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8ParticipantsParticipantDto[] | null;
    totalCount?: number;
  };
export type Activ8ParticipantsParticipantUpdateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  concurrencyStamp?: string | null;
  isPartialUser?: boolean;
};
export type Activ8AppNotificationsAppNotificationSeverity = 'Information' | 'Warning' | 'Danger';
export type Activ8AppNotificationsAppNotification = {
  textMessage?: string | null;
  severity?: Activ8AppNotificationsAppNotificationSeverity;
  typeKey?: string | null;
  properties?: {
    [key: string]: any | null;
  } | null;
};
export type Activ8PartnersDashboardPartnerDashboardStatistics = {
  averageParticipantModulesScorePercentage?: number | null;
  averageParticipantModuleCompletionPercentage?: number | null;
};
export type Activ8PartnersDashboardPartnerDashboardResponse = {
  notifications?: Activ8AppNotificationsAppNotification[] | null;
  statistics?: Activ8PartnersDashboardPartnerDashboardStatistics;
};
export type Activ8PartnersPartnerProfileDetail = {
  publicWebsiteUrl?: string | null;
  publicWebsiteProgramBookUrl?: string | null;
  taxOrCompanyNumber?: string | null;
  headOfficeAddress?: string | null;
  ianaTimezone?: string | null;
  keyDecisionMakerContactName?: string | null;
  keyDecisionMakerContactEmail?: string | null;
  accountsContactName?: string | null;
  accountsContactEmail?: string | null;
  marketingContactName?: string | null;
  marketingContactEmail?: string | null;
  participantSupportContactName?: string | null;
  participantSupportContactEmail?: string | null;
};
export type Activ8PartnersProfilePartnerProfileDetailSetRequest = {
  publicWebsiteUrl?: string | null;
  publicWebsiteProgramBookUrl?: string | null;
  taxOrCompanyNumber?: string | null;
  headOfficeAddress?: string | null;
  ianaTimezone?: string | null;
  keyDecisionMakerContactName?: string | null;
  keyDecisionMakerContactEmail?: string | null;
  accountsContactName?: string | null;
  accountsContactEmail?: string | null;
  marketingContactName?: string | null;
  marketingContactEmail?: string | null;
  participantSupportContactName?: string | null;
  participantSupportContactEmail?: string | null;
};
export type VoloAbpPermissionManagementProviderInfoDto = {
  providerName?: string | null;
  providerKey?: string | null;
};
export type VoloAbpPermissionManagementPermissionGrantInfoDto = {
  name?: string | null;
  displayName?: string | null;
  parentName?: string | null;
  isGranted?: boolean;
  allowedProviders?: string[] | null;
  grantedProviders?: VoloAbpPermissionManagementProviderInfoDto[] | null;
};
export type VoloAbpPermissionManagementPermissionGroupDto = {
  name?: string | null;
  displayName?: string | null;
  permissions?: VoloAbpPermissionManagementPermissionGrantInfoDto[] | null;
};
export type VoloAbpPermissionManagementGetPermissionListResultDto = {
  entityDisplayName?: string | null;
  groups?: VoloAbpPermissionManagementPermissionGroupDto[] | null;
};
export type VoloAbpPermissionManagementUpdatePermissionDto = {
  name?: string | null;
  isGranted?: boolean;
};
export type VoloAbpPermissionManagementUpdatePermissionsDto = {
  permissions?: VoloAbpPermissionManagementUpdatePermissionDto[] | null;
};
export type Activ8PersonAccreditationsPersonAccreditationDto = {
  id?: string;
  description?: string | null;
  creationTime?: string;
  lastModificationTime?: string | null;
};
export type Activ8PersonAccreditationsPersonAccreditationToUserAssignmentDto = {
  id?: string;
  personAccreditation?: Activ8PersonAccreditationsPersonAccreditationDto;
  identityUserId?: string;
  creationTime?: string;
  lastModificationTime?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601Activ8PersonAccreditationsPersonAccreditationToUserAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8PersonAccreditationsPersonAccreditationToUserAssignmentDto[] | null;
  };
export type VoloAbpApplicationDtosPagedResultDto601Activ8PersonAccreditationsPersonAccreditationDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8PersonAccreditationsPersonAccreditationDto[] | null;
    totalCount?: number;
  };
export type Activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto = {
  description?: string | null;
};
export type Activ8PersonAttributesPersonAttributeDto = {
  id?: string;
  description?: string | null;
  creationTime?: string;
  lastModificationTime?: string | null;
};
export type Activ8PersonAttributesPersonAttributeToUserAssignmentDto = {
  id?: string;
  personAttribute?: Activ8PersonAttributesPersonAttributeDto;
  identityUserId?: string;
  creationTime?: string;
  lastModificationTime?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601Activ8PersonAttributesPersonAttributeToUserAssignmentDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8PersonAttributesPersonAttributeToUserAssignmentDto[] | null;
  };
export type VoloAbpApplicationDtosPagedResultDto601Activ8PersonAttributesPersonAttributeDto20Activ8ApplicationContracts20Version100020CultureNeutral20PublicKeyTokenNull =
  {
    items?: Activ8PersonAttributesPersonAttributeDto[] | null;
    totalCount?: number;
  };
export type Activ8PersonAttributesPersonAttributeCreateOrUpdateDto = {
  description?: string | null;
};
export type VoloAbpIdentityIdentityRoleUpdateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  isDefault?: boolean;
  isPublic?: boolean;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityIdentityRoleCreateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  isDefault?: boolean;
  isPublic?: boolean;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpIdentityIdentityRoleDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityIdentityRoleDto[] | null;
  };
export type VoloAbpIdentityIdentityRoleClaimDto = {
  roleId?: string;
  claimType?: string | null;
  claimValue?: string | null;
};
export type VoloAbpIdentityIdentitySecurityLogDto = {
  id?: string;
  tenantId?: string | null;
  applicationName?: string | null;
  identity?: string | null;
  action?: string | null;
  userId?: string | null;
  userName?: string | null;
  tenantName?: string | null;
  clientId?: string | null;
  correlationId?: string | null;
  clientIpAddress?: string | null;
  browserInfo?: string | null;
  creationTime?: string;
  extraProperties?: {
    [key: string]: any | null;
  } | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySecurityLogDto20VoloAbpIdentityProApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityIdentitySecurityLogDto[] | null;
    totalCount?: number;
  };
export type VoloAbpIdentityIdentityPasswordSettingsDto = {
  requiredLength?: number;
  requiredUniqueChars?: number;
  requireNonAlphanumeric?: boolean;
  requireLowercase?: boolean;
  requireUppercase?: boolean;
  requireDigit?: boolean;
};
export type VoloAbpIdentityIdentityLockoutSettingsDto = {
  allowedForNewUsers?: boolean;
  lockoutDuration?: number;
  maxFailedAccessAttempts?: number;
};
export type VoloAbpIdentityIdentitySignInSettingsDto = {
  requireConfirmedEmail?: boolean;
  enablePhoneNumberConfirmation?: boolean;
  requireConfirmedPhoneNumber?: boolean;
};
export type VoloAbpIdentityIdentityUserSettingsDto = {
  isUserNameUpdateEnabled?: boolean;
  isEmailUpdateEnabled?: boolean;
};
export type VoloAbpIdentityIdentitySettingsDto = {
  password?: VoloAbpIdentityIdentityPasswordSettingsDto;
  lockout?: VoloAbpIdentityIdentityLockoutSettingsDto;
  signIn?: VoloAbpIdentityIdentitySignInSettingsDto;
  user?: VoloAbpIdentityIdentityUserSettingsDto;
};
export type VoloAbpIdentityIdentityLdapSettingsDto = {
  enableLdapLogin?: boolean;
  ldapServerHost?: string | null;
  ldapServerPort?: string | null;
  ldapBaseDc?: string | null;
  ldapDomain?: string | null;
  ldapUserName?: string | null;
  ldapPassword?: string | null;
};
export type VoloAbpIdentityIdentityOAuthSettingsDto = {
  enableOAuthLogin?: boolean;
  clientId: string;
  clientSecret?: string | null;
  authority: string;
  scope?: string | null;
  requireHttpsMetadata?: boolean;
};
export type VoloSaasTenantActivationState = 'Active' | 'ActiveWithLimitedTime' | 'Passive';
export type VoloSaasHostDtosSaasTenantDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  name?: string | null;
  editionId?: string | null;
  editionEndDateUtc?: string | null;
  editionName?: string | null;
  hasDefaultConnectionString?: boolean;
  activationState?: VoloSaasTenantActivationState;
  activationEndDate?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloSaasHostDtosSaasTenantUpdateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  editionId?: string | null;
  activationState?: VoloSaasTenantActivationState;
  activationEndDate?: string | null;
  editionEndDateUtc?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloSaasHostDtosSaasTenantDto20VoloSaasHostApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloSaasHostDtosSaasTenantDto[] | null;
    totalCount?: number;
  };
export type VoloSaasHostDtosSaasTenantDatabaseConnectionStringsDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  databaseName?: string | null;
  connectionString?: string | null;
};
export type VoloSaasHostDtosSaasTenantConnectionStringsDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  default?: string | null;
  databases?: VoloSaasHostDtosSaasTenantDatabaseConnectionStringsDto[] | null;
};
export type VoloSaasHostDtosSaasTenantCreateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  name: string;
  editionId?: string | null;
  activationState?: VoloSaasTenantActivationState;
  activationEndDate?: string | null;
  editionEndDateUtc?: string | null;
  adminEmailAddress: string;
  adminPassword: string;
  connectionStrings?: VoloSaasHostDtosSaasTenantConnectionStringsDto;
};
export type VoloSaasHostDtosSaasTenantDatabasesDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  databases?: string[] | null;
};
export type VoloSaasHostDtosEditionLookupDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  displayName?: string | null;
};
export type VoloAbpTextTemplateManagementTextTemplatesTextTemplateContentDto = {
  name?: string | null;
  cultureName?: string | null;
  content?: string | null;
};
export type VoloAbpTextTemplateManagementTextTemplatesUpdateTemplateContentInput = {
  templateName: string;
  cultureName?: string | null;
  content?: string | null;
};
export type VoloAbpTextTemplateManagementTextTemplatesRestoreTemplateContentInput = {
  templateName: string;
  cultureName?: string | null;
};
export type VoloAbpTextTemplateManagementTextTemplatesTemplateDefinitionDto = {
  name?: string | null;
  displayName?: string | null;
  isLayout?: boolean;
  layout?: string | null;
  isInlineLocalized?: boolean;
  defaultCultureName?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpTextTemplateManagementTextTemplatesTemplateDefinitionDto20VoloAbpTextTemplateManagementApplicationContracts20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpTextTemplateManagementTextTemplatesTemplateDefinitionDto[] | null;
    totalCount?: number;
  };
export type VoloAbpApplicationDtosListResultDto601VoloAbpNameValue20VoloAbpCore20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpNameValue[] | null;
  };
export type VoloAbpIdentityIdentityUserUpdateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpIdentityIdentityUserCreateDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  name?: string | null;
  surname?: string | null;
  email: string;
  phoneNumber?: string | null;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  roleNames?: string[] | null;
  organizationUnitIds?: string[] | null;
  password: string;
  sendConfirmationEmail?: boolean;
};
export type VoloAbpIdentityIdentityUserUpdateRolesDto = {
  roleNames: string[];
};
export type VoloAbpIdentityIdentityUserClaimDto = {
  userId?: string;
  claimType?: string | null;
  claimValue?: string | null;
};
export type VoloAbpIdentityOrganizationUnitRoleDto = {
  creationTime?: string;
  creatorId?: string | null;
  organizationUnitId?: string;
  roleId?: string;
};
export type VoloAbpIdentityOrganizationUnitDto = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  parentId?: string | null;
  code?: string | null;
  displayName?: string | null;
  roles?: VoloAbpIdentityOrganizationUnitRoleDto[] | null;
};
export type VoloAbpIdentityIdentityUserUpdatePasswordInput = {
  newPassword: string;
};
export type VoloAbpIdentityIdentityRoleLookupDto = {
  id?: string;
  name?: string | null;
};
export type VoloAbpIdentityOrganizationUnitLookupDto = {
  id?: string;
  displayName?: string | null;
};
export type VoloAbpIdentityExternalLoginProviderDto = {
  name?: string | null;
  canObtainUserInfoWithoutPassword?: boolean;
};
export type VoloAbpIdentityImportExternalUserInput = {
  provider: string;
  userNameOrEmailAddress: string;
  password?: string | null;
};
export type Activ8UsersShareUserWithAnotherPartnerRequest = {
  sourceIdentityUserId?: string;
  sourceTenantId?: string;
  destinationTenantId?: string;
  destinationUserRole?: string | null;
};
export type Activ8UsersUserLinkageInfo = {
  user?: VoloAbpIdentityIdentityUserDto;
  tenant?: VoloSaasHostDtosSaasTenantDto;
};
export type VoloAbpUsersUserData = {
  id?: string;
  tenantId?: string | null;
  userName?: string | null;
  name?: string | null;
  surname?: string | null;
  email?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpUsersUserData20VoloAbpUsersAbstractions20Version532020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpUsersUserData[] | null;
  };
export const {
  useAbpApiDefinitionGetQuery,
  useAbpApplicationConfigurationGetQuery,
  useAbpTenantFindTenantByNameQuery,
  useAbpTenantFindTenantByIdQuery,
  useAccountSettingsGetQuery,
  useAccountSettingsUpdateMutation,
  useAccountSettingsGetTwoFactorQuery,
  useAccountSettingsUpdateTwoFactorMutation,
  useAccountSettingsGetRecaptchaQuery,
  useAccountSettingsUpdateRecaptchaMutation,
  useAccountSettingsGetExternalProviderQuery,
  useAccountSettingsUpdateExternalProviderMutation,
  useActiveCampaignIntegrationGetSettingsQuery,
  useActiveCampaignIntegrationSetSettingsMutation,
  useApiIntegrationCompleteProgramExpressionOfInterestRequestMutation,
  useApiIntegrationGetPartnerInfoQuery,
  useApiIntegrationListActiveProgramsMutation,
  useApiIntegrationGetIntegrationSettingsQuery,
  useApiResourcesGetListQuery,
  useApiResourcesCreateMutation,
  useApiResourcesDeleteMutation,
  useApiResourcesGetAllListQuery,
  useApiResourcesGetQuery,
  useApiResourcesUpdateMutation,
  useApiScopesGetListQuery,
  useApiScopesCreateMutation,
  useApiScopesDeleteMutation,
  useApiScopesGetAllListQuery,
  useApiScopesGetQuery,
  useApiScopesUpdateMutation,
  useAuditLogsGetListQuery,
  useAuditLogsGetQuery,
  useAuditLogsGetErrorRateQuery,
  useAuditLogsGetAverageExecutionDurationPerDayQuery,
  useAuditLogsGetEntityChangesQuery,
  useAuditLogsGetEntityChangesWithUsernameQuery,
  useAuditLogsGetEntityChangeWithUsernameQuery,
  useAuditLogsGetEntityChangeQuery,
  useIdentityClaimTypeGetListQuery,
  useIdentityClaimTypeCreateMutation,
  useIdentityClaimTypeGetQuery,
  useIdentityClaimTypeUpdateMutation,
  useIdentityClaimTypeDeleteMutation,
  useIdentityServerClaimTypesGetListQuery,
  useClientsGetListQuery,
  useClientsCreateMutation,
  useClientsDeleteMutation,
  useClientsGetQuery,
  useClientsUpdateMutation,
  useDataExportTriggerUserExportEmailMutation,
  useDataExportTriggerFacilitatorExportEmailMutation,
  useDataExportTriggerParticipantExportEmailMutation,
  useDataExportTriggerCourseTemplateExportEmailMutation,
  useDataExportTriggerCourseProgramExportEmailMutation,
  useDataExportTriggerCourseProgramParticipantExportEmailMutation,
  useDataImportTriggerParticipantImportMutation,
  useDataImportPrepareImportFileWriteMutation,
  useEditionGetQuery,
  useEditionUpdateMutation,
  useEditionDeleteMutation,
  useEditionGetListQuery,
  useEditionCreateMutation,
  useEditionGetUsageStatisticsQuery,
  useEditionGetPlanLookupQuery,
  useSubscriptionCreateSubscriptionMutation,
  useEmailSettingsGetQuery,
  useEmailSettingsUpdateMutation,
  useFacilitatorCreateMutation,
  useFacilitatorGetListQuery,
  useFacilitatorUpdateMutation,
  useFacilitatorGetQuery,
  useFacilitatorDeleteMutation,
  useFeaturesGetQuery,
  useFeaturesUpdateMutation,
  useIdentityResourcesGetListQuery,
  useIdentityResourcesCreateMutation,
  useIdentityResourcesDeleteMutation,
  useIdentityResourcesGetAllListQuery,
  useIdentityResourcesGetQuery,
  useIdentityResourcesUpdateMutation,
  useIdentityResourcesCreateStandardResourcesMutation,
  useLanguageGetAllListQuery,
  useLanguageGetListQuery,
  useLanguageCreateMutation,
  useLanguageGetQuery,
  useLanguageUpdateMutation,
  useLanguageDeleteMutation,
  useLanguageSetAsDefaultMutation,
  useLanguageGetResourcesQuery,
  useLanguageGetCulturelistQuery,
  useLanguageGetFlagListQuery,
  useLanguageTextGetListQuery,
  useLanguageTextGetQuery,
  useLanguageTextUpdateMutation,
  useLanguageTextRestoreToDefaultMutation,
  useLearningCourseProgramUpdateMutation,
  useLearningCourseProgramGetQuery,
  useLearningCourseProgramDeleteMutation,
  useLearningCourseProgramCreateMutation,
  useLearningCourseProgramGetListQuery,
  useLearningCourseProgramSetProgramStatusMutation,
  useLearningCourseProgramCloneCourseProgramMutation,
  useLearningCourseProgramAssignParticipantToProgramMutation,
  useLearningCourseProgramUnassignParticipantToProgramMutation,
  useLearningCourseProgramGenerateUrlForDownloadCourseContentQuery,
  useLearningCourseProgramFacilitatorGetQuery,
  useLearningCourseProgramFacilitatorUpdateMutation,
  useLearningCourseProgramFacilitatorDeleteMutation,
  useLearningCourseProgramFacilitatorGetListQuery,
  useLearningCourseProgramFacilitatorCreateMutation,
  useLearningCourseProgramParticipantGetListQuery,
  useLearningCourseProgramParticipantCreateMutation,
  useLearningCourseProgramParticipantGetQuery,
  useLearningCourseProgramParticipantUpdateMutation,
  useLearningCourseProgramParticipantDeleteMutation,
  useLearningCourseProgramParticipantGetByProgramAndParticipantQuery,
  useLearningCourseProgramSessionPrepareSessionContentQuery,
  useLearningCourseProgramSessionProgressThroughSessionContentMutation,
  useLearningCourseProgramSessionProgressThroughSessionContentBatchMutation,
  useLearningCourseTemplateSetTemplateStatusMutation,
  useLearningCourseTemplateGetRoleNamesApplicableToCourseAssignmentsQuery,
  useLearningCourseTemplateDeleteMutation,
  useLearningCourseTemplateGetQuery,
  useLearningCourseTemplateUpdateMutation,
  useLearningCourseTemplateCloneCourseTemplateMutation,
  useLearningCourseTemplateGetTemplatePartnerLicensingQuery,
  useLearningCourseTemplatePrepareResourceWriteMutation,
  useLearningCourseTemplatePrepareResourceReadQuery,
  useLearningCourseTemplateCreateMutation,
  useLearningCourseTemplateGetListQuery,
  useLearningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplateQuery,
  useLearningCourseTemplateGetTemplateContentQuery,
  useLearningCourseTemplateGenerateUrlForDownloadCourseContentQuery,
  useLeptonThemeSettingsGetQuery,
  useLeptonThemeSettingsUpdateMutation,
  useOrganizationUnitAddRolesMutation,
  useOrganizationUnitGetRolesQuery,
  useOrganizationUnitAddMembersMutation,
  useOrganizationUnitGetMembersQuery,
  useOrganizationUnitCreateMutation,
  useOrganizationUnitDeleteMutation,
  useOrganizationUnitGetListQuery,
  useOrganizationUnitGetQuery,
  useOrganizationUnitUpdateMutation,
  useOrganizationUnitGetListAllQuery,
  useOrganizationUnitMoveMutation,
  useOrganizationUnitGetAvailableUsersQuery,
  useOrganizationUnitGetAvailableRolesQuery,
  useOrganizationUnitRemoveMemberMutation,
  useOrganizationUnitRemoveRoleMutation,
  useParticipantCreateMutation,
  useParticipantGetListQuery,
  useParticipantUpdateMutation,
  useParticipantGetQuery,
  useParticipantDeleteMutation,
  useParticipantRemoveIdentityUserLinkMutation,
  useParticipantMaintainIdentityUserLinksMutation,
  usePartnerAppNotificationGetAllQuery,
  usePartnerDashboardGetQuery,
  usePartnerManagementEnsurePartnerSetupMutation,
  usePartnerProfileGetQuery,
  usePartnerProfileSetMutation,
  usePermissionsGetQuery,
  usePermissionsUpdateMutation,
  usePersonAccreditationGetAssignmentsForUserQuery,
  usePersonAccreditationSetAssignmentsForUserMutation,
  usePersonAccreditationGetListQuery,
  usePersonAccreditationCreateMutation,
  usePersonAccreditationUpdateMutation,
  usePersonAccreditationDeleteMutation,
  usePersonAccreditationGetQuery,
  usePersonAttributeGetAssignmentsForUserQuery,
  usePersonAttributeSetAssignmentsForUserMutation,
  usePersonAttributeGetListQuery,
  usePersonAttributeCreateMutation,
  usePersonAttributeUpdateMutation,
  usePersonAttributeDeleteMutation,
  usePersonAttributeGetQuery,
  useIdentityRoleGetQuery,
  useIdentityRoleUpdateMutation,
  useIdentityRoleDeleteMutation,
  useIdentityRoleCreateMutation,
  useIdentityRoleGetListQuery,
  useIdentityRoleGetAllListQuery,
  useIdentityRoleUpdateClaimsMutation,
  useIdentityRoleGetClaimsQuery,
  useIdentityRoleGetAllClaimTypesQuery,
  useIdentitySecurityLogGetListQuery,
  useIdentitySecurityLogGetQuery,
  useIdentitySecurityLogGetMyListQuery,
  useIdentitySecurityLogGetMyQuery,
  useIdentitySettingsGetQuery,
  useIdentitySettingsUpdateMutation,
  useIdentitySettingsGetLdapQuery,
  useIdentitySettingsUpdateLdapMutation,
  useIdentitySettingsGetOAuthQuery,
  useIdentitySettingsUpdateOAuthMutation,
  useTenantGetQuery,
  useTenantUpdateMutation,
  useTenantDeleteMutation,
  useTenantGetListQuery,
  useTenantCreateMutation,
  useTenantGetDatabasesQuery,
  useTenantGetConnectionStringsQuery,
  useTenantUpdateConnectionStringsMutation,
  useTenantApplyDatabaseMigrationsMutation,
  useTenantGetEditionLookupQuery,
  useTemplateContentGetQuery,
  useTemplateContentUpdateMutation,
  useTemplateContentRestoreToDefaultMutation,
  useTemplateDefinitionGetListQuery,
  useTemplateDefinitionGetQuery,
  useTimezoneLookupGetAllIanaQuery,
  useTimezoneLookupGetAllWindowsQuery,
  useIdentityUserGetQuery,
  useIdentityUserUpdateMutation,
  useIdentityUserDeleteMutation,
  useIdentityUserGetListQuery,
  useIdentityUserCreateMutation,
  useIdentityUserGetRolesQuery,
  useIdentityUserUpdateRolesMutation,
  useIdentityUserGetAssignableRolesQuery,
  useIdentityUserGetAvailableOrganizationUnitsQuery,
  useIdentityUserGetAllClaimTypesQuery,
  useIdentityUserGetClaimsQuery,
  useIdentityUserUpdateClaimsMutation,
  useIdentityUserGetOrganizationUnitsQuery,
  useIdentityUserLockMutation,
  useIdentityUserUnlockMutation,
  useIdentityUserFindByUsernameQuery,
  useIdentityUserFindByEmailQuery,
  useIdentityUserGetTwoFactorEnabledQuery,
  useIdentityUserSetTwoFactorEnabledMutation,
  useIdentityUserUpdatePasswordMutation,
  useIdentityUserGetRoleLookupQuery,
  useIdentityUserGetOrganizationUnitLookupQuery,
  useIdentityUserGetExternalLoginProvidersQuery,
  useIdentityUserImportExternalUserMutation,
  useUserLinkageShareUserWithAnotherTenantMutation,
  useUserLinkageGetAllUserLinkagesQuery,
  useIdentityUserLookupFindByIdQuery,
  useIdentityUserLookupFindByUserNameQuery,
  useIdentityUserLookupSearchQuery,
  useIdentityUserLookupGetCountQuery,
} = injectedRtkApi;
