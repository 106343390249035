import { sentenceCase } from 'change-case';
import { useState } from 'react';
import {
    Activ8CoursesTemplatesLearningCourseTemplateDto,
    Activ8CoursesTemplatesLearningCourseTemplateStatus
} from 'src/api/redux/app/appApi';
import { fDate, fDateTime, fToNow } from 'src/utils/formatTime';

// @mui
import {
    Button, Checkbox, Chip, MenuItem, TableCell, TableRow, Tooltip, Typography
} from '@mui/material';

// components
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import {
    LearningCourseTemplateActions, LearningCourseTemplateActionsProps
} from '../LearningCourseTemplateActions';

// ----------------------------------------------------------------------

interface Props {
  row: Activ8CoursesTemplatesLearningCourseTemplateDto;
  selected: boolean;
  hideRole?: boolean;
  onEditRow: VoidFunction;
  onOverviewRow?: VoidFunction;
  onSelectRow: VoidFunction;
  onDataRefetch: VoidFunction;
  handleDownloadContent: VoidFunction;
};

export default function LearningCourseTemplateTableRow({
  row,
  selected,
  hideRole,
  onEditRow,
  onOverviewRow,
  onSelectRow,
  onDataRefetch,
  handleDownloadContent
}: Props) {
  const { internalName, status, creationTime, lastModificationTime, enrollmentAllowedForIdentityRoleName, courseReferenceCode, id } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  let statusIcon: string = '';
  let statusIconColor: string = '';
  switch (status) {
    case 'Live':
      statusIcon = 'eva:checkmark-circle-fill';
      statusIconColor = 'success.main';
      break;
    case 'Draft':
      statusIcon = 'eva:edit-outline';
      statusIconColor = 'info.main';
      break;
    case 'Paused':
      statusIcon = 'eva:pause-circle-outline';
      statusIconColor = 'error.main';
      break;
  }

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell align="left">  
        <Button onClick={async () => { onOverviewRow ? await onOverviewRow() : await onEditRow() }} variant={'text'}><Typography variant='body2' sx={{maxWidth: '260px'}} noWrap>{internalName}</Typography></Button>
      </TableCell>

      <TableCell align="left">{courseReferenceCode}</TableCell>

      <Tooltip title={status as string} arrow>
        <TableCell align="center">
          <Iconify
            icon={statusIcon}
            sx={{
              width: 20,
              height: 20,
              color: statusIconColor
            }}
          />
        </TableCell>
      </Tooltip>

      <TableCell align="left">{sentenceCase(enrollmentAllowedForIdentityRoleName || '')}</TableCell>
      {/* <Tooltip arrow title={fDateTime(creationTime || new Date())}><TableCell align="left"><>{fToNow(creationTime || new Date())}</></TableCell></Tooltip>
      <Tooltip arrow title={fDateTime(lastModificationTime || new Date())}><TableCell align="left"><>{fToNow(lastModificationTime || new Date())}</></TableCell></Tooltip> */}

      <TableCell align="left"><>{fDateTime(creationTime || new Date())}</></TableCell>
      <TableCell align="left"><>{fDateTime(lastModificationTime || new Date())}</></TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {/* {onOverviewRow ?
                <MenuItem
                  onClick={() => {
                    onOverviewRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:file-text-fill'} />
                  Overview
                </MenuItem>
                : undefined} */}

              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>

              <LearningCourseTemplateActions row={row} handleCloseMenu={handleCloseMenu} onDataRefetch={onDataRefetch} handleDownloadContent={handleDownloadContent} />
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
