import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserOption } from 'src/@types/user';
import {
  Activ8CoursesContentLearningCourseContentChapter,
  Activ8CoursesContentLearningCourseContentChapterSession,
  Activ8CoursesProgramsCoursesLearningCourseProgramDto,
  Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto,
  Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto,
  Activ8CoursesTemplatesLearningCourseTemplateDto, appApi,
  Activ8CoursesContentQuizResultsLearningCourseContentQuizResult
} from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import RHFUserMultiSelect from 'src/components/hook-form/RHFUserMultiSelect';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useAppUserContext from 'src/hooks/useAppUserContext';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import UserDetailsCard from 'src/sections/@dashboard/user/cards/UserDetailsCard';
import { fDate } from 'src/utils/formatTime';
import { mapIdentityUser } from 'src/utils/mapUser';

import {
  Alert, Box, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Grid, Stack, Tab, Tabs, Tooltip, Typography, useTheme
} from '@mui/material';

import { LearningCourseProgramChapterItem } from './LearningCourseProgramChapterItem';
import LearningCourseProgramDetailsCard from './LearningCourseProgramDetailsCard';
import {
  LearningCourseProgramFacilitatorPreRequisiteDetails
} from './LearningCourseProgramFacilitatorPreRequisiteDetails';
import {
  LearningCourseProgramParticipantPreRequisiteDetails
} from './LearningCourseProgramParticipantPreRequisiteDetails';
import {
  LearningCourseProgramSessionContentViewWizard
} from './sessionContentView/LearningCourseProgramSessionContentViewWizard';

export type LearningCourseProgramSessionContentViewWizardFormData = {
  assignFacilitatorIds: UserOption[];
  assignParticipantIds: UserOption[];
  assignedParticipantSearchText: string;
  quizQuestionSelectedOptions: string[];
};

type Props = {
  template: Activ8CoursesTemplatesLearningCourseTemplateDto;
  program: Activ8CoursesProgramsCoursesLearningCourseProgramDto;
  onDataRefetch: VoidFunction;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`program-tabpanel-${index}`}
      aria-labelledby={`program-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function LearningCourseProgramOverviewDashboard({ template, program, onDataRefetch }: Props) {
  const appUserContext = useAppUserContext();
  const getCourseTemplateContent = appApi.endpoints.learningCourseTemplateGetTemplateContent.useQuery({ courseTemplateId: template.id as string });
  const getParticipantProgramAssignmentQuery = (appUserContext?.currentUser?.roles || []).indexOf('participant') > -1 ? appApi.endpoints.learningCourseProgramParticipantGetByProgramAndParticipant.useQuery({ programId: program.id as string, participantId: appUserContext?.currentUser?.id as string }) : undefined;
  const [getProgramParticipants, getProgramParticipantsState] = appApi.endpoints.learningCourseProgramParticipantGetList.useLazyQuery();
  const [learningCourseProgramFacilitatorDeletePost, learningCourseProgramFacilitatorDeletePostStatus] = appApi.endpoints.learningCourseProgramFacilitatorDelete.useMutation();
  const [learningCourseProgramFacilitatorCreatePost, learningCourseProgramFacilitatorCreatePostStatus] = appApi.endpoints.learningCourseProgramFacilitatorCreate.useMutation();
  const [learningCourseProgramParticipantDeletePost, learningCourseProgramParticipantDeletePostStatus] = appApi.endpoints.learningCourseProgramParticipantDelete.useMutation();
  const [learningCourseProgramParticipantCreatePost, learningCourseProgramParticipantCreatePostStatus] = appApi.endpoints.learningCourseProgramParticipantCreate.useMutation();
  const theme = useTheme();
  const roleQuery = appApi.endpoints.identityRoleGetAllList.useQuery();
  const permissionChecker = usePermissionChecker();
  const canManageFacilitators = permissionChecker.has(permissionKeys.tenant.program.manageFacilitators);
  const canManageParticipants = permissionChecker.has(permissionKeys.tenant.program.manageParticipants);
  const isFacilitatorOfProgram = program.facilitators && Boolean(program.facilitators.find(e => e.facilitatorIdentityUserId === appUserContext?.currentUser?.id));
  const canViewParticipants = isFacilitatorOfProgram || canManageParticipants;
  const canCreateUpdateDelete = permissionChecker.has(permissionKeys.tenant.program.createUpdateDelete);
  const canDownloadCourseContent = permissionChecker.has(permissionKeys.tenant.program.downloadContent) && (isFacilitatorOfProgram || canCreateUpdateDelete);

  const [getCourseContentPackageDownloadUrlQuery] = appApi.endpoints.learningCourseProgramGenerateUrlForDownloadCourseContent.useLazyQuery();

  const [contentChapterForWizard, setContentChapterForWizard] = useState<Activ8CoursesContentLearningCourseContentChapter | undefined>(undefined);
  const [contentChapterIndexForWizard, setContentChapterIndexForWizard] = useState<number | undefined>(undefined);
  const [contentSessionForWizard, setContentSessionForWizard] = useState<Activ8CoursesContentLearningCourseContentChapterSession | undefined>(undefined);
  const [completedQuizzes, setCompletedQuizzes] = useState<Activ8CoursesContentQuizResultsLearningCourseContentQuizResult[]>([]);
  const [contentSessionIndexForWizard, setContentSessionIndexForWizard] = useState<number | undefined>(undefined);
  const [contentSessionWizardIsShown, setContentSessionWizardIsShown] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<LearningCourseProgramSessionContentViewWizardFormData>({
    defaultValues: {
      assignFacilitatorIds: [],
      assignParticipantIds: [],
      assignedParticipantSearchText: '',
      quizQuestionSelectedOptions: []
    }
  });

  const handleDownloadContent = async () => {
    try {
      const downloadQueryResponse = await getCourseContentPackageDownloadUrlQuery({ courseProgramId: program?.id as string }).unwrap();
      window.open((downloadQueryResponse?.blobReference || '') + (downloadQueryResponse?.blobToken || ''));
    }
    catch (err) {
      handleError(err);
    }
  };

  const assignFacilitatorIdsWatch = methods.watch('assignFacilitatorIds');
  const assignParticipantIdsWatch = methods.watch('assignParticipantIds');
  const assignedParticipantSearchTextWatch = methods.watch('assignedParticipantSearchText');

  const confirm = useConfirm();
  const { handleError } = useApiResponseHandler();
  const [selectedTab, setSelectedTab] = useState(0);
  const isLoading = !(learningCourseProgramFacilitatorDeletePostStatus.isLoading || learningCourseProgramFacilitatorCreatePostStatus.isLoading || learningCourseProgramParticipantDeletePostStatus.isLoading || learningCourseProgramParticipantCreatePostStatus.isLoading)
    && (getCourseTemplateContent.isLoading || roleQuery.isLoading || getProgramParticipantsState.isLoading || getParticipantProgramAssignmentQuery?.isLoading);

  const [shownModalType, setShownModalType] = useState<null | 'facilitatorAssign' | 'participantAssign'>(null);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const onRefresh = () => {
    onDataRefetch();
    fetchParticipants();
    getParticipantProgramAssignmentQuery?.refetch();
  }

  useEffect(() => {
    fetchParticipants();
  }, [assignedParticipantSearchTextWatch])

  const fetchParticipants = async () => {
    getProgramParticipants({ learningCourseProgramIds: [program.id as string], maxResultCount: 100, filterText: assignedParticipantSearchTextWatch, includeParticipantDetails: true });
  }

  const unassignFacilitator = (assignment: Activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentDto) => {
    confirm({
      title: `Are you sure you want to unassign ${assignment.facilitatorIdentityUser?.name}?`,
      description:
        <Stack gap={3}>
          <Typography variant='body1'>{`This will remove ${assignment.facilitatorIdentityUser?.name} ${assignment.facilitatorIdentityUser?.surname} as a trainer of this course`}</Typography>
          <Alert severity='warning'>{assignment.facilitatorIdentityUser?.name} will be sent an email notifying them that they have been unassigned from the program.</Alert>
        </Stack>
    })
      .then(async () => {
        try {
          await learningCourseProgramFacilitatorDeletePost({
            id: assignment.id as string
          }).unwrap();
          enqueueSnackbar(`${assignment.facilitatorIdentityUser?.name} removed from program!`);
        }
        catch (error) {
          handleError(error);
        }
        onRefresh();
      });
  }

  const unassignParticipant = (assignment: Activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentDto) => {
    confirm({
      title: `Are you sure you want to unassign ${assignment.participantIdentityUser?.name}?`,
      description:
        <Stack gap={3}>
          <Typography variant='body1'>{`This will remove ${assignment.participantIdentityUser?.name} ${assignment.participantIdentityUser?.surname} as a participant of this course`}</Typography>
          <Alert severity='warning'>{assignment.participantIdentityUser?.name} will be sent an email notifying them that they have been unassigned from the program.</Alert>
        </Stack>
    })
      .then(async () => {
        try {
          await learningCourseProgramParticipantDeletePost({
            id: assignment.id as string
          }).unwrap();
          enqueueSnackbar(`${assignment.participantIdentityUser?.name} removed from program!`);
        }
        catch (error) {
          handleError(error);
        }
        onRefresh();
      });
  }

  const assignFacilitators = () => {
    if (!assignFacilitatorIdsWatch || assignFacilitatorIdsWatch.length === 0) return;

    confirm({ description: `Are you sure you want to assign the facilitator${assignFacilitatorIdsWatch.length > 1 ? 's' : ''}?` })
      .then(async () => {

        let errored = false;
        for (let index = 0; index < assignFacilitatorIdsWatch.length; index++) {
          const f = assignFacilitatorIdsWatch[index];
          try {
            await learningCourseProgramFacilitatorCreatePost({
              activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentCreateDto: {
                learningCourseProgramId: program.id,
                facilitatorIdentityUserId: f.id
              }
            }).unwrap();
            enqueueSnackbar(`${f.label} assigned to program!`);
          }
          catch (error) {
            errored = true;
            handleError(error, f.label);
          }
        }
        if (!errored) {
          setShownModalType(null);
          methods.setValue('assignFacilitatorIds', []);
        }
        onRefresh();
      });
  }

  const assignParticipants = () => {
    if (!assignParticipantIdsWatch || assignParticipantIdsWatch.length === 0) return;

    confirm({ description: `Are you sure you want to assign the participant${assignParticipantIdsWatch.length > 1 ? 's' : ''}?` })
      .then(async () => {

        let errored = false;
        for (let index = 0; index < assignParticipantIdsWatch.length; index++) {
          const f = assignParticipantIdsWatch[index];
          try {
            await learningCourseProgramParticipantCreatePost({
              activ8CoursesProgramsParticipantsParticipantToLearningCourseProgramAssignmentCreateDto: {
                learningCourseProgramId: program.id,
                participantIdentityUserId: f.id
              }
            }).unwrap();
            enqueueSnackbar(`${f.label} assigned to program!`);
          }
          catch (error) {
            errored = true;
            handleError(error, f.label);
          }
        }
        if (!errored) {
          setShownModalType(null);
          methods.setValue('assignParticipantIds', []);
        }
        onRefresh();
      });
  }

  const participantProgression = getParticipantProgramAssignmentQuery ? getParticipantProgramAssignmentQuery.data?.progression : undefined;

  useEffect(() => {
    // console.log('heree participantProgression', participantProgression);
    setCompletedQuizzes(participantProgression && participantProgression.sessions && participantProgression.sessions.length > 0 ? (participantProgression.sessions.flatMap(s => s.quizResults).filter(e => e && e?.isCompleted === true) || []) as Activ8CoursesContentQuizResultsLearningCourseContentQuizResult[] : []);
  }, [participantProgression])

  let sessionContentViewWizard: ReactNode | undefined = undefined;

  const triggerShowSessionContentViewWizard = (chapterId: string, sessionId: string) => {
    const contentChapter = (getCourseTemplateContent?.data?.chapters || []).find(c => c.id === chapterId) as Activ8CoursesContentLearningCourseContentChapter;
    const contentChapterIndex = (getCourseTemplateContent?.data?.chapters || []).indexOf(contentChapter);
    const contentSession = (contentChapter.sessions || []).find(c => c.id === sessionId) as Activ8CoursesContentLearningCourseContentChapterSession;
    const contentSessionIndex = (contentChapter.sessions || []).indexOf(contentSession);

    setContentChapterForWizard(contentChapter);
    setContentChapterIndexForWizard(contentChapterIndex);
    setContentSessionForWizard(contentSession);
    setContentSessionIndexForWizard(contentSessionIndex);
    setContentSessionWizardIsShown(true);
  };

  const triggerCloseSessionContentViewWizard = () => {

    setContentChapterForWizard(undefined);
    setContentChapterIndexForWizard(undefined);
    setContentSessionForWizard(undefined);
    setContentSessionIndexForWizard(undefined);
    setContentSessionWizardIsShown(false);

    onRefresh();
  }

  return (
    isLoading ?
      <LoadingScreen />
      :
      <FormProvider methods={methods}>
        {
          contentSessionWizardIsShown && contentChapterIndexForWizard !== undefined && contentSessionIndexForWizard !== undefined && contentSessionForWizard && contentChapterForWizard ?
            <LearningCourseProgramSessionContentViewWizard
              courseProgramId={program.id as string}
              chapterIndex={contentChapterIndexForWizard}
              sessionIndex={contentSessionIndexForWizard}
              programName={program.publicName as string}
              contentSession={contentSessionForWizard}
              contentChapter={contentChapterForWizard}
              onClose={triggerCloseSessionContentViewWizard}
              initialQuizResults={completedQuizzes}
            />
            : undefined
        }
        {
          //   <div>
          //   <div>
          //     <code style={{ whiteSpace: 'pre' }}>
          //       {JSON.stringify(participantProgression, null, 2)}
          //     </code>
          //   </div>
          //   <div>
          //     <code style={{ whiteSpace: 'pre' }}>
          //       {JSON.stringify(completedQuizzes, null, 2)}
          //     </code>
          //   </div>
          // </div>
        }
        {sessionContentViewWizard}
        <Dialog
          open={Boolean(shownModalType)}
          onClose={() => { setShownModalType(null); methods.setValue('assignParticipantIds', []) }}
        >
          <>
            {
              shownModalType === 'participantAssign' ?
                <>
                  <DialogTitle>Assign participant to program</DialogTitle>
                  <DialogContent>
                    <DialogContentText sx={{ mb: 3 }}>
                      <LearningCourseProgramParticipantPreRequisiteDetails compact courseTemplate={template} />
                    </DialogContentText>
                    <RHFUserMultiSelect
                      name="assignParticipantIds"
                      userTypes={['participants']}
                      placeholder='Search for a qualified participant...'
                      noOptionsText='No qualified participants were found'
                      requiredAttributeIds={template.attributePreRequisites?.filter(a => a.type == 'Participant').map(a => a.personAttributeId as string) || []}
                      requiredAccreditationIds={template.accreditationPreRequisites?.filter(a => a.type == 'Participant').map(a => a.personAccreditationId as string) || []}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => { setShownModalType(null); methods.setValue('assignParticipantIds', []) }}>Cancel</Button>
                    <Button disabled={!assignParticipantIdsWatch || assignParticipantIdsWatch.length == 0} onClick={() => assignParticipants()}>Assign</Button>
                  </DialogActions>
                </> : undefined
            }
            {
              shownModalType === 'facilitatorAssign' ?
                <>
                  <DialogTitle>Assign facilitator to program</DialogTitle>
                  <DialogContent>
                    <DialogContentText sx={{ mb: 3 }}>
                      <LearningCourseProgramFacilitatorPreRequisiteDetails compact courseTemplate={template} />
                    </DialogContentText>
                    <RHFUserMultiSelect
                      name="assignFacilitatorIds"
                      userTypes={['facilitators']}
                      placeholder='Search for a qualified facilitator...'
                      noOptionsText='No qualified facilitators were found'
                      requiredAttributeIds={template.attributePreRequisites?.filter(a => a.type == 'Facilitator').map(a => a.personAttributeId as string) || []}
                      requiredAccreditationIds={template.accreditationPreRequisites?.filter(a => a.type == 'Facilitator').map(a => a.personAccreditationId as string) || []}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => { setShownModalType(null); methods.setValue('assignFacilitatorIds', []) }}>Cancel</Button>
                    <Button disabled={!assignFacilitatorIdsWatch || assignFacilitatorIdsWatch.length == 0} onClick={() => assignFacilitators()}>Assign</Button>
                  </DialogActions>
                </> : undefined
            }
          </>
        </Dialog>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4} sx={{ mt: 2 }}>
            <Stack rowGap={1} columnGap={2}>
              <LearningCourseProgramDetailsCard program={program} template={template} />

              {!canDownloadCourseContent ? undefined :
                <Button color='primary' variant='contained' onClick={() => handleDownloadContent()}>Download course content</Button>}

              {canCreateUpdateDelete || canManageParticipants ?
                <>
                  <Stack direction='row' spacing={1} justifyContent='space-between'>
                    {program.statistics?.averageParticipantModulesScorePercentage !== null ?
                      <Tooltip title='Average score for online modules completed by participants'>
                        <Card sx={{ width: '100%', p: 0 }}>
                          <CardContent sx={{ p: 1 }}>
                            <Stack alignItems='center'>
                              <Stack direction='row' justifyContent='space-around'>
                                <Typography sx={{ color: theme.palette.info.main }} variant='h5'>{program.statistics?.averageParticipantModulesScorePercentage?.toFixed(0)}%</Typography>
                                {/* <Iconify sx={{fontSize: 30}} icon='eva:checkmark-fill'></Iconify> */}
                              </Stack>
                              <Typography variant='body2'>Avg. module score</Typography>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Tooltip> : undefined}

                    <Tooltip title='Total views of content by participants'>
                      <Card sx={{ width: '100%', p: 0 }}>
                        <CardContent sx={{ p: 1 }}>
                          <Stack alignItems='center'>
                            <Stack direction='row' justifyContent='space-around'>
                              <Typography sx={{ color: theme.palette.info.main }} variant='h5'>{program.statistics?.totalContentViews}</Typography>
                              {/* <Iconify sx={{fontSize: 30}} icon='eva:checkmark-fill'></Iconify> */}
                            </Stack>
                            <Typography variant='body2'>Content views</Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Tooltip>
                  </Stack>

                  {program.statistics?.averageParticipantModuleCompletionPercentage !== null ?
                    <Stack direction='row' spacing={1} justifyContent='space-between'>
                      <Tooltip title='Percentage of online modules completed by participants'>
                        <Card sx={{ width: '100%', p: 0 }}>
                          <CardContent sx={{ p: 1 }}>
                            <Stack alignItems='center'>
                              <Stack direction='row' justifyContent='space-around'>
                                <Typography sx={{ color: theme.palette.info.main }} variant='h5'>{program.statistics?.averageParticipantModuleCompletionPercentage?.toFixed(0)}%</Typography>
                                {/* <Iconify sx={{fontSize: 30}} icon='eva:checkmark-fill'></Iconify> */}
                              </Stack>
                              <Typography variant='body2'>Modules complete</Typography>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Tooltip>
                    </Stack> : undefined}
                </>
                : undefined}
            </Stack>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={selectedTab} onChange={handleChangeTab} variant="fullWidth" aria-label="Program tabs">
                <Tab label="Modules" id={`program-tab-0`} />
                <Tab label="Facilitators" id={`program-tab-1`} />
                {canViewParticipants ? <Tab label="Participants" id={`program-tab-2`} /> : undefined}
                {canManageParticipants ? <Tab label="Prerequisites" id={`program-tab-3`} /> : undefined}
              </Tabs>
            </Box>
            <TabPanel value={selectedTab} index={0}>
              <Stack direction='column' spacing={1}>
                <Stack direction='column' spacing={{ xs: 4, md: 2 }}>
                  {
                    (getCourseTemplateContent.data?.chapters || []).map((chapter, index) =>
                      <LearningCourseProgramChapterItem key={chapter.id} courseProgram={program} contentChapter={chapter} chapterIndex={index} triggerShowSessionContentViewWizard={triggerShowSessionContentViewWizard} participantProgression={participantProgression} />
                    )
                  }
                </Stack>
              </Stack>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <Grid container spacing={3}>
                {canManageFacilitators ?
                  <Grid item xs={12}>
                    <Stack justifyContent='flex-end' direction='row' alignItems='flex-start'>
                      <Button variant='contained' onClick={() => setShownModalType('facilitatorAssign')}>Assign facilitator</Button>
                    </Stack>
                  </Grid> : undefined}

                {
                  !program.facilitators || program.facilitators.length == 0 ?
                    <Grid item xs={12}>
                      <Alert severity='info' sx={{ width: '100%' }}>
                        No facilitators have been assigned to this program
                      </Alert>
                    </Grid>
                    :
                    (program.facilitators || []).map((fac) =>
                      <Grid key={fac.id} item xs={12} md={6}>
                        <Stack direction='column' spacing={1}>
                          <UserDetailsCard forceType='Facilitator' withName user={mapIdentityUser(fac.facilitatorIdentityUser)}
                            actions={canManageFacilitators ? <>
                              <Button color='error' onClick={() => unassignFacilitator(fac)}>Unassign</Button>
                            </> : undefined}
                            extraDetails={
                              <Typography variant="body2" color="text.secondary">
                                Assigned on {fDate(fac.creationTime || new Date())}
                              </Typography>
                            }
                          />
                        </Stack>
                      </Grid>
                    )
                }
              </Grid>
            </TabPanel>
            {!canViewParticipants ? undefined :
              <TabPanel value={selectedTab} index={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack justifyContent='space-between' direction='row' alignItems='flex-start'>
                      <RHFTextField name='assignedParticipantSearchText' size='small' placeholder='Search assigned participants...' sx={{ width: '50%' }} />
                      {canManageParticipants ? <Button variant='contained' onClick={() => setShownModalType('participantAssign')}>Assign participant</Button> : undefined}
                    </Stack>
                  </Grid>

                  {
                    !getProgramParticipants || !getProgramParticipantsState.data || !getProgramParticipantsState.data.items || getProgramParticipantsState.data.items.length == 0 ?
                      <Grid item xs={12}>
                        <Alert severity='info' sx={{ width: '100%' }}>
                          {assignedParticipantSearchTextWatch ? 'No participants found' : 'No participants have been assigned to this program'}
                        </Alert>
                      </Grid>
                      :
                      (getProgramParticipantsState.data.items || []).map((part) => {
                        return (
                          <Grid key={part.id} item xs={12} md={6}>
                            <Stack direction='column' spacing={1}>
                              <UserDetailsCard forceType='Participant' compact withName user={mapIdentityUser(part.participantIdentityUser)}
                                actions={canManageParticipants ? <Stack direction={'row'} justifyContent='space-between' sx={{ width: '100%' }}>
                                  <Button color='error' onClick={() => unassignParticipant(part)}>Unassign</Button>
                                  {part.assignmentStatus === 'Completed' && part.onlineModuleScorePercentage !== undefined && part.onlineModuleScorePercentage !== null ? <Tooltip title='Online module score'><Chip variant={'filled'} label={`${part.onlineModuleScorePercentage}%`} /></Tooltip> : undefined}
                                  <Tooltip title='Program participant status'><Chip color={part.assignmentStatus !== 'Completed' ? 'warning' : 'success'} variant={part.assignmentStatus !== 'Completed' ? 'filled' : 'outlined'} label={part.assignmentStatus} /></Tooltip>
                                </Stack> : <Chip color={part.assignmentStatus !== 'Completed' ? 'warning' : 'success'} variant={part.assignmentStatus !== 'Completed' ? 'filled' : 'outlined'} label={part.assignmentStatus} />}
                                extraDetails={
                                  <>
                                    <Typography variant="body2" color="text.secondary">
                                      Assigned on {fDate(part.creationTime || new Date())}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      {!part.contentViewCount ? 'No content views' : (part.contentViewCount + ' content view' + ((part.contentViewCount || 0) > 1 ? 's' : ''))}
                                    </Typography>
                                  </>
                                }
                              />
                            </Stack>
                          </Grid>
                        )
                      })
                  }
                </Grid>
              </TabPanel>}

            {!canManageParticipants ? undefined :
              <TabPanel value={selectedTab} index={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack gap={7}>
                      <Stack gap={1}>
                        <Typography variant='h5'>Facilitators</Typography>
                        <LearningCourseProgramFacilitatorPreRequisiteDetails compact courseTemplate={template} />
                      </Stack>

                      <Stack gap={1}>
                        <Typography variant='h5'>Participants</Typography>
                        <LearningCourseProgramParticipantPreRequisiteDetails compact courseTemplate={template} />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </TabPanel>}
          </Grid>
        </Grid>
      </FormProvider>
  )
}