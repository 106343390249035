import { useSnackbar } from 'notistack';
import { User } from 'oidc-client-ts';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import appSettings from 'src/appSettings';

import { Stack, Typography } from '@mui/material';

import setLoggedInUser from './setLoggedInUser';

export function onFreshLogin(user?: User) {
  localStorage.setItem('is_impersonate_refresh_pending', '0');
  localStorage.setItem('impersonate_logged_in_primary_user_token', '');
  localStorage.setItem('impersonate_tenant_id', '');
  localStorage.setItem('impersonate_user_id', '');
  localStorage.setItem('navigate_to_participant_id', '');
  localStorage.setItem('navigate_to_facilitator_id', '');
  localStorage.setItem('impersonate_host_access_token', '');
  localStorage.setItem('impersonate_host_refresh_token', '');
  localStorage.setItem('impersonate_partner_access_token', '');
  localStorage.setItem('impersonate_partner_refresh_token', '');

  if (user) {
    localStorage.setItem('login_user', JSON.stringify(user));
  }
  else {
    localStorage.setItem('login_user', '');
  }
}

function useImpersonation() {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const impersonate = async (tenantId: string | undefined, userId: string | undefined, navigateToParticipantId?: string, navigateToFacilitatorId?: string): Promise<void> => {
    let userAccessToken = auth.user?.access_token as string;
    const principalUserType = Boolean(auth.user?.profile.tenantid) ? 'partner' : 'host';
    console.log('request impersonate', { principalUserType, tenantId, userId });
    //const refreshToken = auth.user?.refresh_token as string;

    let returningToHost = false;
    let transitioningFromHostToPartner = false;
    let transitioningFromHostToPartnerToParticipantOrFacilitator = false;
    let transitioningFromParticipantOrFacilitatorBackToPartnerForHost = false;
    let transitioningFromParticipantOrFacilitatorBackToPartnerForPartner = false;
    let transitioningFromPartnerToParticipantOrFacilitator = false;

    if (!localStorage.getItem('impersonate_logged_in_primary_user_token')) {
      localStorage.setItem('impersonate_logged_in_primary_user_token', userAccessToken);
    }

    if (!Boolean(localStorage.getItem('impersonate_tenant_id')) && tenantId && !userId) {
      transitioningFromHostToPartner = true;
      // transitioning from host tenant to partner impersonation
      console.log('transitioning from host tenant to partner impersonation')
    }
    else if (Boolean(localStorage.getItem('impersonate_tenant_id')) && !tenantId && !userId) {
      returningToHost = true;
      // transitioning from partner impersonation to host
      console.log('transitioning from partner impersonation to host')
    }
    else if (Boolean(localStorage.getItem('impersonate_tenant_id')) && Boolean(localStorage.getItem('impersonate_user_id')) && tenantId && !userId && principalUserType === 'host') {
      transitioningFromParticipantOrFacilitatorBackToPartnerForHost = true;
      console.log('transitioning host principal from participant/facilitator back to partner')
    }
    else if (Boolean(localStorage.getItem('impersonate_tenant_id')) && Boolean(localStorage.getItem('impersonate_user_id')) && tenantId && !userId && principalUserType === 'partner') {
      transitioningFromParticipantOrFacilitatorBackToPartnerForPartner = true;
      console.log('transitioning partner principal from participant/facilitator back to partner')
    }
    else if (Boolean(localStorage.getItem('impersonate_tenant_id')) && !Boolean(localStorage.getItem('impersonate_user_id')) && tenantId && userId) {
      transitioningFromPartnerToParticipantOrFacilitator = true;
      console.log('transitioning from partner to participant/facilitator')
    }
    else if (!Boolean(localStorage.getItem('impersonate_tenant_id')) && !Boolean(localStorage.getItem('impersonate_user_id')) && tenantId && userId) {
      transitioningFromHostToPartnerToParticipantOrFacilitator = true;
      console.log('transitioning from host to partner back to participant/facilitator')
    }

    let accessTokenForImpersonateRequest: string;
    if (transitioningFromParticipantOrFacilitatorBackToPartnerForPartner) {
      const loginPrincipalUser = JSON.parse(localStorage.getItem('login_user') as string) as User;
      onFreshLogin(loginPrincipalUser);
      sessionStorage.setItem(`oidc.user:${appSettings.oAuthAuthority}:${appSettings.oAuthClientId}`, JSON.stringify(loginPrincipalUser));
      // return responseBody;
      await auth.signinSilent();
      return;
    }
    else if (transitioningFromParticipantOrFacilitatorBackToPartnerForHost) {
      // accessTokenForImpersonateRequest = userAccessToken; //localStorage.getItem('impersonate_partner_access_token') as string;
      // accessTokenForImpersonateRequest = localStorage.getItem('impersonate_partner_access_token') as string; //localStorage.getItem('impersonate_partner_access_token') as string;
      // userAccessToken = localStorage.getItem('impersonate_host_access_token') as string; //localStorage.getItem('impersonate_partner_access_token') as string;
      accessTokenForImpersonateRequest = localStorage.getItem('impersonate_logged_in_primary_user_token') as string;
      userAccessToken = localStorage.getItem('impersonate_logged_in_primary_user_token') as string;

      // userAccessToken = localStorage.getItem('impersonate_partner_access_token') as string;
    }
    else if (transitioningFromPartnerToParticipantOrFacilitator) {
      accessTokenForImpersonateRequest = userAccessToken;
    }
    else if (returningToHost) {
      // accessTokenForImpersonateRequest = localStorage.getItem('impersonate_partner_access_token') as string;
      // accessTokenForImpersonateRequest = userAccessToken;

      accessTokenForImpersonateRequest = localStorage.getItem('impersonate_partner_access_token') as string; //localStorage.getItem('impersonate_partner_access_token') as string;
      userAccessToken = localStorage.getItem('impersonate_host_access_token') as string; //localStorage.getItem('impersonate_partner_access_token') as string;
      // userAccessToken = accessTokenForImpersonateRequest;
    }
    else if (transitioningFromHostToPartnerToParticipantOrFacilitator) {
      const details = {
        'access_token': userAccessToken,
        // 'access_token': localStorage.getItem('impersonate_host_access_token'),
        //'access_token': userAccessToken,
        'grant_type': 'Impersonation', // : 'LinkLogin',
        'Client_Id': appSettings.oAuthClientId,
        'Client_Secret': null,
        'TenantId': tenantId,
        'UserId': null
      } as any;

      const response = await makeTokenRequest(details, userAccessToken);

      accessTokenForImpersonateRequest = userAccessToken;
      userAccessToken = (await response.json()).access_token;
    }
    else if (transitioningFromHostToPartner) {
      accessTokenForImpersonateRequest = userAccessToken;
    }
    else {
      enqueueSnackbar(<Stack>
        <Typography variant='body1' gutterBottom>Failed to impersonate</Typography>
        <Typography variant='body2'>Workflow unknown for transition</Typography>
      </Stack>, { variant: 'error' });
      return;
    }

    const details = {
      'access_token': accessTokenForImpersonateRequest,
      // 'access_token': localStorage.getItem('impersonate_host_access_token'),
      //'access_token': userAccessToken,
      'grant_type': 'Impersonation',
      // 'grant_type': 'LinkLogin',
      'Client_Id': appSettings.oAuthClientId,
      'Client_Secret': null,
      'TenantId': returningToHost ? null : (tenantId || null),
      'UserId': returningToHost ? null : (userId || null)
    } as any;

    console.log('heree details impersonate token', details)

    const response = await makeTokenRequest(details, userAccessToken);

    if (!response.ok) {
      const resJson = (await response.json());
      enqueueSnackbar(<Stack>
        <Typography variant='body1' gutterBottom>Failed to impersonate</Typography>
        <Typography variant='body2'>{resJson.error}</Typography>
      </Stack>, { variant: 'error' });

      console.error('Failed to impersonate', resJson, response);
      localStorage.setItem('is_impersonate_refresh_pending', '0');
      localStorage.setItem('navigate_to_participant_id', '');
      localStorage.setItem('navigate_to_facilitator_id', '');

      // if (localStorage.getItem('is_impersonate') !== '1') {
      //   localStorage.setItem('is_impersonate', '0');
      //   localStorage.setItem('impersonate_tenant_id', '');
      //   localStorage.setItem('impersonate_user_id', '');
      // }
    }
    else {
      const responseBody = await response.json();
      if (transitioningFromHostToPartner || transitioningFromHostToPartnerToParticipantOrFacilitator) {
        // transitioning from host tenant to partner impersonation
        const storageUser = JSON.parse(sessionStorage.getItem(`oidc.user:${appSettings.oAuthAuthority}:${appSettings.oAuthClientId}`) as string);
        localStorage.setItem('impersonate_host_access_token', storageUser.access_token);
        localStorage.setItem('impersonate_host_refresh_token', storageUser.refresh_token);
        localStorage.setItem('impersonate_partner_access_token', responseBody.access_token);
        localStorage.setItem('impersonate_partner_refresh_token', responseBody.refresh_token);
      }
      else if (returningToHost) {
        localStorage.setItem('impersonate_host_access_token', '');
        localStorage.setItem('impersonate_host_refresh_token', '');
        localStorage.setItem('impersonate_partner_access_token', '');
        localStorage.setItem('impersonate_partner_refresh_token', '');
      }

      // const newAccessToken = responseBody.access_token;

      localStorage.setItem('is_impersonate_refresh_pending', '1');
      localStorage.setItem('impersonate_tenant_id', tenantId || '');
      localStorage.setItem('impersonate_user_id', userId || '');
      localStorage.setItem('is_impersonate', Boolean(tenantId) || Boolean(userId) ? '1' : '0');

      if (navigateToParticipantId) localStorage.setItem('navigate_to_participant_id', navigateToParticipantId as string);
      if (navigateToFacilitatorId) localStorage.setItem('navigate_to_facilitator_id', navigateToFacilitatorId as string);

      setLoggedInUser(responseBody);

      // return responseBody;
      await auth.signinSilent();
    }
  }

  const resetToPreviousUser = async (): Promise<void> => {
    if (Boolean(localStorage.getItem('impersonate_tenant_id')) && !Boolean(localStorage.getItem('impersonate_user_id'))) {
      // User wants to return to host tenant
      await impersonate(undefined, undefined);
    }
    else if (Boolean(localStorage.getItem('impersonate_tenant_id')) && Boolean(localStorage.getItem('impersonate_user_id'))) {
      // User wants to return from participant/facilitator back to partner
      await impersonate(localStorage.getItem('impersonate_tenant_id') as string, undefined);
    }
  }

  const makeTokenRequest = async (details: any, currentUserAccessToken: string): Promise<any> => {
    const formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    const formBodyStr = formBody.join("&");

    const response = await fetch(appSettings.oAuthAuthority + '/connect/token', {
      method: 'POST',
      headers: {
        // 'Authorization': 'Bearer ' + details.access_token,
        'Authorization': 'Bearer ' + currentUserAccessToken,
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: formBodyStr
    });

    return response;
  }

  return {
    isImpersonatingTenant: Boolean(localStorage.getItem('impersonate_tenant_id')),

    isImpersonatingUser: Boolean(localStorage.getItem('impersonate_user_id')),

    onFreshLogin: onFreshLogin,

    impersonate: impersonate,

    resetToPreviousUser: resetToPreviousUser
  }
}

export default useImpersonation;