// import * as dayjs from 'dayjs';
// import updateLocale from 'dayjs/plugin/updateLocale';
import { ConfirmProvider } from 'material-ui-confirm';

import { MantineProvider } from '@mantine/core';

// components
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/NotistackProvider';
import ScrollToTop from './components/ScrollToTop';
import ThemeSettings from './components/settings';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';

// dayjs.extend(updateLocale)

// dayjs.updateLocale('en', {
//   formats: {
//     // abbreviated format options allowing localization
//     LTS: 'h:mm:ss A',
//     LT: 'h:mm A',
//     L: 'MM/DD/YYYY',
//     LL: 'MMMM D, YYYY',
//     LLL: 'MMMM D, YYYY h:mm A',
//     LLLL: 'dddd, MMMM D, YYYY h:mm A',
//     // lowercase/short, optional formats for localization
//     l: 'D/M/YYYY',
//     ll: 'D MMM, YYYY',
//     lll: 'D MMM, YYYY h:mm A',
//     llll: 'ddd, MMM D, YYYY h:mm A'
//   },
// })
// ----------------------------------------------------------------------

export default function App() {

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <MantineProvider
          // theme={{
          //   components: {
          //     // m-468e7eda m-8fb7ebe7 mantine-Input-input mantine-TimeInput-input
          //     TextInput: {
          //       styles: () => ({
          //         input: {
          //           color: 'red!important'
          //         },
          //         wrapper: {
          //           color: 'red!important'
          //         },
          //         description: {
          //           color: 'red!important'
          //         },
          //         label: {
          //           color: 'red!important'
          //         },
          //         root: {
          //           color: 'red!important'
          //         }
          //       })
          //     }
          //   }
          // }}
          >
            <NotistackProvider>
              <ConfirmProvider>
                <ScrollToTop />
                <Router />
              </ConfirmProvider>
            </NotistackProvider></MantineProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
