import { kebabCase } from 'change-case';
// sections
import { useConfirm } from 'material-ui-confirm';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { appApi } from 'src/api/redux/app/appApi';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import {
  AttributeTableRow, AttributeTableToolbar
} from 'src/sections/master/setup/person/attribute/list';

// @mui
import {
  Alert, Box, Button, Card, Container, IconButton, Stack, Table, TableBody, TableContainer,
  TablePagination, Tooltip, Typography
} from '@mui/material';

// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Iconify from '../../../../components/Iconify';
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import {
  TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions
} from '../../../../components/table';
// hooks
import useSettings from '../../../../hooks/useSettings';
import useTable, { emptyRows } from '../../../../hooks/useTable';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'description', label: 'Name', align: 'left' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function AttributeList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'description'
  });

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const confirm = useConfirm();

  const { handleError } = useApiResponseHandler();

  const [filterName, setFilterName] = useState('');
  const [attributeDeletePost] = appApi.endpoints.personAttributeDelete.useMutation();

  const attributeListQuery = appApi.endpoints.personAttributeGetList.useQuery({
    sorting: (orderBy || 'description') + ' ' + (order || 'asc'),
    skipCount: page * rowsPerPage,
    maxResultCount: rowsPerPage,
    filterText: filterName,
  },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true
    });

  const tableData = attributeListQuery.data?.items || [];

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteRow = async (id: string) => {
    confirm({
      title: 'Delete attribute',
      description:
        <Stack gap={3}>
          <Typography variant='body1'>{`Are you sure you want to delete the attribute?`}</Typography>
          <Alert severity='warning'>Facilitator attribute pre-requisites on course templates will be deleted.</Alert>
          <Alert severity='warning'>Attributes assigned to participants and facilitators will be deleted.</Alert>
        </Stack>
    })
      .then(async () => {
        try {
          await attributeDeletePost({
            id: id
          }).unwrap()
          setSelected([]);
          attributeListQuery.refetch();
        }
        catch (error) {
          handleError(error);
        }
      });
  };

  const handleDeleteRows = (selected: string[]) => {
    confirm({
      title: `Delete attribute${selected.length > 1 ? 's' : ''}`,
      description:
        <Stack gap={3}>
          <Typography variant='body1'>{`Are you sure you want to delete the selected attribute${selected.length > 1 ? 's' : ''}?`}</Typography>
          <Alert severity='warning'>Facilitator attribute pre-requisites on course templates will be deleted.</Alert>
          <Alert severity='warning'>Attributes assigned to participants and facilitators will be deleted.</Alert>
        </Stack>
    })
      .then(async () => {
        try {
          const promises = selected.map(id => attributeDeletePost({
            id: id
          }).unwrap());
          await Promise.all(promises);
          setSelected([]);
          attributeListQuery.refetch();
        }
        catch (error) {
          handleError(error);
        }
      });
  };

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.master.person.attributes.edit(kebabCase(id)));
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !tableData || tableData.length === 0;

  return (
    <Page title="Person attributes">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Person attributes"
          links={[
            { name: 'Manage', href: PATH_DASHBOARD.general.manageSettings },
            { name: 'Attributes' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.master.person.attributes.new}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New attribute
            </Button>
          }
        />

        <Card>
          <AttributeTableToolbar
            filterName={filterName}
            onFilterName={handleFilterName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={attributeListQuery?.data?.totalCount || 0}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      !checked,
                      tableData.map((row) => row.id || '')
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={attributeListQuery?.data?.totalCount || 0}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id || '')
                    )
                  }
                />

                <TableBody>
                  {tableData
                    .map((row) => (
                      <AttributeTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id || '')}
                        onSelectRow={() => onSelectRow(row.id || '')}
                        onDeleteRow={() => handleDeleteRow(row.id || '')}
                        onEditRow={() => handleEditRow(row.id || '')}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={attributeListQuery?.data?.totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            {/* <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            /> */}
          </Box>
        </Card>
      </Container>
    </Page>
  );
}