import { useConfirm } from 'material-ui-confirm';
import * as mime from 'mime-types';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import {
    Activ8CoursesContentLearningCourseContentResource,
    Activ8CoursesProgramsCoursesLearningCourseProgramDto,
    Activ8CoursesTemplatesPrepareResourceOperationResponse, appApi
} from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import RHFUserMultiSelect from 'src/components/hook-form/RHFUserMultiSelect';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useAppUserContext from 'src/hooks/useAppUserContext';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import UserDetailsCard from 'src/sections/@dashboard/user/cards/UserDetailsCard';
import uuidv4 from 'src/utils/uuidv4';

import {
    Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText,
    Stack, Typography
} from '@mui/material';

import { ResourceContentManager } from '../shared/ResourceContentManager';

type Props = {
    assignToProgram?: Activ8CoursesProgramsCoursesLearningCourseProgramDto;
    isOpen: boolean;
    onClose: () => void;
}

export default function ParticipantImportDialog({ assignToProgram, isOpen, onClose }: Props) {
    const [importUploadFileBlobUrl, setImportUploadFileBlobUrl] = useState('');
    const appUserContext = useAppUserContext();
    const [triggerImportPost, triggerImportPostStatus] = appApi.endpoints.dataImportTriggerParticipantImport.useMutation();
    const [prepareImportFileWritePost, prepareImportFileWritePostStatus] = appApi.endpoints.dataImportPrepareImportFileWrite.useMutation();
    // const [downloadSampleImportTemplate, downloadSampleImportTemplateStatus] = appApi.endpoints.dataImportDownloadSampleParticipantImportTemplate.useLazyQuery();
    const { handleError } = useApiResponseHandler();
    const confirm = useConfirm();
    const { enqueueSnackbar } = useSnackbar();

    const isLoading = triggerImportPostStatus.isLoading || prepareImportFileWritePostStatus.isLoading;

    // const downloadSample = async () => {
    //     const result = await downloadSampleImportTemplate().unwrap();
    //     const blob = new Blob(result as any, { type: 'text/csv' });
    //     window.open(URL.createObjectURL(blob));
    // };

    const importParticipants = () => {
        confirm({
            description:
                <Typography variant='body1'>{`Are you sure you want to trigger the import?`}</Typography>
        })
            .then(async () => {
                try {
                    await triggerImportPost({
                        activ8DataDataImportTriggerParticipantDataImportRequest: {
                            importFileBlobUrl: importUploadFileBlobUrl as string,
                            assignToCourseProgramId: assignToProgram?.id
                        }
                    }).unwrap();

                    onClose();
                    enqueueSnackbar('Import triggered!');
                }
                catch (error) {
                    handleError(error);
                }
            });
    }

    const onUploadSuccess = async (resource: Activ8CoursesContentLearningCourseContentResource): Promise<void> => {
        try {
            setImportUploadFileBlobUrl(resource.blobReference as string);
        }
        catch (error) {
            handleError(error);
        }
    }

    const prepareUpload = async (file: File): Promise<{ response: Activ8CoursesTemplatesPrepareResourceOperationResponse | null, fileId: string }> => {
        try {
            const contentType = mime.lookup(file.name) as string;

            if (contentType !== 'text/csv') {
                enqueueSnackbar('Invalid file type. Please select a CSV file.', {
                    variant: 'error'
                });
                return {
                    response: null,
                    fileId: ''
                };
            }

            const fileId = uuidv4();
            const response = await prepareImportFileWritePost({
                contentType: contentType,
                contentFileName: file.name
            }).unwrap();

            return {
                response,
                fileId
            };
        }
        catch (error) {
            handleError(error);
            return {
                response: null,
                fileId: ''
            };
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
        >
            <DialogTitle>Import participants{assignToProgram ? ' to program' : undefined}</DialogTitle>
            <DialogContent>
                <Stack gap={1.5} sx={{ mb: 2 }}>
                    <Alert severity='info'>
                        Email will be sent to <strong>{appUserContext?.currentUser?.email}</strong> on completion
                    </Alert>
                    {/* <Typography>
                        For a sample CSV file <Link onClick={() => downloadSample()}>click here</Link>.
                    </Typography> */}
                    {!importUploadFileBlobUrl ?
                        <Stack gap={1}>
                            <Typography variant='body2'>
                                The first row of the CSV must contain the column headers below.
                            </Typography>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemText primary='FirstName - required' secondary={<Stack gap={1} sx={{ pl: 2 }}>
                                        <Typography variant='body2'>The first name of the participant.</Typography>
                                    </Stack>} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary='LastName - required' secondary={<Stack gap={1} sx={{ pl: 2 }}>
                                        <Typography variant='body2'>The last name of the participant.</Typography>
                                    </Stack>} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary='Email - required' secondary={
                                        <Stack gap={1} sx={{ pl: 2 }}>
                                            <Typography variant='body2'>The email of the participant. Existing participants will be matched on this field.</Typography>
                                        </Stack>
                                    } />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary='PhoneNumber - optional' secondary={
                                        <Stack gap={1} sx={{ pl: 2 }}>
                                            <Typography variant='body2'>The phone number of the participant in E.164 international format which includes country code. Existing participants will be matched on this field.</Typography>
                                            <Typography variant='body2'>Example: <code>+61400222333</code> for an Australian (+61 country code) mobile phone of 0400 222 333.</Typography>
                                        </Stack>
                                    } />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary='CreatedAt - optional' secondary={
                                        <Stack gap={1} sx={{ pl: 2 }}>
                                            <Typography variant='body2'>The date when the participant was created or registered in YYYY-MM-DD format.</Typography>
                                            <Stack gap={0.2}>
                                                <Typography variant='body2'>Example: <code>2019-10-12</code> for 12th October 2019.</Typography>
                                            </Stack>
                                        </Stack>
                                    } />
                                </ListItem>
                                {!assignToProgram ?
                                    <ListItem>
                                        <ListItemText primary='AssignToProgramReferenceCode - optional' secondary={<Stack gap={1} sx={{ pl: 2 }}>
                                            <Typography variant='body2'>The program reference code to assign the participant to.</Typography>
                                        </Stack>} />
                                    </ListItem> : undefined}
                            </List>
                        </Stack> : undefined}
                    {assignToProgram ?
                        <Stack gap={0.5}>
                            <Typography variant='body2'>
                                All participants will be created or matched and then assigned to:
                            </Typography>
                            <Typography sx={{ ml: 2 }} variant='subtitle2'>
                                <code>{assignToProgram.programReferenceCode}</code>: {assignToProgram.internalName}
                            </Typography>
                        </Stack>
                        : undefined}
                </Stack>
                <ResourceContentManager
                    defaultValue={[]}
                    prepareUpload={(file: File) => prepareUpload(file)}
                    hideDropzone={false}
                    restrictedToMedia={false}
                    direction='row'
                    singleFile={true}
                    preventDownload={true}
                    onUploadSuccess={(resource: Activ8CoursesContentLearningCourseContentResource) => onUploadSuccess(resource)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
                <Button disabled={isLoading || !importUploadFileBlobUrl} onClick={() => importParticipants()}>Import</Button>
            </DialogActions>
        </Dialog>
    )
}