import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { appApi, VoloAbpNameValue } from 'src/api/redux/app/appApi';

import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

type IProps = {
  name: string;
};

type Props = IProps & TextFieldProps;

export default function RHFTimezoneSelect({ name, label, helperText, ...other }: Props) {
  const { control } = useFormContext();
  //   const methods = useForm();

  const [value, setValue] = React.useState<VoloAbpNameValue | null>(null);
  const [resetCount, setResetCount] = React.useState<number>(0);
  const [inputValue, setInputValue] = React.useState('');
  const timezoneLookupQuery = appApi.endpoints.timezoneLookupGetAllIana.useQuery();
  const [options, setOptions] = React.useState<readonly VoloAbpNameValue[]>([]);
  
  const prettifyTimezoneId = (id: string) => id.replace(/_/gi, ' ');
  const uglifyTimezoneId = (id: string) => id.replace(/ /gi, '_');

  React.useEffect(() => {
    setOptions((timezoneLookupQuery?.data?.items || []).map(a => {
      return { 
        name: a.name,
        value: prettifyTimezoneId(a.value || '')
      }
    }))
  }, [timezoneLookupQuery.isLoading]);

  return (    
    <>
      { timezoneLookupQuery.isLoading ? <></> : 
      <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
          filterOptions={(opts, { inputValue }) => !inputValue ? opts.slice(0, 30) : opts.filter(x => x && x.name && x.name.toLowerCase().indexOf(uglifyTimezoneId(inputValue.toLowerCase())) > -1).slice(0, 30)}
          options={options}
          autoComplete
          fullWidth
          // includeInputInList={false}
          // filterSelectedOptions
          value={(value || prettifyTimezoneId(field.value || ''))as any}
          isOptionEqualToValue={(op, val) => {
            // console.log('heree isOptionEqualToValue', val, op);
            // if(!op) return true;
            const matchVal = uglifyTimezoneId(typeof val === 'string' ? val : val.name);
            return op.name === matchVal;
          }}
          onChange={(event: any, newValue: VoloAbpNameValue | null) => {
            // setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);

            // console.log('heree5', field.name, newValue)
            // methods.setValue(field.name, newValue?.description, { shouldValidate: false, shouldDirty: true, shouldTouch: true});
            field.onChange(newValue?.name);
          }}
          onInputChange={(event, newInputValue, reason) => {
            // console.log('heree changed1', newInputValue, reason)
            if (reason === 'clear') {
              field.onChange('');
              // setValue(null);
              setResetCount(resetCount + 1);
              setInputValue('');
            } else {
              field.onChange(newInputValue);
              setInputValue(newInputValue);
            }
          }}
          //   onReset={() => field.onChange(newValue?.description)}
          renderInput={(params) => {
            const helperTextComponent = error?.message && helperText ? 
                    <>
                      <span style={{display: 'block'}}>{error?.message}</span>
                      <span style={{display: 'block'}}>{helperText}</span>
                    </>
                    : (helperText ? helperText : 
                      (error?.message ? error?.message : undefined)
                      )

            return (
              <>
              <TextField key={resetCount} {...params} inputProps={{...params.inputProps, autoComplete:'no'}} label={label} fullWidth helperText={helperTextComponent} error={!!error} />
              </>
            )
          }}
          renderOption={(props, option) => (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body2" color="text.secondary">
                      {prettifyTimezoneId(option.value)}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            )}
        />
      )}
      />
      }
    </>    
  );
}
