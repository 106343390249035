import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { Control, FieldErrors, useFieldArray, useFormContext } from 'react-hook-form';
import { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import ChipMenuButton from 'src/sections/shared/ChipMenuButton';
import { stringifyNumber } from 'src/utils/formatNumber';
import {
    isoTimespanToFriendlyText, isoTimespanToTotalMinutes, minutesToIsoTimespan
} from 'src/utils/formatTime';
import uuidv4 from 'src/utils/uuidv4';

import {
    Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, MenuItem, Stack, Tab, Tabs,
    useTheme
} from '@mui/material';

import { LearningTemplateFormValuesSchema } from '../../LearningCourseTemplateWizardForm';
import { CourseContentSessionTabContent } from './CourseContentSessionTabContent';

type CourseContentChapterAccordianItemProps = {
    courseTemplateId: string;
    onDisplayToggle: (index: number, forceVisibility?: boolean) => void;
    onDeleteChapter: (index: number) => void;
    isExpanded: boolean;
    chapterIndex: number;
    control: Control<LearningTemplateFormValuesSchema>;
};

interface TabPanelProps {
    children?: React.ReactNode;
    sessionIndex: number;
    value: number | null;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, sessionIndex, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{ width: '100%' }}
            hidden={value !== sessionIndex}
            id={`vertical-tabpanel-${sessionIndex}`}
            aria-labelledby={`vertical-tab-${sessionIndex}`}
            {...other}
        >
            {value === sessionIndex && (
                <Box sx={{ p: 2, paddingTop: 0, paddingLeft: 4 }} width='100%'>
                    {children}
                </Box>
            )}
        </div>
    );
}

export const CourseContentChapterAccordianItem = ({
    courseTemplateId,
    chapterIndex,
    control,
    isExpanded,
    onDisplayToggle,
    onDeleteChapter,
}: CourseContentChapterAccordianItemProps) => {
    const { watch, formState, getValues, setValue, trigger } = useFormContext<LearningTemplateFormValuesSchema>();
    const [selectedSession, setSelectedSession] = useState<number | null>(0);
    const [chapterTotalDuration, setChapterTotalDuration] = useState<string>('');
    const [isChapterValid, setIsChapterValid] = useState<boolean>(false);
    const [autoShowLastSessionId, setAutoShowLastSessionId] = useState(false);
    const theme = useTheme();
    const handleChangeSession = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedSession(newValue);
    };
    const chapterFieldName = `chapters.${chapterIndex}` as 'chapters.0';
    const sessionWatch = watch(`${chapterFieldName}.sessions` as unknown as 'chapters.0.sessions');

    const confirm = useConfirm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${chapterFieldName}.sessions` as unknown as 'chapters.0.sessions',

        // shouldUnregister: false,
    });

    const onDeleteSession = async (index: number) => {
        const toDelete = (getValues(`chapters.${chapterIndex}.sessions`) || [])[index];
        if (!toDelete) return;

        const touchedFieldsOnSession = (!formState.touchedFields.chapters || !formState.touchedFields.chapters[chapterIndex].sessions || !formState.touchedFields.chapters[chapterIndex].sessions) ? {} as any : (formState.touchedFields.chapters[chapterIndex].sessions?.[index] || {} as any);
        const deleteSessionFunc = () => {
            remove(index);
            setSelectedSession(fields.length === 1 ? null : index - 1);
        }

        if (toDelete.content?.length === 0 && !toDelete.facilitatorNotes && toDelete.facilitatorResources?.length === 0) {
            deleteSessionFunc();
        }
        else if (!touchedFieldsOnSession.content && !touchedFieldsOnSession.duration && !touchedFieldsOnSession.facilitatorNotes && !touchedFieldsOnSession.facilitatorResources && !touchedFieldsOnSession.name) {
            deleteSessionFunc();
        }
        else {
            confirm({ title: toDelete?.name || undefined, description: `Are you sure you want to delete the session including its content and quizzes?` })
                .then(deleteSessionFunc);
        }
    };

    const addSession = async () => {
        const newSessionId = uuidv4();
        await setAutoShowLastSessionId(true);

        append({
            id: newSessionId,
            name: stringifyNumber(fields.length + 1, true) + ' session',
            duration: '00:00:00' as any,
            facilitatorNotes: '',
            facilitatorResources: [],
            content: []
        });
    };
    useEffect(() => {
        if (autoShowLastSessionId) {
            setSelectedSession(fields.length - 1);
            setAutoShowLastSessionId(false);
        }
    }, [fields])

    const isLoading = false;

    const refreshValidation = () => {
        const errors = (formState.errors.chapters as unknown as any[])?.[chapterIndex];
        const isValid = !Boolean(errors); // && (isEdit || Boolean(formState.touchedFields.chapters?.[chapterIndex] || formState.dirtyFields.chapters?.[chapterIndex]));
        setIsChapterValid(isValid)
        if (!isValid) {
            console.error('Chapter not valid', { isValid, chapterIndex, errors });
        }
    }

    useEffect(() => {
        trigger(chapterFieldName);
        setTimeout(() => { refreshValidation(); }, 100)
    }, [sessionWatch, isExpanded])

    // useEffect(() => {
    //     trigger(chapterFieldName);
    //     setTimeout(() => { refreshValidation(); }, 200)
    // }, [isExpanded])

    useEffect(() => {
        const totalDurationIso = minutesToIsoTimespan((sessionWatch || []).map(a => isoTimespanToTotalMinutes(a.duration)).reduce((a, b) => a + b, 0));
        setChapterTotalDuration(totalDurationIso);
        // console.log('heree durationWatch 1', totalDurationIso);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    useEffect(() => {
        setValue(`chapters.${chapterIndex}.duration` as unknown as 'chapters.0.duration', chapterTotalDuration, { shouldValidate: false, shouldDirty: true, shouldTouch: true });
        // console.log('heree durationWatch 2');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chapterTotalDuration])

    const durationWatch = watch(`chapters.${chapterIndex}.duration` as unknown as 'chapters.0.duration');

    const totalDurationText = isoTimespanToFriendlyText(durationWatch) || '';

    const isSessionValid = (sessionIndex: number): boolean => {
        const errors = ((formState.errors.chapters as unknown as any[])?.[chapterIndex]?.sessions)?.[sessionIndex];
        const isValid = !Boolean(errors); // && (isEdit || Boolean(formState.touchedFields.chapters?.[chapterIndex]?.sessions?.[sessionIndex] || formState.dirtyFields.chapters?.[chapterIndex]?.sessions?.[sessionIndex]));
        return isValid;
    }

    return (
        <Accordion
            TransitionProps={{ unmountOnExit: true }}
            expanded={isExpanded}
            onChange={() => onDisplayToggle(chapterIndex)}
        >
            <AccordionSummary
                expandIcon={<Iconify icon={'eva:chevron-down-outline'} />}
                aria-controls={`chapter-${chapterIndex}-content`}
                id={`chapter-${chapterIndex}-header`}
                focusRipple={false}
                disableTouchRipple={true}
            >
                <Box width={'100%'}>
                    <Stack direction="row" justifyContent="space-between" alignItems={"baseline"}>
                        <Stack direction="row" justifyContent="space-between">
                            <ChipMenuButton
                                label={'Ch. ' + (chapterIndex + 1)}
                                variant={'filled'}
                                size="small"
                                stopPropagation
                                color={!isChapterValid ? 'error' : (isExpanded ? 'primary' : undefined)}
                                id={'chapter-' + chapterIndex}
                                icon={<Iconify icon='eva:more-horizontal-outline' />}
                            >
                                <MenuItem onClick={(e) => { e.stopPropagation(); onDisplayToggle(chapterIndex, true); addSession(); }}>Add session</MenuItem>
                                <MenuItem onClick={(e) => { e.stopPropagation(); onDeleteChapter(chapterIndex) }}>Delete chapter {chapterIndex + 1}</MenuItem>
                            </ChipMenuButton>
                            <RHFTextField
                                variant="standard"
                                name={`chapters.${chapterIndex}.name`}
                                onClick={(e) => { e.stopPropagation(); onDisplayToggle(chapterIndex, true) }}
                                // startAdornment={<InputAdornment position="start">#{chapterIndex + 1}</InputAdornment>}
                                sx={{ width: '290px', ml: 4 }}
                                size="small"
                            />
                            <div>
                                {(sessionWatch || []).length === 0 ? (
                                    <Chip
                                        icon={<Iconify icon="eva:bookmark-outline" />}
                                        variant="filled"
                                        color="error"
                                        sx={{ ml: 4 }}
                                        size="small"
                                        label="No sessions"
                                    />
                                ) : (
                                    <Chip
                                        icon={<Iconify icon="eva:bookmark-outline" />}
                                        variant="outlined"
                                        size="small"
                                        sx={{ ml: 4 }}
                                        label={`${(sessionWatch || []).length} session${(sessionWatch || []).length > 1 ? 's' : ''
                                            }`}
                                    />
                                )}
                            </div>
                            {durationWatch && totalDurationText ? (
                                <Chip
                                    icon={<Iconify icon="eva:clock-outline" />}
                                    variant="outlined"
                                    sx={{ ml: 4 }}
                                    size="small"
                                    label={totalDurationText}
                                />
                            ) : undefined
                            }
                        </Stack>

                        {
                            isExpanded ?
                                <div>
                                    <Button
                                        sx={{ mr: 4 }}
                                        size="small"
                                        variant={fields.length === 0 ? 'contained' : 'outlined'}
                                        color={'primary'}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onDisplayToggle(chapterIndex, true);
                                            addSession();
                                        }}
                                    >
                                        Add session
                                    </Button>
                                    {/* <Button
                    sx={{ mr: 2 }}
                    size="small"
                    variant="outlined"
                    color={'error'}
                    onClick={(e) => { e.stopPropagation(); onDeleteChapter(chapterIndex) }}
                    >
                    Delete
                    </Button> */}
                                </div>
                                : undefined
                        }
                    </Stack>
                </Box>
            </AccordionSummary>
            <AccordionDetails hidden={!fields || fields.length === 0}>
                <Stack direction={'row'} alignItems={'baseline'} justifyContent='space-between' spacing={0}>
                    {/* <code style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify((formState.errors.chapters as unknown as any[])?.[chapterIndex], null, 2)}</code> */}
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={selectedSession}
                        onChange={handleChangeSession}
                        aria-label="Session list"
                        indicatorColor={'transparent' as any}
                        sx={{ borderRight: 0, borderColor: 'divider', p: 0 }}
                    >
                        {(sessionWatch || []).map((s, sessionIndex) =>
                            <Tab
                                key={s.id}
                                disableRipple
                                sx={{ maxWidth: '320px' }}
                                style={{ marginRight: 15, marginLeft: 0 }}
                                label={
                                    <Stack direction={'row'}>
                                        <ChipMenuButton
                                            label={'Ses. ' + (sessionIndex + 1)}
                                            variant={'filled'}
                                            size="small"
                                            stopPropagation
                                            // color={(selectedSession === sessionIndex ? 'primary' : (isChapterValid ? undefined : 'error'))}
                                            color={(!isSessionValid(sessionIndex) ? 'error' : (selectedSession === sessionIndex ? 'primary' : undefined))}
                                            id={'session-' + sessionIndex}
                                            icon={<Iconify icon='eva:more-horizontal-outline' />}
                                        >
                                            {/* <MenuItem onClick={(e) => { e.stopPropagation(); onDisplayToggle(chapterIndex, true); addSession(); }}>Add session</MenuItem> */}
                                            <MenuItem onClick={(e) => { e.stopPropagation(); onDeleteSession(sessionIndex) }}>Delete session {sessionIndex + 1}</MenuItem>
                                        </ChipMenuButton>

                                        <RHFTextField
                                            variant="standard"
                                            name={`chapters.${chapterIndex}.sessions.${sessionIndex}.name`}
                                            // onClick={(e) => e.stopPropagation()}
                                            // startAdornment={<InputAdornment position="start">#{chapterIndex + 1}</InputAdornment>}
                                            sx={{ width: 'auto', ml: 2 }}
                                            size="small"
                                        />
                                    </Stack>
                                }
                                id={`session-tab-${sessionIndex}`}
                                {... { 'aria-controls': `session-tabpanel-${sessionIndex}` }}
                            />
                        )}
                    </Tabs>
                    {(fields || []).map((s, sessionIndex) =>
                        <TabPanel key={s.id} value={selectedSession} sessionIndex={sessionIndex}>
                            <CourseContentSessionTabContent courseTemplateId={courseTemplateId} chapterIndex={chapterIndex} sessionIndex={sessionIndex} control={control} />
                        </TabPanel>
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};
