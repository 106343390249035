import * as mime from 'mime-types';
import { useEffect } from 'react';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';
import {
    Activ8CoursesContentLearningCourseContentResource,
    Activ8CoursesContentLearningCourseContentResourceType,
    Activ8CoursesTemplatesPrepareResourceOperationResponse, appApi
} from 'src/api/redux/app/appApi';
import RHFRichTextEditor from 'src/components/hook-form/RHFRichTextEditor';
import Iconify from 'src/components/Iconify';
import ChipMenuButton from 'src/sections/shared/ChipMenuButton';
import { ResourceContentManager } from 'src/sections/shared/ResourceContentManager';
import {
    isoTimespanToFriendlyText, minutesToFriendlyText, minutesToIsoTimespan
} from 'src/utils/formatTime';
import uuidv4 from 'src/utils/uuidv4';

import { Divider, MenuItem, Stack, Typography, useTheme } from '@mui/material';

import { LearningTemplateFormValuesSchema } from '../../LearningCourseTemplateWizardForm';
import { CourseTemplateContentBuilder } from './CourseTemplateContentBuilder';

type CourseContentSessionTabContentProps = {
    courseTemplateId: string;
    sessionIndex: number;
    chapterIndex: number;
    control: Control<LearningTemplateFormValuesSchema>;
}

export const CourseContentSessionTabContent = ({ courseTemplateId, sessionIndex, chapterIndex, control }: CourseContentSessionTabContentProps) => {
    const theme = useTheme();
    const { watch, setValue, getValues, trigger } = useFormContext<LearningTemplateFormValuesSchema>();

    const sessionFieldName = `chapters.${chapterIndex}.sessions.${sessionIndex}` as unknown as 'chapters.0.sessions.0';
    // const sessionWatch = watch(`chapters.${chapterIndex}.sessions.${sessionIndex}` as unknown as 'chapters.0.sessions.0');
    const nameWatch = watch(`chapters.${chapterIndex}.sessions.${sessionIndex}.name` as unknown as 'chapters.0.sessions.0.name');

    const durationFieldName = `chapters.${chapterIndex}.sessions.${sessionIndex}.duration` as unknown as 'chapters.0.sessions.0.duration';
    const durationWatch = watch(durationFieldName);

    const facilitatorResourceFieldName = `chapters.${chapterIndex}.sessions.${sessionIndex}.facilitatorResources` as 'chapters.0.sessions.0.facilitatorResources';
    const facilitatorNotesFieldName = `chapters.${chapterIndex}.sessions.${sessionIndex}.facilitatorNotes` as 'chapters.0.sessions.0.facilitatorNotes';
    const facilitatorResourceWatch = watch(facilitatorResourceFieldName);
    const facilitatorNotesWatch = watch(facilitatorNotesFieldName);

    const [prepareContentWritePost] = appApi.endpoints.learningCourseTemplatePrepareResourceWrite.useMutation({});
    const [prepareContentReadQuery] = appApi.endpoints.learningCourseTemplatePrepareResourceRead.useLazyQuery();
    const { fields, append, remove, update } = useFieldArray<LearningTemplateFormValuesSchema>({
        control,
        name: facilitatorResourceFieldName,
        // mode: 'onBlur',
        // shouldUnregister: false
    });

    useEffect(() => {
        trigger(sessionFieldName, { shouldFocus: false });
    }, [facilitatorResourceWatch, facilitatorNotesWatch])

    const setDuration = (minutes: number) => {
        setValue(durationFieldName, minutesToIsoTimespan(minutes), { shouldTouch: true, shouldValidate: true, shouldDirty: true });
        trigger(durationFieldName);
    }

    const durationOptions = Array.from({ length: 33 }, (x, i) => i).map((i) => {
        const isoVal = minutesToIsoTimespan(i * 15);
        return (
            <MenuItem key={i} selected={isoVal === (durationWatch || getValues(durationFieldName))} onClick={(e) => { setDuration(i * 15) }}>{minutesToFriendlyText(15 * i) || '-- No duration --'}</MenuItem>
        )
    });

    const prepareUpload = async (file: File): Promise<{ response: Activ8CoursesTemplatesPrepareResourceOperationResponse | null, fileId: string }> => {
        const fileId = uuidv4();
        const response = await prepareContentWritePost({
            courseTemplateId: courseTemplateId,
            resourceId: fileId,
            contentType: mime.lookup(file.name) as string,
            contentFileName: file.name
        })
            .unwrap();

        return {
            response,
            fileId
        };
    }

    const prepareReadUrl = async (file: Activ8CoursesContentLearningCourseContentResource, isDownload: boolean): Promise<string> => {
        const response = await prepareContentReadQuery({
            courseTemplateId: courseTemplateId,
            resourceId: file.id,
            contentType: file.contentType as string,
            contentFileName: file.fileName as string,
            isDownload: isDownload
        }, true).unwrap();

        const url = `${response.blobReference}${response.blobToken}`;

        // Make head request to ensure or wait for blob to exist
        let attempts = 0;
        while (attempts < 60) {
            const res = await fetch(url, {
                method: 'HEAD'
            });
            if (res.status === 200) {
                break;
            }
            await new Promise(r => setTimeout(r, 1000));

            attempts++;
        }

        return url;
    }

    const onResourceDelete = async (resource: Activ8CoursesContentLearningCourseContentResource): Promise<void> => {
        const index = fields.findIndex(e => e.id === resource.id);
        remove(index);
    }
    const onUploadSuccess = async (resource: Activ8CoursesContentLearningCourseContentResource): Promise<void> => {
        append({
            id: resource.id as string,
            type: resource.type as Activ8CoursesContentLearningCourseContentResourceType,
            description: resource.description as string,
            blobReference: resource.blobReference as string,
            fileName: resource.fileName as string,
            contentType: resource.contentType as string,
            fileExtension: resource.fileExtension as string,
            fileSizeBytes: resource.fileSizeBytes as number,
        });
    }
    const onUpdateMetadata = async (resource: Activ8CoursesContentLearningCourseContentResource): Promise<void> => {
        const index = (getValues(facilitatorResourceFieldName) || []).findIndex(e => e.id === resource.id);

        update(index, { ...resource } as any);
    }

    return (
        <Stack rowGap={4} divider={<Divider orientation="horizontal" />}>
            {/* {<code style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(facilitatorResourceWatch)}</code>} */}

            <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems="center" gap={1} rowGap={2}>
                    <Iconify fontSize={'24px'} icon="eva:bookmark-fill" color={theme.palette.primary.main} />
                    <Typography color={theme.palette.primary.main} variant='h4' sx={{ fontWeight: 600 }} noWrap>{nameWatch.substring(0, 30) || '...'}</Typography>
                </Stack>
                <ChipMenuButton
                    key={durationWatch}
                    label={isoTimespanToFriendlyText(durationWatch || getValues(durationFieldName)) || 'No duration'}
                    variant={'filled'}
                    id={'session-' + sessionIndex + '-duration'}
                    icon={<Iconify fontSize={20} icon='eva:clock-outline' />}
                    MenuListProps={{ variant: 'selectedMenu', autoFocusItem: true }}
                    PaperProps={{
                        style: {
                            maxHeight: 30 * 10,
                            // width: '20ch',
                        }
                    }}
                >
                    {durationOptions}
                </ChipMenuButton>
            </Stack>

            {/* <Stack rowGap={2}> */}
            <div>
                <Stack gap={2} sx={{ mb: 3 }}>
                    <Typography variant='h5'>Trainer content</Typography>
                    <Typography variant='body1'>Assist trainers in delivering this session.</Typography>
                    <Typography variant='body1'>Trainees will not be able to view trainer content.</Typography>
                    <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                        <RHFRichTextEditor name={`chapters.${chapterIndex}.sessions.${sessionIndex}.facilitatorNotes`} />
                        <ResourceContentManager
                            defaultValue={facilitatorResourceWatch || []}
                            prepareUpload={(file: File) => prepareUpload(file)}
                            prepareReadUrl={(file: Activ8CoursesContentLearningCourseContentResource, isDownload: boolean) => prepareReadUrl(file, isDownload)}
                            onDelete={(resource: Activ8CoursesContentLearningCourseContentResource) => onResourceDelete(resource)}
                            onUploadSuccess={(resource: Activ8CoursesContentLearningCourseContentResource) => onUploadSuccess(resource)}
                            onUpdateMetadata={(resource: Activ8CoursesContentLearningCourseContentResource) => onUpdateMetadata(resource)} />
                    </Stack>
                </Stack>
            </div>
            <div>
                <Stack gap={2} sx={{ mt: 3 }}>
                    <Typography variant='h5'>Trainee content</Typography>
                    <Typography variant='body1'>Provide content, resources and quizzes that will be delivered to the trainees when they are completing the online modules for this session.</Typography>
                    <CourseTemplateContentBuilder {...{ courseTemplateId, chapterIndex, sessionIndex, control, prepareUpload, prepareReadUrl }} />
                </Stack>
            </div>
            {/* </Stack> */}
        </Stack>
    )
}