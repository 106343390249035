import { upperFirst } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { accountApi } from 'src/api/redux/account/accountApi';
import { appApi } from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import RHFAddressSelect from 'src/components/hook-form/RHFAddressSelect';
import RHFMultiSelect from 'src/components/hook-form/RHFMultiSelect';
import RHFPersonAccreditationSelect from 'src/components/hook-form/RHFPersonAccreditationSelect';
import RHFPersonAttributeSelect from 'src/components/hook-form/RHFPersonAttributeSelect';
import RHFPhoneField from 'src/components/hook-form/RHFPhoneField';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useAppUserContext from 'src/hooks/useAppUserContext';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import uuidv4 from 'src/utils/uuidv4';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Card, ListItemText, MenuItem, Stack } from '@mui/material';

import { UserManager } from '../../../@types/user';
import { FormProvider, RHFSwitch, RHFTextField } from '../../../components/hook-form';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

type ExtraFormValueProps = {
  assignedPersonAttributeIds: string[];
  assignedPersonAccreditationIds: string[];
}

type FormValuesProps = UserManager & ExtraFormValueProps;

type Props = {
  isEdit: boolean;
  isEditCurrentUserProfile: boolean;
  currentUser?: UserManager;
  isParticipantForm?: boolean;
  isFacilitatorForm?: boolean;
  enablePartialUser?: boolean;
};

export default function UserNewEditForm({ isEdit, isEditCurrentUserProfile, currentUser, isParticipantForm, isFacilitatorForm, enablePartialUser }: Props) {
  const navigate = useNavigate();
  const appUserContext = useAppUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useApiResponseHandler();
  const [profileUpdatePost] = accountApi.endpoints.profileUpdate.useMutation();
  const [userCreatePost] = appApi.endpoints.identityUserCreate.useMutation();
  const [userUpdatePost] = appApi.endpoints.identityUserUpdate.useMutation();
  const [participantCreatePost] = appApi.endpoints.participantCreate.useMutation();
  const [participantUpdatePost] = appApi.endpoints.participantUpdate.useMutation();
  const [facilitatorCreatePost] = appApi.endpoints.facilitatorCreate.useMutation();
  const [facilitatorUpdatePost] = appApi.endpoints.facilitatorUpdate.useMutation();
  const userProfileQuery = accountApi.endpoints.profileGet.useQuery(undefined,
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true
    });
  const userListQuery = appApi.endpoints.identityUserGetList.useQuery({});
  const roleQuery = appApi.endpoints.identityRoleGetList.useQuery({
    maxResultCount: 100
  });
  const [getAssignedAttributesToUserQueryLazy, getAssignedAttributesToUserQuery] = !isEditCurrentUserProfile ? appApi.endpoints.personAttributeGetAssignmentsForUser.useLazyQuery(
    //, { 
    // refetchOnFocus: true,
    //refetchOnMountOrArgChange: true,
    //skip: !isEdit || !currentUser?.id
    //}
  ) : [];
  const [getAssignedAccreditationsToUserQueryLazy, getAssignedAccreditationsToUserQuery] = !isEditCurrentUserProfile ? appApi.endpoints.personAccreditationGetAssignmentsForUser.useLazyQuery(
    //, { 
    // refetchOnFocus: true,
    //refetchOnMountOrArgChange: true,
    //skip: !isEdit || !currentUser?.id
    //}
  ) : [];
  const [setAssignedAttributesToUserQuery] = appApi.endpoints.personAttributeSetAssignmentsForUser.useMutation();
  const [setAssignedAccreditationsToUserQuery] = appApi.endpoints.personAccreditationSetAssignmentsForUser.useMutation();

  const isHostTenant = !appUserContext?.currentTenant?.id;
  const permissionChecker = usePermissionChecker()
  const hasPermissionToSetAccreditations = permissionChecker.has(permissionKeys.tenant.partner.users.assignAccreditations);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: enablePartialUser ? Yup.string().nullable().email('Not a valid email') : Yup.string().required('Email is required').email('Not a valid email'),
    phoneNumber: Yup.string().nullable(),
    address: Yup.string().nullable(),
    assignedPersonAttributeIds: Yup.array().nullable(),
    assignedPersonAccreditationIds: Yup.array().nullable(),
    postcode: Yup.string().nullable(),
    countryCode: Yup.string().nullable()
  });

  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || '',
      userName: currentUser?.userName || '',
      lastName: currentUser?.lastName || '',
      email: enablePartialUser && currentUser?.isPartialUser ? '' : (currentUser?.email || ''),
      phoneNumber: currentUser?.phoneNumber || '',
      address: currentUser?.address || '',
      isActive: currentUser?.isActive === undefined ? true : currentUser?.isActive,
      roleNames: currentUser?.roleNames || (isHostTenant && !isParticipantForm && !isFacilitatorForm ? ['admin'] : []),
      assignedPersonAttributeIds: isEdit ? (getAssignedAttributesToUserQuery?.data?.items?.map(a => (a.personAttribute as any).id) || []) : [],
      assignedPersonAccreditationIds: isEdit ? (getAssignedAccreditationsToUserQuery?.data?.items?.map(a => (a.personAccreditation as any).id) || []) : [],
      postcode: currentUser?.postcode || '',
      countryCode: currentUser?.countryCode || ''
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, isEdit, isEditCurrentUserProfile, isParticipantForm, isFacilitatorForm]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  useEffect(() => {
    if (isEdit && currentUser?.id && getAssignedAttributesToUserQueryLazy) {
      getAssignedAttributesToUserQueryLazy({ userId: currentUser?.id as string }, false);
    }
    if (isEdit && currentUser?.id && getAssignedAccreditationsToUserQueryLazy) {
      getAssignedAccreditationsToUserQueryLazy({ userId: currentUser?.id as string }, false);
    }

    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [isEdit, currentUser]);

  const onSubmit = async (data: FormValuesProps) => {
    let success = false;
    let userIdToNavigateTo: string | undefined = undefined;


    const partialUserEmail = enablePartialUser && !Boolean(data.email) ? uuidv4().replace(/-/gi, '') + '@partialuser.activ8ls.com' : '';

    try {
      if (isEdit) {
        if (!isEditCurrentUserProfile) {
          try {
            if (!isParticipantForm) {
              await userUpdatePost({
                id: currentUser?.id as string,
                voloAbpIdentityIdentityUserUpdateDto: {
                  userName: data.userName || partialUserEmail,
                  name: data.name,
                  surname: data.lastName,
                  email: data.email || partialUserEmail,
                  phoneNumber: data.phoneNumber?.toString(),
                  isActive: data.isActive,
                  roleNames: data.roleNames,
                  organizationUnitIds: undefined,
                  lockoutEnabled: true,
                  concurrencyStamp: undefined,
                  extraProperties: {
                    "claims.address": data.address,
                    'Postcode': data.postcode,
                    'CountryCode': data.countryCode
                  }
                }
              }).unwrap();
              userIdToNavigateTo = currentUser?.id as string;
            }
            else if (isFacilitatorForm) {
              await facilitatorUpdatePost({
                id: currentUser?.id as string,
                activ8FacilitatorsFacilitatorUpdateDto: {
                  userName: data.userName || partialUserEmail,
                  name: data.name,
                  surname: data.lastName,
                  email: data.email || partialUserEmail,
                  phoneNumber: data.phoneNumber?.toString(),
                  isActive: data.isActive,
                  roleNames: data.roleNames,
                  organizationUnitIds: undefined,
                  lockoutEnabled: true,
                  concurrencyStamp: undefined,
                  extraProperties: {
                    "claims.address": data.address,
                    'Postcode': data.postcode,
                    'CountryCode': data.countryCode
                  }
                }
              }).unwrap();
              userIdToNavigateTo = currentUser?.id as string;
            }
            else {
              await participantUpdatePost({
                id: currentUser?.id as string,
                activ8ParticipantsParticipantUpdateDto: {
                  userName: data.userName || partialUserEmail,
                  name: data.name,
                  surname: data.lastName,
                  email: data.email || partialUserEmail,
                  phoneNumber: data.phoneNumber?.toString(),
                  isActive: data.isActive,
                  roleNames: data.roleNames,
                  organizationUnitIds: undefined,
                  lockoutEnabled: true,
                  concurrencyStamp: undefined,
                  extraProperties: {
                    "claims.address": data.address,
                    'Postcode': data.postcode,
                    'CountryCode': data.countryCode
                  }
                }
              }).unwrap();
              userIdToNavigateTo = currentUser?.id as string;
            }

            if (!isEditCurrentUserProfile && !isHostTenant && data.assignedPersonAttributeIds) {
              await setAssignedAttributesToUserQuery({
                userId: currentUser?.id as string,
                body: data.assignedPersonAttributeIds || []
              }).unwrap();
            }
            if (!isHostTenant && !isEditCurrentUserProfile && hasPermissionToSetAccreditations) {
              await setAssignedAccreditationsToUserQuery({
                userId: currentUser?.id as string,
                body: data.assignedPersonAccreditationIds || []
              }).unwrap();
            }

            if (userProfileQuery.data?.email === data.email) {
              await userProfileQuery.refetch();
            }
            success = true;
          }
          catch (error) {
            handleError(error);
          }
        }
        else {
          try {
            await profileUpdatePost({
              voloAbpAccountUpdateProfileDto: {
                userName: data.userName || partialUserEmail,
                name: data.name,
                surname: data.lastName,
                email: data.email || partialUserEmail,
                phoneNumber: data.phoneNumber?.toString(),
                concurrencyStamp: undefined,
                extraProperties: {
                  "claims.address": data.address,
                  'Postcode': data.postcode,
                  'CountryCode': data.countryCode
                }
              }
            }).unwrap();
            await userProfileQuery.refetch();
            success = true;
          }
          catch (error) {
            handleError(error);
          }
        }
      }
      else {
        try {
          if (isParticipantForm) {
            const userCreated = await participantCreatePost({
              activ8ParticipantsParticipantCreateDto: {
                userName: data.email || partialUserEmail,
                name: data.name,
                surname: data.lastName,
                email: data.email || partialUserEmail,
                phoneNumber: data.phoneNumber?.toString(),
                isActive: data.isActive,
                roleNames: ['participant'],
                organizationUnitIds: undefined,
                lockoutEnabled: true,
                password: new Date().getTime().toString() + '!Az',
                extraProperties: {
                  "claims.address": data.address,
                  'Postcode': data.postcode,
                  'CountryCode': data.countryCode
                }
              }
            }).unwrap();
            userIdToNavigateTo = userCreated.id;
          }
          else if (isFacilitatorForm) {
            const userCreated = await facilitatorCreatePost({
              activ8FacilitatorsFacilitatorCreateDto: {
                userName: data.email || partialUserEmail,
                name: data.name,
                surname: data.lastName,
                email: data.email || partialUserEmail,
                phoneNumber: data.phoneNumber?.toString(),
                isActive: data.isActive,
                roleNames: ['facilitator'],
                organizationUnitIds: undefined,
                lockoutEnabled: true,
                password: new Date().getTime().toString() + '!Az',
                extraProperties: {
                  "claims.address": data.address,
                  'Postcode': data.postcode,
                  'CountryCode': data.countryCode
                }
              }
            }).unwrap();
            userIdToNavigateTo = userCreated.id;
          }
          else {
            const userCreated = await userCreatePost({
              voloAbpIdentityIdentityUserCreateDto: {
                userName: data.email || partialUserEmail,
                name: data.name,
                surname: data.lastName,
                email: data.email || partialUserEmail,
                phoneNumber: data.phoneNumber?.toString(),
                isActive: data.isActive,
                roleNames: data.roleNames,
                organizationUnitIds: undefined,
                lockoutEnabled: true,
                password: new Date().getTime().toString() + '!Az',
                extraProperties: {
                  "claims.address": data.address,
                  'Postcode': data.postcode,
                  'CountryCode': data.countryCode
                }
              }
            }).unwrap();
            userIdToNavigateTo = userCreated.id;
          }

          if (data.assignedPersonAttributeIds && data.assignedPersonAttributeIds.length > 0) {
            await setAssignedAttributesToUserQuery({
              userId: userIdToNavigateTo as string,
              body: data.assignedPersonAttributeIds
            }).unwrap()
          }
          if (data.assignedPersonAccreditationIds && data.assignedPersonAccreditationIds.length > 0 && !isParticipantForm) {
            await setAssignedAccreditationsToUserQuery({
              userId: userIdToNavigateTo as string,
              body: data.assignedPersonAccreditationIds || []
            }).unwrap();
          }

          await userListQuery.refetch();

          success = true;
        }
        catch (error) {
          handleError(error);
        }
      }

      if (success) {
        enqueueSnackbar(!isEdit ? 'Created user and invite email has been sent!' : 'Updated!');
        await userListQuery.refetch();
        if (!isEditCurrentUserProfile) {
          if (isParticipantForm) {
            navigate(PATH_DASHBOARD.tenant.participant.overview(userIdToNavigateTo || ''));
          }
          else if (isFacilitatorForm) {
            navigate(PATH_DASHBOARD.tenant.facilitator.overview(userIdToNavigateTo || ''));
          }
          else {
            navigate(PATH_DASHBOARD.user.list);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isPartialUser = enablePartialUser && !watch('email');

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/* <>{isParticipantForm ? '1' : '0'}</><br />
        <>{isEditCurrentUserProfile ? '1' : '0'}</> */}
        <Stack spacing={3} alignItems="flex-end">
          <RHFTextField name="name" label="First name" disableAutoComplete />
          <RHFTextField name="lastName" label="Last name" disableAutoComplete />
          <RHFTextField name="email" label="Email address" helperText={isPartialUser ? 'If an email is not provided this user will be considered "partial" and will not be able to login to the platform' : undefined} />
          <RHFPhoneField name="phoneNumber" label="Phone number" placeholder='International format. Eg AUS mobile: 61 411 222 333' disableAutoComplete />
          <RHFAddressSelect name="address" postcodeFieldName="postcode" countryCodeFieldName="countryCode" label="Address" />

          {!isHostTenant && (!isEdit || (getAssignedAttributesToUserQuery && getAssignedAttributesToUserQuery.data && getAssignedAttributesToUserQuery.data.items)) ?
            <>
              <RHFPersonAttributeSelect
                name="assignedPersonAttributeIds"
                label="Attributes"
                defaultValue={defaultValues.assignedPersonAttributeIds}
              />
            </>
            : undefined}
          {hasPermissionToSetAccreditations && !isHostTenant && !isEditCurrentUserProfile && (!isEdit || (getAssignedAccreditationsToUserQuery && getAssignedAccreditationsToUserQuery.data && getAssignedAccreditationsToUserQuery.data.items)) ?
            <>
              <RHFPersonAccreditationSelect
                name="assignedPersonAccreditationIds"
                label="Accreditations"
                defaultValue={defaultValues.assignedPersonAccreditationIds}
              />
            </>
            : undefined}

          {!isEditCurrentUserProfile && !isHostTenant && !isParticipantForm && !isFacilitatorForm ?
            <>
              <RHFMultiSelect name="roleNames" label="Role" defaultValue={defaultValues.roleNames} required={true}>
                {(roleQuery?.data?.items || []).map((opt) => ({ ...opt, name: upperFirst(opt.name || '') })).map((option) => (
                  <MenuItem
                    key={option.id}
                    value={(option.name || '').toLowerCase()}
                  >
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))}
              </RHFMultiSelect>
              <RHFSwitch name="isActive" label="Is active" />
            </>
            : undefined}

          <Stack direction="row" justifyContent={isPartialUser ? 'space-between' : 'flex-end'} alignItems="center" sx={{ width: '100%' }}>
            {
              isPartialUser ?
                <Alert severity="warning">An email has not been provided so this user will be unable to login</Alert>
                : undefined
            }
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled={!isValid}>
              {!isEdit ? ('Create ' + (isPartialUser ? 'partial ' : '') + (isParticipantForm ? 'participant' : (isFacilitatorForm ? 'facilitator' : 'user'))) : 'Update'}
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Card>
  );
}
