import { sentenceCase } from 'change-case';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserManager } from 'src/@types/user';
import { appApi, VoloAbpIdentityIdentityUserDto } from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import Iconify from 'src/components/Iconify';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useAppUserContext from 'src/hooks/useAppUserContext';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fDate } from 'src/utils/formatTime';

import {
    Autocomplete, CardActions, Chip, Divider, IconButton, InputAdornment, Link, Stack, TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

type Props = {
  user: UserManager;
};

export default function UserParentDetailsCard({ user }: Props) {   
    const { handleError } = useApiResponseHandler();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const appUserContext = useAppUserContext();
    const confirm = useConfirm();
    const [ maintainIdentityUserLink ] = appApi.endpoints.participantMaintainIdentityUserLinks.useMutation();
    const [ removeIdentityUserLink ] = appApi.endpoints.participantRemoveIdentityUserLink.useMutation();
    const getParticipantRoleQuery = appApi.endpoints.identityUserGetRoleLookup.useQuery();
    const participantRoleId = getParticipantRoleQuery.data?.find(e => e.name === 'participant')?.id || '';
    const [userSearchInputValue, setUserSearchInputValue] = useState('');
    const [isParentChangeEdit, setIsParentChangeEdit] = useState<boolean>(false);
    const [isChildChangeEdit, setIsChildChangeEdit] = useState<boolean>(false);
    const permissionChecker = usePermissionChecker();
    const canManageParticipants = permissionChecker.has(permissionKeys.tenant.participant.manage);
    const [identityUserGetListLazyQuery, identityUserGetListQuery] = appApi.endpoints.identityUserGetList.useLazyQuery();
    const getParticipantQuery = appApi.endpoints.participantGet.useQuery({
      id: user.id
    });

    useEffect(() => {
      identityUserGetListLazyQuery({ sorting: 'name, surname', roleId: participantRoleId, filter: userSearchInputValue });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSearchInputValue])

    let autocompleteEl = <Autocomplete
        id="parent-identity-user"
        getOptionLabel={(o) => `${o.name} ${o.surname}`}
        placeholder='Search for a participant...'
        size='small'
        onInputChange={(event, newValue) => {
          setUserSearchInputValue(newValue);
        }}
        fullWidth={true}
        onChange={async (ev, val) => {
          await persistParticipantMaintainParentIdentityUser(val?.id || '');
          await setIsChildChangeEdit(false);
          await setIsParentChangeEdit(false);
        }}        
        autoComplete
        filterOptions={(x) => x}
        // value={(identityUserGetListQuery && identityUserGetListQuery.data && identityUserGetListQuery.data.items ? identityUserGetListQuery.data.items.find(e => e.id === (user.parentIdentityUser?.id || '')) : null)}
        // isOptionEqualToValue={(o, v) => o.id === v.id}
        options={identityUserGetListQuery.data?.items?.filter(a => a.id !== (user?.id || '')) || []}
        renderInput={(params) => <TextField {...params} autoFocus={true} inputProps={{...params.inputProps, autoComplete:'no'}} label="Search for participant" InputProps={{
          ...params.InputProps,
          startAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton
                  size='small'
                  aria-label="cancel"
                  onClick={() => { setIsChildChangeEdit(false); setIsParentChangeEdit(false); }}
                  edge="end"
                >
                  <Iconify icon={'eva:undo-fill'} />
                </IconButton>
              </InputAdornment>
            </>
          )
        }} />}
      />;

    const persistParticipantMaintainParentIdentityUser = async (linkToUserId: string) => {
      try {
        await maintainIdentityUserLink({
          parentIdentityUserId: isChildChangeEdit ? user.id : linkToUserId,
          childIdentityUserId: isChildChangeEdit ? linkToUserId : user.id,
          unlinkAllChildParentLinks: isParentChangeEdit
        }).unwrap();
        
        getParticipantQuery.refetch();
        enqueueSnackbar(isChildChangeEdit ? 'Added child successfully' : 'Updated parent successfully');
      }
      catch(error){
        handleError(error);
      }
    }

    const handleRemoveParent = async () => {
      if(!user.parentIdentityUser?.id) return;

      confirm({ description: `Are you sure you want to unlink the parent?` })
      .then(async () => { 
        try {
          await removeIdentityUserLink({
            parentIdentityUserId: user.parentIdentityUser?.id || '',
            childIdentityUserId: user.id,
          }).unwrap();
          
          getParticipantQuery.refetch();
          enqueueSnackbar('Removed parent successfully');
        }
        catch(error){
          handleError(error);
        }
    })
  };

  const handleRemoveChild = async (childIdentityUserId: string) => {
      confirm({ description: `Are you sure you want to unlink the child?` })
      .then(async () => { 
        try {
          await removeIdentityUserLink({
            parentIdentityUserId: user.id,
            childIdentityUserId: childIdentityUserId,
          }).unwrap();
          
          getParticipantQuery.refetch();
          enqueueSnackbar('Removed child successfully');
        }
        catch(error){
          handleError(error);
        }
    })
  };
    
  return (
    <Card>
      {user.parentIdentityUser?.id || (user.childIdentityUsers && user.childIdentityUsers.length > 0) ?
        <CardContent sx={{ p: 1.5 }}>
          <>
            <Stack spacing={1} alignItems='center' divider={<Divider orientation="horizontal" flexItem />}>
              {user.parentIdentityUser?.id ? 
              <>
                <Stack direction='row' justifyContent='space-between' sx={{ width:'100%' }} alignItems='stretch'>
                  <Typography variant='body1' sx={{ lineHeight: '35px' }}>
                    {user.name + '\'s parent is '}<Link sx={{ cursor: 'pointer' }} onClick={() => navigate(PATH_DASHBOARD.tenant.participant.overview(user.parentIdentityUser?.id || ''))}>{user.parentIdentityUser.name + (user.parentIdentityUser.surname ? ' ' + user.parentIdentityUser.surname : '')}</Link>
                  </Typography>
                  <IconButton color='primary' size='small' component="label" onClick={() => handleRemoveParent()}><Iconify icon='eva:trash-fill' /></IconButton>
                </Stack>
                
              </> : undefined}

              {user.childIdentityUsers && user.childIdentityUsers.length > 0 ? 
                user.childIdentityUsers.map(child => (
                  <Stack key={child.id} direction='row' justifyContent='space-between' sx={{ width:'100%' }} alignItems='stretch'>
                    <Typography variant='body1' sx={{ lineHeight: '35px' }}>
                      {user.name + ' is a child to '}<Link sx={{ cursor: 'pointer' }} onClick={() => navigate(PATH_DASHBOARD.tenant.participant.overview(child?.id || ''))}>{child.name + (child.surname ? ' ' + child.surname : '')}</Link>
                    </Typography>
                    <IconButton color='primary' size='small' component="label" onClick={() => handleRemoveChild(child?.id || '')}><Iconify icon='eva:trash-fill' /></IconButton>
                  </Stack>
                ))
              : undefined}
            </Stack>
          </>
        </CardContent>
      : undefined }

      {
        appUserContext?.currentTenant?.id && canManageParticipants ? 
          <CardActions>
          {isParentChangeEdit || isChildChangeEdit ? 
            autocompleteEl
          : 
            <Stack direction='row' alignItems='stretch' justifyContent='space-between' sx={{ width:'100%' }}>
              <Button variant="text" onClick={() => setIsParentChangeEdit(true)} sx={{ width:'100%' }}>
                {user.parentIdentityUser ? 'Change parent' : 'Set parent'}
              </Button>
              <Button variant="text" onClick={() => setIsChildChangeEdit(true)} sx={{ width:'100%' }}>
                Add child
              </Button>
            </Stack>
          }
        </CardActions>
        : <></>
      }
      
    </Card>
  );
}
