import parse from 'html-react-parser';
import { difference, get, uniq } from 'lodash-es';
import { DateTime } from 'luxon';
import { forwardRef, ReactElement, Ref, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Activ8CoursesContentLearningCourseContentBlock,
    Activ8CoursesContentLearningCourseContentChapter,
    Activ8CoursesContentLearningCourseContentChapterSession,
    Activ8CoursesContentLearningCourseContentResource,
    Activ8CoursesContentQuizResultsLearningCourseContentQuizResult, appApi
} from 'src/api/redux/app/appApi';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import { CloseIcon } from 'src/theme/overrides/CustomIcons';

import {
    AppBar, Button, Container, Dialog, IconButton, LinearProgress, Slide, Stack, Toolbar,
    Typography, useTheme
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import {
    LearningCourseProgramSessionContentViewWizardFormData
} from '../LearningCourseProgramOverviewDashboard';
import {
    LearningCourseProgramSessionContentBlockView
} from './LearningCourseProgramSessionContentBlockView';

// import 'react-quill/dist/quill.snow.css';

type LearningCourseProgramSessionContentViewWizardProps = {
    chapterIndex: number;
    sessionIndex: number;
    programName: string;
    courseProgramId: string;
    contentSession: Activ8CoursesContentLearningCourseContentChapterSession;
    contentChapter: Activ8CoursesContentLearningCourseContentChapter;
    onClose: () => void;
    initialQuizResults: Activ8CoursesContentQuizResultsLearningCourseContentQuizResult[];
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function LearningCourseProgramSessionContentViewWizard({ contentChapter, programName, chapterIndex, sessionIndex, contentSession, courseProgramId, onClose, initialQuizResults }: LearningCourseProgramSessionContentViewWizardProps) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [quizContentBlockIdInProgress, setQuizContentBlockIdInProgress] = useState<string | undefined>(undefined);
    const [currentQuizQuestionIndex, setCurrentQuizQuestionIndex] = useState<number | undefined>(undefined);
    const [completedQuizIds, setCompletedQuizIds] = useState<string[]>(initialQuizResults ? initialQuizResults.filter(e => e.isCompleted).map(e => e.learningCourseContentQuizId as string) : []);
    const [contentEntryTime, setContentEntryTime] = useState(DateTime.utc());
    const [isNextProgressionBlocked, setIsNextProgressionBlocked] = useState<boolean>(false);
    const preparedContentForParticipantQuery = appApi.endpoints.learningCourseProgramSessionPrepareSessionContent.useQuery({ courseProgramId: courseProgramId, courseProgramChapterId: contentChapter.id as string, courseProgramChapterSessionId: contentSession.id as string }, { refetchOnFocus: true, refetchOnMountOrArgChange: true, refetchOnReconnect: true })
    const methods = useFormContext<LearningCourseProgramSessionContentViewWizardFormData>();
    const theme = useTheme();
    const { handleError } = useApiResponseHandler();
    const [logProgressionThroughBlocksPost] = appApi.endpoints.learningCourseProgramSessionProgressThroughSessionContentBatch.useMutation();

    const [contentPages, setContentPages] = useState<Array<Array<Activ8CoursesContentLearningCourseContentBlock>>>([]);
    const [quizResults, setQuizResults] = useState<Array<Activ8CoursesContentQuizResultsLearningCourseContentQuizResult>>(initialQuizResults);

    useEffect(() => {
        const pages: Array<Array<Activ8CoursesContentLearningCourseContentBlock>> = [];
        if (!preparedContentForParticipantQuery.isLoading && !preparedContentForParticipantQuery.isFetching && preparedContentForParticipantQuery.data && preparedContentForParticipantQuery.data.session && preparedContentForParticipantQuery.data.session.content && preparedContentForParticipantQuery.data.session.content.length > 0) {
            const currentSessionContent = preparedContentForParticipantQuery.data.session.content;
            let currentPage: Array<Activ8CoursesContentLearningCourseContentBlock> = [];
            for (let contentBlockIndex = 0; contentBlockIndex < currentSessionContent.length; contentBlockIndex++) {
                const contentBlock = currentSessionContent[contentBlockIndex];

                if (contentBlock.type !== 'PageBreak') {
                    currentPage.push(contentBlock);
                }

                if (contentBlock.type === 'PageBreak') {
                    // Start new page
                    pages.push([...currentPage]);
                    currentPage = [];
                }
                else if (contentBlockIndex === currentSessionContent.length - 1) {
                    // Last content block, finalise the page
                    pages.push([...currentPage]);
                }
            }
        }

        setContentPages(pages);
    }, [preparedContentForParticipantQuery.isLoading])

    const totalProgressions = contentPages.length;

    const prepareContentResourceReadUrl = async (file: Activ8CoursesContentLearningCourseContentResource, isDownload: boolean): Promise<string> => {
        const preparedUrl = get(preparedContentForParticipantQuery.data?.resourceContentUrls, file.id as string);

        return preparedUrl as string;
    };

    const isLoading = preparedContentForParticipantQuery.isLoading || preparedContentForParticipantQuery.isFetching;
    const currentlyOnLastStep = !isLoading && (currentStepIndex) === totalProgressions;
    const currentlyOnFirstStep = (currentStepIndex) === 0;
    const percentComplete = currentStepIndex === 0 ? 0 : ((currentStepIndex) / totalProgressions) * 100;

    const onQuizOptionSelectToggle = (optionId: string, isSelected: boolean) => {
        let selected = methods.getValues('quizQuestionSelectedOptions') as string[];
        if (isSelected) {
            if (selected.indexOf(optionId) === -1) {
                // add
                selected.push(optionId);
            }
        }
        else {
            if (selected.indexOf(optionId) > -1) {
                // remove
                selected = selected.filter(e => e !== optionId);
            }
        }
        methods.setValue('quizQuestionSelectedOptions', selected);
    }

    const onQuizStart = (quizContentBlock: Activ8CoursesContentLearningCourseContentBlock) => {
        setCurrentQuizQuestionIndex(0);
        setContentEntryTime(DateTime.utc());
        setQuizContentBlockIdInProgress(quizContentBlock.id as string);
        methods.setValue('quizQuestionSelectedOptions', []);
        if (quizContentBlock.quiz?.isAnswerFeedbackShown) {
            setIsNextProgressionBlocked(true);
        }
    }
    const onQuizComplete = (quizContentBlock: Activ8CoursesContentLearningCourseContentBlock) => {
        // console.log('onQuizComplete', [...completedQuizIds, quizContentBlock.id as string])
        setCompletedQuizIds(uniq([...completedQuizIds, quizContentBlock.id as string]));
        setCurrentQuizQuestionIndex(undefined);
        setContentEntryTime(DateTime.utc());
        setQuizContentBlockIdInProgress(undefined);
        methods.setValue('quizQuestionSelectedOptions', []);
    }

    const quizQuestionSelectedOptionsWatch = methods.watch('quizQuestionSelectedOptions') as any[] | undefined;

    const onPreviousClick = async () => {
        setCurrentStepIndex(currentStepIndex - 1);
    }

    const quizContentBlockInProgress = (contentSession.content || []).find(c => c.id === quizContentBlockIdInProgress);
    const quizBlockInProgress = quizContentBlockInProgress?.quiz;
    const lastQuestionInQuizBlockInProgress = quizBlockInProgress && quizBlockInProgress.questions && quizBlockInProgress.questions.length - 1 === currentQuizQuestionIndex;

    const allQuizzesOnPage = (contentPages[currentStepIndex] || []).filter(c => c.type === 'Quiz');
    const isAllQuizzesOnPageComplete = allQuizzesOnPage.length === 0 || difference(allQuizzesOnPage.filter(c => c && c.quiz).map(q => q.quiz?.id), completedQuizIds).length === 0;

    const onNextClick = async () => {
        try {
            await logProgressThroughBlocks();
        }
        catch (err) {
            handleError(err);
        }

        let willProgressToNextStep = true;
        if (isQuizInProgress) {
            if (!lastQuestionInQuizBlockInProgress) {
                if (!isNextProgressionBlocked) {
                    methods.setValue('quizQuestionSelectedOptions', []);
                    setCurrentQuizQuestionIndex((currentQuizQuestionIndex || 0) + 1);

                    if (quizBlockInProgress?.isAnswerFeedbackShown) {
                        setIsNextProgressionBlocked(true);
                    }
                }
                else {
                    willProgressToNextStep = false;
                    setIsNextProgressionBlocked(false);
                }
            }
            else {
                if (!isNextProgressionBlocked) {
                    // Just answered last question in quiz
                    onQuizComplete(quizBlockInProgress);
                    setIsNextProgressionBlocked(false);
                }
                else {
                    willProgressToNextStep = false;
                    setIsNextProgressionBlocked(false);
                }
            }
        }
        else {
            setCurrentStepIndex(currentStepIndex + 1);
        }

        if (willProgressToNextStep) {
            setContentEntryTime(DateTime.utc());
        }
    }

    const logProgressThroughBlocks = async (): Promise<void> => {
        const exitTime = DateTime.utc();

        if (quizBlockInProgress) {
            const result = await logProgressionThroughBlocksPost({
                activ8CoursesProgramsSessionsProgressThroughSessionContentBatchRequest: {
                    requests: [
                        {
                            entryTime: contentEntryTime.toISO() || undefined,
                            exitTime: exitTime.toISO() || undefined,
                            learningCourseProgramId: courseProgramId,
                            learningCourseTemplateContentChapterId: contentChapter.id as string,
                            learningCourseTemplateContentSessionId: contentSession.id as string,
                            learningCourseContentBlockId: quizContentBlockInProgress?.id as string,
                            learningCourseContentQuizId: quizBlockInProgress.id,
                            learningCourseContentQuizQuestionId: currentQuizQuestionIndex != undefined ? (quizContentBlockInProgress?.quiz?.questions || [])[currentQuizQuestionIndex].id : undefined,
                            learningCourseContentQuizQuestionMultipleChoiceOptionIdsSelected: currentQuizQuestionIndex != undefined ? methods.getValues('quizQuestionSelectedOptions') : undefined,
                        }
                    ]
                }
            }).unwrap();

            if (result && result.responses && result.responses.length > 0) {
                const newResults = [...quizResults];

                result.responses.forEach(r => {
                    r.quizResults?.forEach(q => {
                        const existingResultIndex = newResults.findIndex(r => r.learningCourseContentQuizId === q.learningCourseContentQuizId);
                        if (existingResultIndex > -1) {
                            newResults[existingResultIndex] = q;
                        }
                        else {
                            newResults.push(q);
                        }
                    })
                })
                setQuizResults(newResults);
                setIsNextProgressionBlocked(false);
            }
        }
        else {
            const contentBlocksToLog = contentPages[currentStepIndex].filter(e => e.type !== 'Quiz');
            // console.log('heree contentBlocksToLog', contentPages[currentStepIndex], contentPages)
            if (contentBlocksToLog && contentBlocksToLog.length > 0) {
                await logProgressionThroughBlocksPost({
                    activ8CoursesProgramsSessionsProgressThroughSessionContentBatchRequest: {
                        requests: contentBlocksToLog.map(contentBlock => ({
                            entryTime: contentEntryTime.toISO() || undefined,
                            exitTime: exitTime.toISO() || undefined,
                            learningCourseProgramId: courseProgramId,
                            learningCourseTemplateContentChapterId: contentChapter.id as string,
                            learningCourseTemplateContentSessionId: contentSession.id as string,
                            learningCourseContentBlockId: contentBlock?.id as string
                        }))
                    }
                }).unwrap();
            }
        }
    }

    const isQuizInProgress = Boolean(currentQuizQuestionIndex !== undefined && currentQuizQuestionIndex >= 0);

    const isQuizQuestionAnswered = isQuizInProgress ? quizQuestionSelectedOptionsWatch && quizQuestionSelectedOptionsWatch.length > 0 : false;

    return (
        <Dialog
            fullScreen
            open={true}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} color='secondary'>
                <Toolbar>
                    <Stack direction='row' justifyContent={'space-between'} sx={{ width: '100%', p: 1 }} alignItems='center'>
                        <IconButton
                            edge="start"
                            color="default"
                            onClick={onClose}
                            // hidden={isQuizInProgress}
                            sx={isQuizInProgress ? { visibility: 'hidden' } : {}}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Stack direction={'column'} spacing={{ xs: 1, md: 2 }} alignItems={'center'}>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="body2" component="div">
                                {programName}
                            </Typography>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="body2" component="div">
                                {contentChapter.name}
                            </Typography>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
                                {contentSession.name}
                            </Typography>
                        </Stack>
                        <div>
                            <Button color={"inherit"} variant='text' disabled={isLoading || currentlyOnFirstStep || currentlyOnLastStep || isQuizInProgress} onClick={onPreviousClick} startIcon={<Iconify icon='eva:arrow-left-fill' />} sx={{ mr: theme.spacing(2) }}>
                                Back
                            </Button>
                            <Button autoFocus color={isNextProgressionBlocked ? "info" : "primary"} variant='contained' disabled={isLoading || (!isQuizInProgress && !isAllQuizzesOnPageComplete && !isNextProgressionBlocked) || (isQuizInProgress && !isQuizQuestionAnswered)} onClick={currentlyOnLastStep ? onClose : onNextClick} endIcon={currentlyOnLastStep ? <Iconify icon='eva:checkmark-circle-2-outline' /> : <Iconify icon='eva:arrow-right-fill' />}>
                                {lastQuestionInQuizBlockInProgress ? (isNextProgressionBlocked ? 'Check answer' : 'Finish quiz') : (currentlyOnLastStep ? 'Finish' : (isNextProgressionBlocked ? 'Check answer' : 'Next'))}
                            </Button>
                        </div>
                    </Stack>
                </Toolbar>
                <LinearProgress variant="determinate" value={percentComplete} color={currentlyOnLastStep ? "success" : "info"} sx={{ height: '10px', borderRadius: 0, width: '100%' }} />
            </AppBar>

            {/* <code>{JSON.stringify(completedQuizIds)}</code> */}

            {isLoading ? <LoadingScreen preventFullScreen /> :
                <Container sx={{ mt: 5 }}>
                    {/* <code>{JSON.stringify(contentPages)}</code> */}
                    {currentlyOnLastStep ?

                        <Stack direction='column' spacing={2} sx={{ width: '100%' }} alignItems='center' justifyContent='center'>
                            <Typography variant='h3' sx={{ color: theme.palette.success.main }}>All done here!</Typography>
                            <Iconify sx={{ m: 0, p: 0, color: theme.palette.success.main }} icon='ic:baseline-tag-faces' fontSize={90} />
                        </Stack>
                        :

                        <Stack gap={2}>
                            {contentPages[currentStepIndex].map((block, index) => {
                                const isQuizComplete = block.quiz ? completedQuizIds.indexOf(block.quiz.id as string) > -1 : false;

                                return !quizContentBlockIdInProgress || quizContentBlockIdInProgress === block.id ?
                                    <LearningCourseProgramSessionContentBlockView quizResults={quizResults} key={block.id} contentBlock={block} prepareContentResourceReadUrl={prepareContentResourceReadUrl} onQuizStart={onQuizStart} currentQuizQuestionIndex={currentQuizQuestionIndex} onQuizOptionSelectToggle={onQuizOptionSelectToggle} isQuizComplete={isQuizComplete} />
                                    : undefined
                            }
                            )}
                        </Stack>
                    }
                </Container>
            }
        </Dialog>
    )
}