import { Link as RouterLink } from 'react-router-dom';
import appSettings from 'src/appSettings';

import { Box, BoxProps } from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  fullLogo?: boolean;
}

export default function Logo({ disabledLink = false, fullLogo, sx }: Props) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = '/logo/logo_single.svg';

  const logo = (
    <Box sx={{ width: (fullLogo ? '100%' : 40), height: (fullLogo ? 80 : 40), ...sx }}>
      <img alt="logo" src={fullLogo ? appSettings.assetsBaseUrl + '/logo/logo_full.svg' : appSettings.assetsBaseUrl + '/logo/logo_single.svg'} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
