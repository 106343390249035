import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';
import {
    Activ8CoursesContentLearningCourseContentResource,
    Activ8CoursesContentLearningCourseContentResourceType,
    Activ8CoursesTemplatesPrepareResourceOperationResponse
} from 'src/api/redux/app/appApi';
import { RHFTextField } from 'src/components/hook-form';
import RHFRichTextEditor from 'src/components/hook-form/RHFRichTextEditor';
import Iconify from 'src/components/Iconify';
import ChipMenuButton from 'src/sections/shared/ChipMenuButton';
import { ResourceContentManager } from 'src/sections/shared/ResourceContentManager';
import uuidv4 from 'src/utils/uuidv4';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
    Button, Chip, Divider, IconButton, Stack, SxProps, Theme, Tooltip, Typography, useTheme
} from '@mui/material';

import { LearningTemplateFormValuesSchema } from '../../LearningCourseTemplateWizardForm';

export interface ICourseTemplateContentBuilderContentBlockProps {
    courseTemplateId: string;
    sessionIndex: number;
    chapterIndex: number;
    contentIndex: number;
    moveCounter: number;
    isEditing: boolean;
    isLastBlockInPage: boolean;
    isFirstBlockInPage: boolean;
    control: Control<LearningTemplateFormValuesSchema>;
    onClick: (index: number) => void;
    onBlur: (index: number) => void;
    onDeleteContentBlock: (index: number) => void;
    prepareReadUrl: (resource: Activ8CoursesContentLearningCourseContentResource, isDownload: boolean) => Promise<string>;
    prepareUpload: (file: File) => Promise<{ response: Activ8CoursesTemplatesPrepareResourceOperationResponse | null, fileId: string }>;
}
export function CourseTemplateContentBuilderContentBlock({ courseTemplateId, chapterIndex, sessionIndex, contentIndex, isEditing, onClick, onDeleteContentBlock, onBlur, control, prepareReadUrl, prepareUpload, isLastBlockInPage, isFirstBlockInPage, moveCounter }: ICourseTemplateContentBuilderContentBlockProps) {
    const { watch, setValue, getValues } = useFormContext<LearningTemplateFormValuesSchema>();
    const theme = useTheme();
    const [blockContainerStyles, setBlockContainerStyles] = useState<SxProps<Theme>>({});

    const resourcesArrayFieldName = `chapters.${chapterIndex}.sessions.${sessionIndex}.content.${contentIndex}.resources` as 'chapters.0.sessions.0.content.0.resources';
    const { fields, append, remove, update, move } = useFieldArray<LearningTemplateFormValuesSchema>({
        control,
        name: resourcesArrayFieldName
    });
    const resourcesArrayWatch = watch(resourcesArrayFieldName);

    const contentBlockFieldName = `chapters.${chapterIndex}.sessions.${sessionIndex}.content.${contentIndex}` as 'chapters.0.sessions.0.content.0';
    const contentBlockWatch = watch(contentBlockFieldName);
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: contentBlockWatch.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        width: '100%'
    };

    const onResourceDelete = async (resource: Activ8CoursesContentLearningCourseContentResource): Promise<void> => {
        const index = fields.findIndex(e => e.id === resource.id);
        remove(index);
    }
    const onUploadSuccess = async (resource: Activ8CoursesContentLearningCourseContentResource): Promise<void> => {
        append({
            id: resource.id as string,
            type: resource.type as Activ8CoursesContentLearningCourseContentResourceType,
            description: resource.description as string,
            blobReference: resource.blobReference as string,
            fileName: resource.fileName as string,
            contentType: resource.contentType as string,
            fileExtension: resource.fileExtension as string,
            fileSizeBytes: resource.fileSizeBytes as number,
        });
    }
    const onUpdateMetadata = async (resource: Activ8CoursesContentLearningCourseContentResource): Promise<void> => {
        const index = (getValues(resourcesArrayFieldName) || []).findIndex(e => e.id === resource.id);

        update(index, { ...resource } as any);
    }

    const isPageBreakBlock = contentBlockWatch.type === 'PageBreak';
    const pageWrapperContentBorder = `2px solid ${theme.palette.grey[300]}`;
    // const pageWrapperContentBorder = `2px solid red`;
    const pageWrapperContentBorderRadius = `8px`;
    const pageWrapperContentPadding = theme.spacing(2);
    useEffect(() => {
        let newStyles = {} as SxProps<Theme>;
        let wasSet = false;

        if (isPageBreakBlock) {
            newStyles = { pb: theme.spacing(4) };
            wasSet = true;
        }
        else {
            if (isFirstBlockInPage) {
                // First content block in session
                newStyles = { ...newStyles, borderLeft: pageWrapperContentBorder, borderRight: pageWrapperContentBorder, borderTop: pageWrapperContentBorder, pt: pageWrapperContentPadding, borderTopLeftRadius: pageWrapperContentBorderRadius, borderTopRightRadius: pageWrapperContentBorderRadius };
                wasSet = true;
            }
            if (isLastBlockInPage) {
                // Last content block in page
                newStyles = { ...newStyles, borderLeft: pageWrapperContentBorder, borderRight: pageWrapperContentBorder, borderBottom: pageWrapperContentBorder, borderBottomLeftRadius: pageWrapperContentBorderRadius, borderBottomRightRadius: pageWrapperContentBorderRadius };
                wasSet = true;
            }
        }
        if (!wasSet) {
            // Content block in middle of page
            newStyles = { ...newStyles, borderLeft: pageWrapperContentBorder, borderRight: pageWrapperContentBorder, borderTop: 'none', borderBottom: 'none' };
        }

        setBlockContainerStyles(newStyles);
    }, [contentBlockWatch.type, contentIndex, isLastBlockInPage, isFirstBlockInPage, isPageBreakBlock//, moveCounter
    ]);

    return (
        <Stack direction='row' ref={setNodeRef} onClick={() => onClick(contentIndex)} sx={{ pl: pageWrapperContentPadding, pr: pageWrapperContentPadding, pt: theme.spacing(4), pb: theme.spacing(2), ...blockContainerStyles }}>
            <Stack gap={0} alignItems='center' justifyContent={'center'} sx={{ borderRightWidth: (isPageBreakBlock ? 0 : 1), borderRightStyle: 'solid', borderRightColor: isEditing ? theme.palette.primary.main : theme.palette.grey[300], pr: 1, mr: 2 }}>
                <Tooltip title='Move or re-order content block' enterDelay={1000}>
                    <IconButton {...listeners}>
                        <Iconify icon='eva:menu-outline' />
                    </IconButton>
                </Tooltip>
                {!isPageBreakBlock && !isEditing ? <IconButton color='primary' onClick={(e) => { e.stopPropagation(); onClick(contentIndex) }}>
                    <Iconify icon='eva:edit-outline' />
                </IconButton> : undefined}
                {!isPageBreakBlock && isEditing ? <IconButton color='success' onClick={(e) => { e.stopPropagation(); onBlur(contentIndex) }}>
                    <Iconify icon='eva:checkmark-outline' />
                </IconButton> : undefined}
                <Divider orientation='horizontal' flexItem sx={{ bgcolor: theme.palette.grey[300] }} />
                <IconButton sx={{ mt: 2 }} color='error' size='small' onClick={(e) => { e.stopPropagation(); onDeleteContentBlock(contentIndex) }}>
                    <Iconify icon='eva:trash-outline' />
                </IconButton>
            </Stack>
            <div style={style} {...attributes}>
                {/* <div>
                    <div>isPageBreakBlock = {isPageBreakBlock.toString()}</div>
                    <div>isFirstBlockInPage = {isFirstBlockInPage.toString()}</div>
                    <div>isLastBlockInPage = {isLastBlockInPage.toString()}        </div>
                    <div>moveCounter = {moveCounter.toString()}</div>
                </div> */}
                {
                    contentBlockWatch.type === 'RichText' ? <RHFRichTextEditor name={`${contentBlockFieldName}.richText`} readOnly={!isEditing} /> : undefined
                }
                {
                    contentBlockWatch.type === 'Resources' || contentBlockWatch.type === 'Media' ?
                        <ResourceContentManager
                            defaultValue={resourcesArrayWatch || []}
                            prepareUpload={(file: File) => prepareUpload(file)}
                            hideDropzone={!isEditing}
                            restrictedToMedia={contentBlockWatch.type === 'Media'}
                            direction='row'
                            prepareReadUrl={(file: Activ8CoursesContentLearningCourseContentResource, isDownload: boolean) => prepareReadUrl(file, isDownload)}
                            onDelete={(resource: Activ8CoursesContentLearningCourseContentResource) => onResourceDelete(resource)}
                            onUploadSuccess={(resource: Activ8CoursesContentLearningCourseContentResource) => onUploadSuccess(resource)}
                            onUpdateMetadata={(resource: Activ8CoursesContentLearningCourseContentResource) => onUpdateMetadata(resource)} />
                        : undefined
                }
                {
                    contentBlockWatch.type === 'Quiz' ?
                        <CourseTemplateQuizBuilder {...{ courseTemplateId, sessionIndex, chapterIndex, contentIndex, isEditing, control }} />
                        : undefined
                }
                {
                    contentBlockWatch.type === 'PageBreak' ? <Typography {...listeners} variant='body1' sx={{ textAlign: 'center', height: '100%', marginTop: '26px', color: theme.palette.grey[400] }}>Page break</Typography> : undefined
                }
            </div>
        </Stack>
    )
}

interface CourseTemplateQuizBuilderProps {
    courseTemplateId: string;
    sessionIndex: number;
    chapterIndex: number;
    contentIndex: number;
    isEditing: boolean;
    control: Control<LearningTemplateFormValuesSchema>;
}

function CourseTemplateQuizBuilder({ courseTemplateId, sessionIndex, chapterIndex, contentIndex, isEditing, control }: CourseTemplateQuizBuilderProps) {
    const { watch, setValue, getValues } = useFormContext<LearningTemplateFormValuesSchema>();
    const theme = useTheme();
    const confirm = useConfirm();

    const quizFieldName = `chapters.${chapterIndex}.sessions.${sessionIndex}.content.${contentIndex}.quiz` as 'chapters.0.sessions.0.content.0.quiz';

    const isAssessedFieldName = `${quizFieldName}.isAssessed` as 'chapters.0.sessions.0.content.0.quiz.isAssessed';
    const isAnswerFeedbackShownFieldName = `${quizFieldName}.isAnswerFeedbackShown` as 'chapters.0.sessions.0.content.0.quiz.isAnswerFeedbackShown';
    const isAssessedWatch = watch(isAssessedFieldName);
    const isAnswerFeedbackShownWatch = watch(isAnswerFeedbackShownFieldName);
    const descriptionFieldName = `${quizFieldName}.description` as 'chapters.0.sessions.0.content.0.quiz.description';
    const descriptionWatch = watch(descriptionFieldName);

    const questionsArrayFieldName = `${quizFieldName}.questions` as 'chapters.0.sessions.0.content.0.quiz.questions';
    const { fields, append, remove, update, move } = useFieldArray<LearningTemplateFormValuesSchema>({
        control,
        name: questionsArrayFieldName
    });
    const questionsArrayWatch = watch(questionsArrayFieldName);

    const addQuestion = (): void => {
        append({
            id: uuidv4(),
            questionText: '',
            choiceOptions: [
                {
                    id: uuidv4(),
                    answerText: '',
                    isCorrect: true,
                },
                {
                    id: uuidv4(),
                    answerText: '',
                    isCorrect: false,
                }
            ]
        })
    }

    const deleteQuestion = (index: number) => {
        const toDelete = (questionsArrayWatch || [])[index];

        if (!toDelete.questionText && (!toDelete.choiceOptions || toDelete.choiceOptions.length === 0 || toDelete.choiceOptions.filter(e => e.answerText && true).length === 0)) {
            remove(index);
            return;
        }

        confirm({ description: `Are you sure you want to delete the question?` })
            .then(() => {
                remove(index);
            });
    }

    return (
        <Stack gap={isEditing ? 3 : 1}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <RHFTextField sx={{ maxWidth: '300px' }} name={descriptionFieldName} variant='standard' placeholder='Quiz title' />
                <Stack direction='row' gap={1}>
                    <Tooltip enterDelay={500} title='Whether to mark this quiz as assessed. If assessed this quiz will contribute to "online module scores".'>
                        <Chip
                            sx={{ width: '136px' }}
                            key={isAssessedWatch + '1'}
                            label={isAssessedWatch === true ? 'Assessed' : 'Not assessed'}
                            variant={'outlined'}
                            color={isAssessedWatch === true ? 'success' : undefined}
                            id={'session-' + sessionIndex + '-isAssessed'}
                            icon={isAssessedWatch === true ? <Iconify fontSize={20} icon='eva:award-fill' /> : <Iconify fontSize={20} icon='eva:award-outline' />}
                            onClick={(e) => { e.stopPropagation(); setValue(isAssessedFieldName, isAssessedWatch ? false : true); }}
                        />
                    </Tooltip>
                    <Tooltip enterDelay={500} title='Whether feedback should be provided to participants immediately after each question has been answered.'>
                        <Chip
                            sx={{ width: '136px' }}
                            key={isAnswerFeedbackShownWatch + '1'}
                            label={isAnswerFeedbackShownWatch === true ? 'Feedback' : 'No feedback'}
                            variant={'outlined'}
                            color={isAnswerFeedbackShownWatch === true ? 'success' : undefined}
                            id={'session-' + sessionIndex + '-isAnswerFeedbackShown'}
                            icon={isAnswerFeedbackShownWatch === true ? <Iconify fontSize={20} icon='eva:flag-fill' /> : <Iconify fontSize={20} icon='eva:flag-outline' />}
                            onClick={(e) => { e.stopPropagation(); setValue(isAnswerFeedbackShownFieldName, isAnswerFeedbackShownWatch ? false : true); }}
                        />
                    </Tooltip>
                </Stack>
            </Stack>
            <Stack sx={{ pl: 2 }} gap={isEditing ? 3 : 2} divider={<Divider orientation="horizontal" flexItem />}>
                {
                    (questionsArrayWatch || []).map((q, index) =>
                        <CourseTemplateQuizQuestionBuilder key={q.id} questionIndex={index} onDelete={(index) => deleteQuestion(index)} {...{ courseTemplateId, sessionIndex, chapterIndex, contentIndex, isEditing, control }} />
                    )
                }
                {isEditing ? <div style={{ margin: '0 auto' }}><Button variant='outlined' size='small' onClick={() => addQuestion()}>Add question</Button></div> : undefined}
            </Stack>
        </Stack>
    )
}

interface ICourseTemplateQuizQuestionBuilderProps {
    courseTemplateId: string;
    sessionIndex: number;
    chapterIndex: number;
    contentIndex: number;
    questionIndex: number;
    onDelete: (index: number) => void;
    isEditing: boolean;
    control: Control<LearningTemplateFormValuesSchema>;
}

function CourseTemplateQuizQuestionBuilder({ courseTemplateId, sessionIndex, chapterIndex, contentIndex, questionIndex, isEditing, onDelete, control }: ICourseTemplateQuizQuestionBuilderProps) {
    const { watch, setValue, getValues } = useFormContext<LearningTemplateFormValuesSchema>();
    const theme = useTheme();
    const confirm = useConfirm();
    const questionFieldName = `chapters.${chapterIndex}.sessions.${sessionIndex}.content.${contentIndex}.quiz.questions.${questionIndex}` as 'chapters.0.sessions.0.content.0.quiz.questions.0';

    const questionTextFieldName = `${questionFieldName}.questionText` as 'chapters.0.sessions.0.content.0.quiz.questions.0.questionText';
    const questionTextWatch = watch(questionTextFieldName);

    const choiceOptionsArrayFieldName = `${questionFieldName}.choiceOptions` as 'chapters.0.sessions.0.content.0.quiz.questions.0.choiceOptions';
    const { fields, append, remove, update, move } = useFieldArray<LearningTemplateFormValuesSchema>({
        control,
        name: choiceOptionsArrayFieldName
    });
    const choiceOptionsArrayWatch = watch(choiceOptionsArrayFieldName);

    const addChoiceOption = () => {
        append({
            id: uuidv4(),
            answerText: '',
            isCorrect: false,
        })
    }

    const deleteChoiceOption = (index: number) => {
        const toDelete = (choiceOptionsArrayWatch || [])[index];
        if (!toDelete.answerText) {
            remove(index);
            return;
        }

        confirm({ description: `Are you sure you want to delete the answer?` })
            .then(() => {
                remove(index);
            });
    }

    return (
        <Stack gap={1}>
            <Stack direction='row' justifyContent={isEditing ? 'space-between' : 'flex-start'} alignItems='center' columnGap={2}>
                <Typography variant='body2'>Q{questionIndex + 1}.</Typography>
                {isEditing ? <RHFTextField name={questionTextFieldName} variant='standard' placeholder='Question text' /> : <Typography variant='body2'>{questionTextWatch}</Typography>}
                {isEditing ? <IconButton size='small' color='error' onClick={() => onDelete(questionIndex)}><Iconify icon='eva:trash-outline' /></IconButton> : undefined}
            </Stack>

            <Stack gap={0} sx={{ ml: 5, mr: 5 }}>
                {(choiceOptionsArrayWatch || []).map((c, index) => {
                    const answerTextFieldName = `${choiceOptionsArrayFieldName}.${index}.answerText` as 'chapters.0.sessions.0.content.0.quiz.questions.0.choiceOptions.0.answerText';
                    const answerTextWatch = watch(answerTextFieldName);
                    const isCorrectFieldName = `${choiceOptionsArrayFieldName}.${index}.isCorrect` as 'chapters.0.sessions.0.content.0.quiz.questions.0.choiceOptions.0.isCorrect';
                    const isCorrectWatch = watch(isCorrectFieldName);

                    return (
                        <Stack key={c.id} direction='row' justifyContent={isEditing ? 'space-between' : 'flex-start'} alignItems='center' columnGap={1}>
                            <div style={{ width: '35px' }}><Typography variant='body2'>A{index + 1}.</Typography></div>
                            <Tooltip arrow enterDelay={500} title={<Stack gap={2}><p>Set whether this answer is considered correct or not.</p><p>Answers will be delivered in a random order when participants are shown the quiz so no need to randomise the positioning of the correct answer per question</p></Stack>}>
                                <IconButton
                                    // sx={{width: '130px'}}
                                    key={isCorrectWatch + '1'}
                                    // variant={'outlined'}
                                    color={isCorrectWatch === true ? 'success' : undefined}
                                    id={'session-' + sessionIndex + '-duration'}
                                    onClick={(e) => { e.stopPropagation(); setValue(isCorrectFieldName, isCorrectWatch ? false : true); }}
                                >{isCorrectWatch === true ? <Iconify icon='eva:checkmark-circle-2-fill' /> : <Iconify icon='eva:close-circle-outline' />}</IconButton>
                            </Tooltip>
                            {isEditing ? <RHFTextField name={answerTextFieldName} variant='standard' placeholder='Answer text' /> : <Typography variant='body2'>{answerTextWatch}</Typography>}
                            {isEditing ? <IconButton size='small' color='error' onClick={() => deleteChoiceOption(index)}><Iconify icon='eva:trash-outline' /></IconButton> : undefined}
                        </Stack>
                    )
                })}

                {isEditing ? <Button variant='text' onClick={addChoiceOption} size='small'>Add answer</Button> : undefined}
            </Stack>
        </Stack>
    )
}