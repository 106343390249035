import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { accountApi } from 'src/api/redux/account/accountApi';
import { appApi } from 'src/api/redux/app/appApi';
import appSettings from 'src/appSettings';

import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const auth = useAuth();
  const configQuery = appApi.endpoints.abpApplicationConfigurationGet.useQuery();
  const profileQuery = accountApi.endpoints.profileGet.useQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  let currentSearchParams = new URLSearchParams(searchParams.toString());
  const resetimpersonate = currentSearchParams.get('resetimpersonate') === 'true';
  const isImpersonationRefreshPending = localStorage.getItem('is_impersonate_refresh_pending') === '1' && !resetimpersonate;

  useEffect(() => {
    configQuery.refetch();
  }, [auth, profileQuery])

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (resetimpersonate) {
    localStorage.removeItem('is_impersonate_refresh_pending');
  }

  if (isImpersonationRefreshPending) {
    let navigateToUrl = appSettings.selfUrl;

    window.location.href = navigateToUrl + '?resetimpersonate=true';
    return <LoadingScreen />
  }

  // if (!auth.user || auth.isLoading) {
  //   return <LoadingScreen />;
  // }

  // if (!isAuthenticated) {
  //   if (pathname !== requestedLocation) {
  //     setRequestedLocation(pathname);
  //   }
  //   return <Login />;
  // }

  if (requestedLocation && pathname !== requestedLocation && auth.isAuthenticated) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <>
        <LoadingScreen />
      </>;
    case "signoutRedirect":
      return <>
        <LoadingScreen />
      </>;
  }

  if (auth.isLoading || configQuery?.isLoading === true || profileQuery?.isLoading === true) {
    return <LoadingScreen />;
  }

  // if (auth.error) {
  //     return <div>Oops... {auth.error.message}</div>;
  // } 

  if (auth.isAuthenticated) {
    return <>{children}</>;
  }

  // return <>{children}</>;

  let updatedSearchParams = new URLSearchParams(searchParams.toString());
  const currentRedirectCount = searchParams.get('rca') ? (parseInt(searchParams.get('rca') as string) + 1) : 1;
  if (currentRedirectCount <= 5) {
    updatedSearchParams.set('rca', currentRedirectCount.toString());
    setSearchParams(updatedSearchParams.toString());

    if (profileQuery.isError) {
      auth.removeUser()
        .then(() => auth.revokeTokens())
        // .then(() => auth.signoutRedirect({ id_token_hint: auth.user?.id_token }))
        .then(() => auth.signinRedirect());
    }
    else {
      auth.signinRedirect();
    }
  }

  // return <Navigate to={'/app/dashboard'} />;
  return <LoadingScreen />;
  // return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
}
