import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Activ8FacilitatorsFacilitatorDto, appApi } from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import { FormProvider } from 'src/components/hook-form';
import RHFTenantMultiSelect from 'src/components/hook-form/RHFTenantMultiSelect';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useAppUserContext from 'src/hooks/useAppUserContext';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import { fDateTime } from 'src/utils/formatTime';
import useImpersonation from 'src/utils/impersonation';

import {
    Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton, Stack, Tooltip, Typography
} from '@mui/material';

type UserMembershipInfoCardProps = {
    facilitator: Activ8FacilitatorsFacilitatorDto;
    onRefetch: () => void;
}

type FormData = {
    selectedTenantIdToShareWith: string;
};

export function UserMembershipInfoCard({ facilitator, onRefetch }: UserMembershipInfoCardProps) {
    const userLinkages = appApi.endpoints.userLinkageGetAllUserLinkages.useQuery({ userId: facilitator.id, tenantId: facilitator.tenantId as string }, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
    const permissionChecker = usePermissionChecker();
    const canImpersonate = permissionChecker.has(permissionKeys.tenant.identity.impersonateUser);
    const appUserContext = useAppUserContext();

    const { impersonate, isImpersonatingTenant } = useImpersonation();
    const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const { handleError } = useApiResponseHandler();
    const canShareFacilitatorsWithOtherPartners = permissionChecker.has(permissionKeys.master.shareFacilitatorsWithOtherPartners);
    const [userLinkageShareUserWithAnotherTenantPost] = appApi.endpoints.userLinkageShareUserWithAnotherTenant.useMutation();

    const methods = useForm<FormData>({
        defaultValues: {
            selectedTenantIdToShareWith: ''
        }
    });

    const triggerImpersonationTenant = async (tenantId: string, userId: string) => {
        await impersonate(tenantId, undefined, undefined, userId);
    }

    const impersonateUser = async (tenantId: string, userId: string) => {
        await impersonate(tenantId, userId);
    }

    const selectedTenantIdToShareWithWatch = methods.watch('selectedTenantIdToShareWith');
    const completeShareWithPartner = () => {
        if (!selectedTenantIdToShareWithWatch) return;

        confirm({ description: `Are you sure you would like to share this user with the selected partner?` })
            .then(async () => {

                try {
                    await userLinkageShareUserWithAnotherTenantPost({
                        activ8UsersShareUserWithAnotherPartnerRequest: {
                            destinationUserRole: 'facilitator',
                            destinationTenantId: selectedTenantIdToShareWithWatch,
                            sourceIdentityUserId: facilitator?.id,
                            sourceTenantId: facilitator?.tenantId as string
                        }
                    }).unwrap();
                    enqueueSnackbar(`Shared successfully!`);
                    setIsShareDialogOpen(false);
                    methods.setValue('selectedTenantIdToShareWith', '');
                    onRefetch();
                    userLinkages.refetch();
                }
                catch (error) {
                    handleError(error);
                }
            });
    }

    const { isLoading } = userLinkages;

    return (
        isLoading ? <LoadingScreen /> :
            <PermissionBasedGuard permissionNameKeys={[permissionKeys.tenant.facilitator.manage]}>
                <FormProvider methods={methods}>
                    <Dialog
                        open={isShareDialogOpen}
                        onClose={() => { setIsShareDialogOpen(false); methods.setValue('selectedTenantIdToShareWith', '') }}
                    >
                        <>
                            <DialogTitle>Share user with another partner</DialogTitle>
                            <DialogContent>
                                <RHFTenantMultiSelect
                                    preventMultiple={true}
                                    name="selectedTenantIdToShareWith"
                                    placeholder='Search for a partner...'
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => { setIsShareDialogOpen(false); methods.setValue('selectedTenantIdToShareWith', '') }}>Cancel</Button>
                                <Button disabled={!selectedTenantIdToShareWithWatch} onClick={() => completeShareWithPartner()}>Assign</Button>
                            </DialogActions>
                        </>
                    </Dialog>
                </FormProvider>

                <Card>
                    <CardContent sx={{ p: 1.5 }}>
                        <Stack gap={1}>
                            <Typography>Membership</Typography>
                            <div>
                                {
                                    (userLinkages?.data || []).map(l =>
                                        <Stack key={l.user?.id} direction='row' justifyContent='space-between'>
                                            <Tooltip title={'Member from ' + fDateTime(l.user?.creationTime as string)} placement='top'>
                                                <Typography variant='body2'>{l.tenant?.extraProperties ? l.tenant?.extraProperties['TenantDescription'] : l.tenant?.name}</Typography>
                                            </Tooltip>

                                            {!canImpersonate || appUserContext?.currentTenant?.id || l.tenant?.id === appUserContext?.currentTenant?.id ? undefined :
                                                <div>
                                                    <IconButton
                                                        size='small'
                                                        title='View in partner portal'
                                                        onClick={() => triggerImpersonationTenant(l.tenant?.id as string, l.user?.id as string)}
                                                    // startIcon={<Iconify icon={'eva:arrow-forward-outline'} />}
                                                    >
                                                        <Iconify icon={'eva:arrow-forward-outline'} />
                                                    </IconButton>


                                                    <IconButton
                                                        title='Impersonate'
                                                        size='small'
                                                        onClick={() => impersonateUser(l.tenant?.id as string, l.user?.id as string)}
                                                    // startIcon={<Iconify icon={'eva:unlock-fill'} />}
                                                    >
                                                        <Iconify icon={'eva:unlock-fill'} />
                                                    </IconButton>
                                                </div>
                                            }
                                        </Stack>
                                    )
                                }
                            </div>
                        </Stack>
                    </CardContent>
                    {!canShareFacilitatorsWithOtherPartners ? undefined :
                        <CardActions>
                            <Button
                                color='info'
                                variant='text'
                                size='small'
                                onClick={() => setIsShareDialogOpen(true)}
                                sx={{ mr: 2 }}
                                startIcon={<Iconify icon={'eva:share-outline'} />}
                            >
                                Share with another partner
                            </Button>
                        </CardActions>}
                </Card>
            </PermissionBasedGuard>
    )
}