// sections
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { appApi } from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import Iconify from 'src/components/Iconify';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useAppUserContext from 'src/hooks/useAppUserContext';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import FacilitatorOverviewDashboard from 'src/sections/Facilitator/FacilitatorOverviewDashboard';
import useImpersonation from 'src/utils/impersonation';

// @mui
import { Button, Container } from '@mui/material';

// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
// hooks
import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

export default function FacilitatorOverview() {
  const { themeStretch } = useSettings();
  const { id = '' } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const appUserContext = useAppUserContext();
  const { handleError } = useApiResponseHandler();
  const { enqueueSnackbar } = useSnackbar();
  const permissionChecker = usePermissionChecker();
  const { impersonate, isImpersonatingTenant } = useImpersonation();
  const canImpersonate = permissionChecker.has(permissionKeys.tenant.identity.impersonateUser);
  const canManageFacilitators = permissionChecker.has(permissionKeys.tenant.facilitator.manage);
  const [userDeletePost] = appApi.endpoints.facilitatorDelete.useMutation();

  const getFacilitatorQuery = appApi.endpoints.facilitatorGet.useQuery({
    id: id
  },
    {
      refetchOnMountOrArgChange: true
    });

  const currentFacilitator = getFacilitatorQuery && !getFacilitatorQuery.isLoading && getFacilitatorQuery.data ? getFacilitatorQuery.data : undefined;

  const facilitatorName = currentFacilitator?.name + ' ' + (currentFacilitator?.surname || '');

  const onRefetch = () => {
    getFacilitatorQuery.refetch();
  }

  const impersonateUser = async () => {
    await impersonate(currentFacilitator?.tenantId as string, id);
  }

  const handleDeleteRow = () => {
    confirm({ description: `Are you sure you want to delete the facilitator?` })
      .then(async () => {
        try {
          await userDeletePost({ id: id }).unwrap();
          navigate(PATH_DASHBOARD.tenant.facilitator.list);
          enqueueSnackbar('Deleted facilitator');
        }
        catch (error) {
          handleError(error);
        }
      });
  };

  return (
    <Page title={facilitatorName}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={facilitatorName}
          links={[
            { name: 'Facilitators', href: PATH_DASHBOARD.tenant.facilitator.list },
            { name: facilitatorName },
          ]}
          action={
            appUserContext?.currentTenant?.id ?
              <>
                {
                  !canManageFacilitators ? undefined :
                    <Button
                      variant="outlined"
                      color='error'
                      startIcon={<Iconify icon={'eva:trash-fill'} />}
                      onClick={() => handleDeleteRow()}
                      sx={{ mr: 5 }}
                    >
                      Delete
                    </Button>
                }
                {
                  !canManageFacilitators ? undefined :
                    <Button
                      variant="contained"
                      component={RouterLink}
                      to={PATH_DASHBOARD.tenant.facilitator.edit(id)}
                      startIcon={<Iconify icon={'eva:edit-fill'} />}
                    >
                      Edit profile
                    </Button>
                }
                {!canImpersonate ? undefined :
                  <Button
                    color='info'
                    sx={{ ml: 5 }}
                    onClick={() => impersonateUser()}
                    startIcon={<Iconify icon={'eva:unlock-fill'} />}
                  >
                    Impersonate
                  </Button>
                }
              </> : undefined
          }
        />

        {
          (!getFacilitatorQuery?.isLoading && currentFacilitator) ?
            <FacilitatorOverviewDashboard onRefetch={onRefetch} facilitator={currentFacilitator} />
            : undefined}
      </Container>
    </Page>
  );
}
