// sections
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { appApi } from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import Iconify from 'src/components/Iconify';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useAppUserContext from 'src/hooks/useAppUserContext';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import ParticipantOverviewDashboard from 'src/sections/participant/ParticipantOverviewDashboard';
import useImpersonation from 'src/utils/impersonation';

// @mui
import { Button, Container } from '@mui/material';

// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
// hooks
import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

export default function ParticipantOverview() {
  const { themeStretch } = useSettings();
  const { id = '' } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { impersonate } = useImpersonation();

  const appUserContext = useAppUserContext();
  const { handleError } = useApiResponseHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [userDeletePost] = appApi.endpoints.participantDelete.useMutation();
  const permissionChecker = usePermissionChecker();
  const canManageParticipants = permissionChecker.has(permissionKeys.tenant.participant.manage);
  const canImpersonate = permissionChecker.has(permissionKeys.tenant.identity.impersonateUser);

  const getParticipantQuery = appApi.endpoints.participantGet.useQuery({
    id: id
  },
    {
      refetchOnMountOrArgChange: true
    });

  const currentParticipant = getParticipantQuery && !getParticipantQuery.isLoading && getParticipantQuery.data ? getParticipantQuery.data : undefined;

  const participantName = currentParticipant?.name + ' ' + (currentParticipant?.surname || '');

  const handleDeleteRow = () => {
    confirm({ description: `Are you sure you want to delete the participant?` })
      .then(async () => {
        try {
          await userDeletePost({ id: id }).unwrap();
          navigate(PATH_DASHBOARD.tenant.participant.list);
          enqueueSnackbar('Deleted participant');
        }
        catch (error) {
          handleError(error);
        }
      });
  };

  const triggerImpersonationTenant = async () => {
    await impersonate(currentParticipant?.tenantId as string, undefined, id);
  }

  const impersonateUser = async () => {
    await impersonate(currentParticipant?.tenantId as string, id);
  }

  return (
    <Page title={participantName}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={participantName}
          links={[
            { name: 'Participants', href: PATH_DASHBOARD.tenant.participant.list },
            { name: participantName },
          ]}
          action={
            appUserContext?.currentTenant?.id ?
              <>
                {
                  !canManageParticipants ? undefined :
                    <Button
                      variant="outlined"
                      color='error'
                      startIcon={<Iconify icon={'eva:trash-fill'} />}
                      onClick={() => handleDeleteRow()}
                      sx={{ mr: 5 }}
                    >
                      Delete
                    </Button>}
                {
                  !canManageParticipants ? undefined :
                    <Button
                      variant="contained"
                      component={RouterLink}
                      to={PATH_DASHBOARD.tenant.participant.edit(id)}
                      startIcon={<Iconify icon={'eva:edit-fill'} />}
                    >
                      Edit profile
                    </Button>}
                {!canImpersonate ? undefined :
                  <Button
                    color='info'
                    sx={{ ml: 5 }}
                    onClick={() => impersonateUser()}
                    startIcon={<Iconify icon={'eva:unlock-fill'} />}
                  >
                    Impersonate
                  </Button>
                }
              </> :
              <>
                <Button
                  variant="contained"
                  onClick={triggerImpersonationTenant}
                  startIcon={<Iconify icon={'eva:arrow-forward-outline'} />}
                >
                  View in partners portal
                </Button>
              </>
          }
        />

        {
          (!getParticipantQuery?.isLoading && currentParticipant) ?
            <ParticipantOverviewDashboard participant={currentParticipant} />
            : undefined}
      </Container>
    </Page>
  );
}
