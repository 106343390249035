import { kebabCase } from 'change-case';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { appApi } from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useAppUserContext from 'src/hooks/useAppUserContext';
import useCurrentUser from 'src/hooks/useCurrentUser';
import usePermissionChecker from 'src/hooks/usePermissionChecker';

import {
  Alert, Box, Button, Card, Container, IconButton, Stack, Table, TableBody, TableContainer,
  TablePagination, Tooltip, Typography
} from '@mui/material';

import { UserManager } from '../../@types/user';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import {
  TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions
} from '../../components/table';
import useSettings from '../../hooks/useSettings';
import useTable, { emptyRows } from '../../hooks/useTable';
import { PATH_DASHBOARD } from '../../routes/paths';
import { UserTableRow, UserTableToolbar } from '../../sections/@dashboard/user/list';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'inactive'];

// ----------------------------------------------------------------------

export default function FacilitatorList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const confirm = useConfirm();

  const navigate = useNavigate();

  const { handleError } = useApiResponseHandler();

  const appUserContext = useAppUserContext();
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const permissionChecker = usePermissionChecker();
  const hasExportPermission = permissionChecker.has(permissionKeys.tenant.facilitator.export);
  const [triggerExportPost] = appApi.endpoints.dataExportTriggerFacilitatorExportEmail.useMutation();

  const triggerExport = async () => {
    confirm({
      description:
        <Stack gap={3}>
          <Typography variant='body1'>{`Are you sure you want to trigger an export?`}</Typography>
          <Alert severity='info'>An email will be sent to {appUserContext?.currentUser?.email} in a few minutes which will contain the export of all facilitators.</Alert>
        </Stack>
    })
      .then(async () => {
        try {
          await triggerExportPost(undefined).unwrap();
          enqueueSnackbar('Export triggered!');
        }
        catch (error) {
          handleError(error);
        }
      });
  }

  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [userDeletePost] = appApi.endpoints.facilitatorDelete.useMutation();
  const [userUpdatePost] = appApi.endpoints.facilitatorUpdate.useMutation();
  const userListQuery = appApi.endpoints.facilitatorGetList.useQuery({
    sorting: (orderBy || 'name') + ' ' + (order || 'asc'),
    skipCount: page * rowsPerPage,
    maxResultCount: rowsPerPage,
    filter: filterName,
    notActive: filterStatus === 'all' ? undefined : (filterStatus === 'inactive' ? true : false)
  },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true
    });

  const tableData = userListQuery.data?.items?.map((a) => ({
    id: a.id,
    avatarUrl: undefined,
    name: a.name,
    userName: a.userName,
    lastName: a.surname,
    email: a.email,
    phoneNumber: a.phoneNumber,
    address: undefined,
    isActive: a.isActive,
    lockoutEnabled: a.lockoutEnabled,
    roleNames: a.roleNames,
    organizationUnitIds: [],
    concurrencyStamp: a.concurrencyStamp,
    creationTime: a.creationTime,
    tenantId: a.tenantId,
    postcode: a?.extraProperties ? a?.extraProperties['Postcode'] : null,
    countryCode: a?.extraProperties ? a?.extraProperties['CountryCode'] : null,
    isPartialUser: a?.extraProperties ? a?.extraProperties['IsPartialUser'] === true : null,
  } as UserManager)) || [];

  // console.log("heree", tableData)

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(event.target.value);
    setPage(0);
  };

  const handleDeleteRow = (id: string) => {
    confirm({ description: `Are you sure you want to delete the facilitator?` })
      .then(async () => {
        try {
          await userDeletePost({ id: id }).unwrap();
          setSelected([]);
          userListQuery.refetch();
        }
        catch (error) {
          handleError(error);
        }
      });
  };

  const handleDeleteRows = (selected: string[]) => {
    confirm({ description: `Are you sure you want to delete the selected facilitator${selected.length > 1 ? 's' : ''}?` })
      .then(async () => {
        try {
          const promises = selected.filter(e => e !== currentUser.id).map(id => userDeletePost({ id: id }).unwrap());
          await Promise.all(promises);
          setSelected([]);
          userListQuery.refetch();
        }
        catch (error) {
          handleError(error);
        }
      });
  };


  const handleActivateRow = (id: string) => {
    confirm({ description: `Are you sure you want to activate the facilitator?` })
      .then(async () => {
        try {
          const selectedUser = tableData.find(e => e.id === id) as UserManager;

          await userUpdatePost({
            id: id,
            activ8FacilitatorsFacilitatorUpdateDto: {
              userName: selectedUser.userName,
              name: selectedUser.name,
              surname: selectedUser.lastName,
              email: selectedUser.email,
              phoneNumber: selectedUser.phoneNumber,
              lockoutEnabled: selectedUser.lockoutEnabled,
              roleNames: selectedUser.roleNames,
              organizationUnitIds: selectedUser.organizationUnitIds,
              isActive: true
            }
            // export type VoloSaasHostDtosSaasTenantUpdateDto = {
            //   extraProperties?: {
            //     [key: string]: any;
            //   } | null;
            //   name: string;
            //   editionId?: string | null;
            //   activationState?: VoloSaasTenantActivationState;
            //   activationEndDate?: string | null;
            //   editionEndDateUtc?: string | null;
            //   concurrencyStamp?: string | null;
            // };
          }).unwrap();
          setSelected([]);
          userListQuery.refetch();
        }
        catch (error) {
          handleError(error);
        }
      });
  };

  const handleDeactivateRow = (id: string) => {
    confirm({ description: `Are you sure you want to deactivate the facilitator?` })
      .then(async () => {
        try {
          const selectedUser = tableData.find(e => e.id === id) as UserManager;

          await userUpdatePost({
            id: id,
            activ8FacilitatorsFacilitatorUpdateDto: {
              userName: selectedUser.userName,
              name: selectedUser.name,
              surname: selectedUser.lastName,
              email: selectedUser.email,
              phoneNumber: selectedUser.phoneNumber,
              lockoutEnabled: selectedUser.lockoutEnabled,
              roleNames: selectedUser.roleNames,
              organizationUnitIds: selectedUser.organizationUnitIds,
              isActive: false
            }
            // export type VoloSaasHostDtosSaasTenantUpdateDto = {
            //   extraProperties?: {
            //     [key: string]: any;
            //   } | null;
            //   name: string;
            //   editionId?: string | null;
            //   activationState?: VoloSaasTenantActivationState;
            //   activationEndDate?: string | null;
            //   editionEndDateUtc?: string | null;
            //   concurrencyStamp?: string | null;
            // };
          }).unwrap();
          setSelected([]);
          userListQuery.refetch();
        }
        catch (error) {
          handleError(error);
        }
      });
  };

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.tenant.facilitator.edit(kebabCase(id)));
  };

  const handleOverviewRow = (id: string) => {
    navigate(PATH_DASHBOARD.tenant.facilitator.overview(kebabCase(id)));
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !tableData || tableData.length === 0;

  const totalCount = (userListQuery?.data?.totalCount || 0)

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    // { id: 'role', label: 'Role', align: 'left' }
    // { id: 'status', label: 'Status', align: 'left' },    
  ];

  TABLE_HEAD.push({ id: 'isActive', label: 'Active', align: 'center' },);
  if (appUserContext?.currentTenant?.id) {
    TABLE_HEAD.push({ id: '', label: '', align: '' });
  }

  return (
    <Page title="Facilitators">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Facilitators"
          links={[
            // { name: 'Facilitators' },
          ]}
          action={
            <>
              {
                !hasExportPermission ? undefined :
                  <Button
                    variant="text"
                    startIcon={<Iconify icon={'eva:cloud-download-outline'} />}
                    onClick={triggerExport}
                    sx={{ mr: 4 }}
                  >
                    Export
                  </Button>
              }
              {
                appUserContext?.currentTenant?.id ? <Button
                  variant="contained"
                  component={RouterLink}
                  to={PATH_DASHBOARD.tenant.facilitator.new}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                  New facilitator
                </Button> : undefined
              }
            </>
          }
        />

        <Card>
          <UserTableToolbar
            filterName={filterName}
            filterStatus={filterStatus}
            appUserContext={appUserContext}
            onFilterName={handleFilterName}
            onFilterStatus={handleFilterStatus}
            optionsStatus={STATUS_OPTIONS}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={totalCount}
                  onSelectAllRows={appUserContext?.currentTenant?.id ? (checked) =>
                    onSelectAllRows(
                      !checked,
                      tableData.filter(e => e.id !== currentUser.id).map((row) => row.id)
                    )
                    : undefined}
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalCount}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={appUserContext?.currentTenant?.id ? (checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                    : undefined}
                />

                <TableBody>
                  {tableData
                    .map((row) => (
                      <UserTableRow
                        key={row.id}
                        row={row}
                        allowUserActions={Boolean(appUserContext?.currentTenant?.id)}
                        hideRole={true}
                        appUserContext={appUserContext}
                        currentUser={currentUser}
                        selected={selected.includes(row.id)}
                        onSelectRow={appUserContext?.currentTenant?.id ? () => onSelectRow(row.id) : undefined}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onOverviewRow={() => handleOverviewRow(row.id)}
                        onActivateRow={() => handleActivateRow(row.id || '')}
                        onDeactivateRow={() => handleDeactivateRow(row.id || '')}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            {/* <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            /> */}
          </Box>
        </Card>
      </Container>
    </Page>
  );
}