import { kebabCase } from 'change-case';
// sections
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Activ8CoursesProgramsLearningCourseProgramStatus, appApi } from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useAppUserContext from 'src/hooks/useAppUserContext';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import {
  LearningCourseProgramTableRow, LearningCourseProgramTableToolbar
} from 'src/sections/courses/programs/list';
import DropDownMenuButton from 'src/sections/shared/DropDownMenuButton';

// @mui
import {
  Alert, Box, Button, Card, Container, IconButton, MenuItem, Stack, Table, TableBody,
  TableContainer, TablePagination, Tooltip, Typography
} from '@mui/material';

// components
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import {
  TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions
} from '../../../components/table';
// hooks
import useSettings from '../../../hooks/useSettings';
import useTable, { emptyRows } from '../../../hooks/useTable';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'draft', 'paused', 'live', 'cancelled'];

// ----------------------------------------------------------------------

export default function LearningCourseProgramList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'internalName' });

  const { themeStretch } = useSettings();
  const permissionChecker = usePermissionChecker();
  const appUserContext = useAppUserContext();
  const hasProgramEditPermission = permissionChecker.has(permissionKeys.tenant.program.createUpdateDelete);
  const hasExportPermission = permissionChecker.has(permissionKeys.tenant.program.export);
  const [triggerListExportPost] = appApi.endpoints.dataExportTriggerCourseProgramExportEmail.useMutation();
  const [triggerParticipantExportPost] = appApi.endpoints.dataExportTriggerCourseProgramParticipantExportEmail.useMutation();

  const navigate = useNavigate();

  const { handleError } = useApiResponseHandler();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const triggerExport = async (type: 'participants in programs' | 'programs') => {
    confirm({
      description:
        <Stack gap={3}>
          <Typography variant='body1'>{`Are you sure you want to trigger an export?`}</Typography>
          <Alert severity='info'>An email will be sent to {appUserContext?.currentUser?.email} in a few minutes which will contain the export of all {type}.</Alert>
        </Stack>
    })
      .then(async () => {
        try {
          switch (type) {
            case 'participants in programs': await triggerParticipantExportPost(undefined).unwrap(); break;
            case 'programs': await triggerListExportPost(undefined).unwrap(); break;
          }

          enqueueSnackbar('Export triggered!');
        }
        catch (error) {
          handleError(error);
        }
      });
  }

  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterFacilitatorUserId, setFilterFacilitatorUserId] = useState('all');
  const [filterParticipantUserId, setFilterParticipantUserId] = useState('all');

  const [learningCourseProgramDeletePost] = appApi.endpoints.learningCourseProgramDelete.useMutation();
  const learningCourseProgramGetListQuery = appApi.endpoints.learningCourseProgramGetList.useQuery({
    sorting: (orderBy || (hasProgramEditPermission ? 'internalName' : 'publicName')) + ' ' + (order || 'asc'),
    skipCount: page * rowsPerPage,
    maxResultCount: rowsPerPage,
    filterText: filterName,
    assignedToFacilitatorIds: filterFacilitatorUserId && filterFacilitatorUserId !== '' && filterFacilitatorUserId !== 'all' ? [filterFacilitatorUserId] : undefined,
    assignedToParticipantIds: filterParticipantUserId && filterParticipantUserId !== '' && filterParticipantUserId !== 'all' ? [filterParticipantUserId] : undefined,
    statuses: filterStatus === 'all' ? undefined : [filterStatus as Activ8CoursesProgramsLearningCourseProgramStatus]
  },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true
    });

  const tableData = learningCourseProgramGetListQuery.data?.items || [];

  const onDataRefetch = () => {
    learningCourseProgramGetListQuery.refetch();
  }

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(event.target.value);
    setPage(0);
  };

  const handleFilterFacilitatorUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterFacilitatorUserId(event.target.value);
    setPage(0);
  };

  const handleFilterParticipantUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterParticipantUserId(event.target.value);
    setPage(0);
  };

  const handleDeleteRows = (selected: string[]) => {
    confirm({ description: `Are you sure you want to delete the selected program${selected.length > 1 ? 's' : ''}?` })
      .then(async () => {
        try {
          const promises = selected.map(id => learningCourseProgramDeletePost({ id: id }).unwrap());
          await Promise.all(promises);
          enqueueSnackbar(`Program${selected.length > 1 ? 's' : ''} deleted`);
          setSelected([]);
          learningCourseProgramGetListQuery.refetch();
        }
        catch (error) {
          handleError(error);
        }
      });
  };

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.tenant.program.edit(kebabCase(id)));
  };

  const handleOverviewRow = (id: string) => {
    navigate(PATH_DASHBOARD.tenant.program.overview(kebabCase(id)));
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !tableData || tableData.length === 0;

  const totalCount = (learningCourseProgramGetListQuery?.data?.totalCount || 0)

  const hasProgramCrudPermission = permissionChecker.has(permissionKeys.tenant.program.createUpdateDelete);

  const TABLE_HEAD = hasProgramCrudPermission ? [
    { id: 'internalName', label: 'Name', align: 'left' },
    { id: 'programReferenceCode', label: 'Code', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'conductionAddress', label: 'Location', align: 'left' },
    { id: 'creationTime', label: 'Created', align: 'left' },
    { id: 'lastModificationTime', label: 'Updated', align: 'left' },
    { id: '', label: '', align: '' }
  ] : [
    { id: 'publicName', label: 'Name', align: 'left' },
    { id: 'programReferenceCode', label: 'Code', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'conductionAddress', label: 'Location', align: 'left' }
  ]

  return (
    <Page title="Programs">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Programs"
          links={[]}
          action={
            <>
              {
                !hasExportPermission ? undefined :
                  <DropDownMenuButton label='Export' variant="text" sx={{ mr: 4 }} startIcon={<Iconify icon={'eva:cloud-download-outline'} />}>
                    <MenuItem onClick={() => triggerExport('programs')} disableRipple>
                      Programs
                    </MenuItem>
                    <MenuItem onClick={() => triggerExport('participants in programs')} disableRipple>
                      Participant metrics
                    </MenuItem>
                  </DropDownMenuButton>
              }
              {
                !hasProgramCrudPermission ? undefined : <Button
                  variant="contained"
                  component={RouterLink}
                  to={PATH_DASHBOARD.tenant.program.new}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                  New program
                </Button>
              }
            </>
          }
        />

        <Card>
          {hasProgramCrudPermission ?
            <LearningCourseProgramTableToolbar
              filterName={filterName}
              filterStatus={filterStatus}
              filterFacilitatorUserId={filterFacilitatorUserId}
              filterParticipantUserId={filterParticipantUserId}
              onFilterName={handleFilterName}
              onFilterStatus={handleFilterStatus}
              onFilterFacilitatorUserId={handleFilterFacilitatorUserId}
              onFilterParticipantUserId={handleFilterParticipantUserId}
              optionsStatus={STATUS_OPTIONS}
            /> : undefined}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={totalCount}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      !checked,
                      tableData.map((row) => row.id || '')
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'} >
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalCount}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={!hasProgramCrudPermission ? undefined : (checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id || '')
                    )
                  }
                />

                <TableBody>
                  {tableData
                    .map((row) => (
                      <LearningCourseProgramTableRow
                        key={row.id}
                        row={row}
                        onDataRefetch={onDataRefetch}
                        selected={selected.includes(row.id || '')}
                        onSelectRow={() => onSelectRow(row.id || '')}
                        onEditRow={() => handleEditRow(row.id || '')}
                        onOverviewRow={() => handleOverviewRow(row.id || '')}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}